import axios from 'axios';

let axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use(async config => {
    const token = localStorage.getItem('token')
    
    config.headers.Authorization = `Bearer ${token}`;

    return config;
})

export default axiosInstance;