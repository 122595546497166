import React from 'react'
import { withStyles } from '@material-ui/core'

import InputGenerator from './InputGenerator'
import SimpleModal from './Modal'

let styles = {
	rowContainer: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		flex: 1
	},
	row: {
		flex: 1,
		marginLeft: '10px',
		'&:nth-of-type(1)': {
			marginLeft: '0px'
		}
	},
	column: {
		flex: 1
	},
	displayColumn: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		flex: 1
	}
}

let fieldsToArrays = fields => {
	let newFields = []
	let row = []

	for (let i = 0; i < fields.length; i++) {
		if (fields[i] && fields[i].breakline) {
			newFields.push(row)
			row = []
			row.push(fields[i])
		} else if (fields[i]) {
			row.push(fields[i])
		}
	}

	newFields.push(row)
	return newFields
}

let renderFields = props => fieldsToArrays(props.fields).map((field, index) => {
	return (
		<div className={props.classes.rowContainer} key={index}>
			{field.map((formElement, index) => (
				<div style={{ marginTop: formElement.type.toLowerCase() === 'multiselector' ? 7 : '' }} className={props.classes.row} key={index}>
					<InputGenerator
						key={index}
						margin="dense"
						fullWidth={true}
						InputLabelProps={{
							shrink: true
						}}
						onChange={props.onChange}
						onClick={props.onClick}
						{...formElement}
					/>
				</div>
			))}
		</div>
	)

})


export default withStyles(styles)(props => {
	return (
		<SimpleModal
			open={props.open}
			onClose={props.onClose}
			onCancel={props.onCancel}
			onAccept={props.onSave}
			acceptButtonText={props.submitButtonText}
			cancelButtonText={props.cancelButtonText}
			title={props.dialogTitle}
			maxWidth={props.size}
			utilsButton={props.utilsButton}
			canSubmitForm={props.canSubmit}
			spinner={props.spinner}
			spinnerText={props.spinnerText}
		>
			{renderFields(props)}
			{props.children}
		</SimpleModal>
	)
})