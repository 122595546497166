import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles, Button } from '@material-ui/core'
import { Add, Delete, Edit } from '@material-ui/icons'


import MenuTable from '../../common/Table'
import SimpleFormGenerator from '../../common/SimpleFormGenerator'

import * as TABLETS_REQUESTS from '../../utils/requests/tablets'
import * as helpers from '../../utils/helpers'
import * as NOTIFICATIONS from '../../utils/notification'
import FullPageLoading from '../../common/FullPageLoading'

const initialFields = [
    { value: '', type: 'text', label: 'Tablet UUID', name: 'deviceUuid' }
]

let Tablets = props => {

    let { classes } = props
    const [tablets, setTablets] = useState([])
    const [renderPage, setRenderPage] = useState(true)
    const [addModal, setAddModal] = useState(false)
    const [editing, setEditing] = useState(false)
    const [tabletToEdit, setTabletToEdit] = useState(null)
    const [tabletsFields, setTabletsFields] = useState(initialFields)

    let toggleEditModal = tabletId => {
        setEditing(true)
        setTabletToEdit(tabletId)
        setAddModal(true)
        TABLETS_REQUESTS.getById(props.restaurantId, tabletId).then(currentTablet => {
            Object.keys(currentTablet).forEach(key => {
                onInputChange({ target: { name: key, value: currentTablet[key] } })
            })
        })
    }

    let getTablets = () => TABLETS_REQUESTS.getByRestaurantId(props.restaurantId)
        .then(result => {
            const mappedTablets = result.map(tablet => ({
                id: tablet.id,
                deviceUuid: tablet.deviceUuid,
                menuName: helpers.i18n(tablet.menuName)
            }))
            setTablets(mappedTablets)
            clearForm()
            setRenderPage(true)
        })

    let deleteTablet = tabletId => {
        if (window.confirm('Are you sure?')) {
            TABLETS_REQUESTS.deleteTablet(props.restaurantId, tabletId).then(() => {
                NOTIFICATIONS.success("Tablet successfully deleted")
                getTablets()
            })
                .catch(() => {
                    NOTIFICATIONS.error("Fail to delete")
                })
        }
    }

    let onInputChange = event => {
        let fieldIndex = tabletsFields.findIndex(f => f.name === event.target.name)

        if (!(fieldIndex > -1)) return

        let newFields = [...tabletsFields]

        newFields[fieldIndex].value = event.target.value

        setTabletsFields(newFields)

    }

    let createTablet = () => {
        let tabletJson = {}
        tabletsFields.map(field => {
            if (field.type === 'multiselector') {
                tabletJson[field.name] = field.value.filter(attr => attr.value).map(attr => attr.name)
            }
            else {
                tabletJson[field.name] = field.value
            }
        })

        if(!tabletJson.deviceUuid.trim().length){
            let nameIndex = tabletsFields.findIndex(field => field.name === 'deviceUuid')
            if(nameIndex > -1){
                let tabletsFieldsCopy = [...tabletsFields].map(obj => ({...obj}))
                tabletsFieldsCopy[nameIndex].error = true
                setTabletsFields(tabletsFieldsCopy)
                return NOTIFICATIONS.error("Please complete all fields")
            }
        }

        if (editing) {
            TABLETS_REQUESTS.edit(props.restaurantId, tabletToEdit, tabletJson).then((result) => {
                getTablets()
                NOTIFICATIONS.success("Tablet successfully edited")
            })
                .catch(() => NOTIFICATIONS.error("Fail to add"))
        }
        else {
            TABLETS_REQUESTS.create(props.restaurantId, tabletJson).then((result) => {
                getTablets()
                NOTIFICATIONS.success("Tablet successfully added")
            })
                .catch(() => NOTIFICATIONS.error("Fail to add"))
        }
    }

    let clearForm = () => {
        let newFields = [...initialFields.map(field => ({ ...field }))]
        setAddModal(false)
        setTabletsFields(newFields)
    }

    React.useEffect(() => {
        getTablets()
    }, [])

    if (renderPage) {
        return (
            <div className={classes.container}>
                <SimpleFormGenerator
                    dialogTitle={editing ? 'EDIT TABLET' : 'CREATE NEW TABLET'}
                    fields={tabletsFields}
                    onChange={event => onInputChange(event)}
                    onClose={() => {
                        setAddModal(false)
                        setEditing(false)
                        setTabletToEdit(null)
                        clearForm(initialFields)
                    }}
                    onCancel={() => {
                        setAddModal(false)
                        setEditing(false)
                        setTabletToEdit(null)
                        clearForm(initialFields)
                    }}
                    onSave={() => createTablet()}
                    submitButtonText={editing ? 'EDIT' : 'CREATE'}
                    cancelButtonText={'CANCEL'}
                    open={addModal}>
                </SimpleFormGenerator>

                <MenuTable
                    title={'TABLETS'}
                    headerButton={{
                        icon: <Button color='primary' variant='contained'><Add /> <span className={classes.headerButtonText}>TABLET</span></Button>,
                        tooltip: 'Add new menu',
                        onClick: () => setAddModal(true)
                    }}
                    header={['ID', 'DEVICE', 'MENU NAME']}
                    onClickRow={row => {

                    }}
                    rows={tablets}
                    actions={[
                        {
                            icon: <Delete />,
                            tooltip: 'Delete',
                            onClick: (event, row) => deleteTablet(row.id)
                        },
                        {
                            icon: <Edit />,
                            tooltip: 'Edit',
                            onClick: (event, row) => {
                                toggleEditModal(row.id)
                            }
                        }
                    ]}
                />
            </div >
        )
    } else return <FullPageLoading />
}

let styles = theme => ({
    container: {
        width: '100%',
        height: '100%'
    },
    headerButtonText: {
        marginTop: 2
    },
    displayColumn: {
        display: 'flex',
        flexDirection: 'column',
        margin: 2
    },
    fileMessage: {
        padding: 4
    }
})

export default withRouter(withStyles(styles)(Tablets))