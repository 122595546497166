import app from 'firebase/app';
import 'firebase/analytics';

let defaultLanguage = 'ro'


export const i18n = object => {
    let currentLanguage = localStorage.getItem('currentLanguage') || localStorage.getItem('defaultLanguage')
    if (typeof object === 'object') {
        if (object[currentLanguage]) return object[currentLanguage]
        return object[defaultLanguage]
    }
    return object
}

export const getLanguage = () => localStorage.getItem('currentLanguage') || localStorage.getItem('defaultLanguage')

export const getPrice = (price, currencies, languages) => {
    //get currency symbol
    let symbol = ''
    let currentLanguage = 'ro' /* force ron prices localStorage.getItem('currentLanguage') || localStorage.getItem('defaultLanguage') || localStorage.getItem('languageMobile') */

    let lang = languages.find(lang => {
        return Object.keys(lang)[0] === currentLanguage
    })

    if (lang) {
        symbol = lang[currentLanguage].currencySymbol || lang[currentLanguage].currencyCode
    }

    return `${(Number(price) / Number(currencies[currentLanguage])).toFixed(0)} ${symbol}`
}


export const errorHandling = err => {
    if (err.response.status === 401) {
        window.location.reload()
    }
    if (err.response.status === 403) {
        window.location.href = '/'
    }
    return Promise.reject(err)
}

const initAnalytics = () => {

    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    }

    app.initializeApp(firebaseConfig)
    let analytics = app.analytics()

    return analytics
}

export const analytics = initAnalytics()