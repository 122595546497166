import React, { useState } from 'react'

import { withRouter } from 'react-router-dom'
import { withStyles, Button, TextField, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import i18n from '../../../utils/i18n.json'

import * as MOBILE_REQUESTS from '../../../utils/requests/mobile'
import * as NOTIFICATION from '../../../utils/notification'

let Contact = props => {

    const primaryColor = props.colorTheme === 'darkMode' ? props.design.dark.primary : props.design.light.primary
    const primaryColorText = props.colorTheme === 'darkMode' ? 'white' : 'black'
    const secondaryColor = props.colorTheme === 'darkMode' ? props.design.dark.secondary : props.design.light.secondary
    const useStyles = makeStyles({
        container: {
            width: '100%',
            height: '100%',
            backgroundColor: primaryColor,
            color: 'red'
        },
        cancelButton: {
            float: 'right',
            margin: 12,
            color: primaryColorText
        },
        input: {
            marginTop: 6,
            '& .MuiInputLabel-formControl': {
                color: primaryColorText,  
            },
            "& .MuiInput-underline:before": {
                borderBottom: `1px solid ${primaryColorText}`,
                color: primaryColorText
            },
            "& .MuiInput-underline:after": {
                borderBottom: `1px solid ${primaryColorText}`,
                color: primaryColorText
            },
            "& .MuiInput-underline:hover:before": {
                borderBottom: `1px solid ${primaryColorText}`,
                color: primaryColorText
            },
            '& .MuiInputBase-input': {
                color: primaryColorText
            }
        },
        headerContainer: {
            height: 50
        },
        content: {
            height: 'calc(70vh - 50px)',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '30%',
            "@media (min-width: 767px)": {
                paddingTop: '10%'
            }
        },
        icon: {
            marginRight: 8,
            fontSize: 16,
            position: 'relative',
            top: '-2px'
        },
        formContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        sendMessage: {
            backgroundColor: secondaryColor,
            color: primaryColorText,
            marginTop: 30
        },
        titleText: {
            color: primaryColorText,
            fontWeight: 'bold',
            letterSpacing: 2
        }
    })
    const classes = useStyles();

    let lang = localStorage.getItem('languageMobile')

    const initialFields = [
        { value: '', type: 'text', name: 'name', label: i18n.enterName[lang], breakline: true },
        { value: '', type: 'text', name: 'email', label: i18n.enterEmail[lang], breakline: true },
        { value: '', type: 'text', name: 'comment', label: i18n.saySomething[lang], breakline: true }
    ]

    let [fields, setFields] = useState(initialFields)
    let [disableButton, setDisabledButton] = useState(false)

    let onChange = event => {
        let fieldsCopy = [...fields]

        const currentIndex = fieldsCopy.findIndex(idx => idx.name === event.target.name)

        if (currentIndex > -1) {
            fieldsCopy[currentIndex].value = event.target.value
            setFields(fieldsCopy)
        }
    }

    let sendMessage = () => {
        if (!fields[0].value || !fields[1].value || !fields[2].value) {
            return NOTIFICATION.error("Complete all forms")
        }

        MOBILE_REQUESTS.sendEmail(props.match.params.RESTAURANT_ID, {
            name: fields[0].value,
            email: fields[1].value,
            text: fields[2].value
        }).then(() => {
            setDisabledButton(true)
            NOTIFICATION.success("Email sent")
        }).catch(() => {
            NOTIFICATION.error("Error sending email")
        })
    }

    let renderFields = () => {
        return (
            fields.map(field => {
                return (
                    <div key={field.name} className={classes.input}>
                        <TextField {...field} onChange={event => onChange(event)} />
                    </div>
                )
            })
        )
    }

    return (
        <div className={classes.container}>
            <div className={classes.headerContainer}>
                <Button className={classes.cancelButton} onClick={props.onCancel}><Close className={classes.icon} /><span>{i18n.close[lang]}</span></Button>
            </div>
            <div className={classes.content}>
                <span className={classes.titleText}>{i18n.hearFromYou[lang]}</span>
                <div className={classes.formContainer}>
                    {renderFields()}
                    <Button disabled={disableButton} onClick={() => sendMessage()} className={classes.sendMessage} >{i18n.sendMessage[lang]}</Button>
                </div>
            </div>
        </div>
    )
}




export default withRouter(Contact)