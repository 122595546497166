import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles, Button, Tooltip, GridList, GridListTile } from '@material-ui/core'
import { AddToPhotos } from '@material-ui/icons'

import Dropzone from '../../../common/Dropzone'
import FullPageLoading from '../../../common/FullPageLoading'
import Modal from '../../../common/Modal'
import * as NOTIFICATION from '../../../utils/notification'

import * as RESTAURANT_REQUESTS from '../../../utils/requests/restaurants'

let RestaurantPhotos = props => {
    let { classes } = props
    let [split, setSplit] = React.useState(false)
    let [done, setDone] = React.useState(false)
    let [restaurantImages, setRestaurantImages] = React.useState(false)
    let [cols, setCols] = React.useState(4)
    let [imageFull, setImageFull] = React.useState(null)

    useEffect(() => {
        if (split) setCols(2)
        else setCols(4)
    }, [split]);

    React.useEffect(() => {
        getRestaurantPhotos()
    }, [])

    let getRestaurantPhotos = () => {
        return RESTAURANT_REQUESTS.getImagesByRestaurantId(props.restaurantId).then(images => {
            setRestaurantImages(images)
            setDone(true)
        })
    }

    let uploadFiles = filesToUpload => {
        let files = Array.from(filesToUpload)
        if (!files.length) return;

        const formData = new FormData()
        files.forEach((file, i) => {
            formData.append('file', file)
        })

        return RESTAURANT_REQUESTS.uploadFiles(props.restaurantId, formData).then(result => {
            getRestaurantPhotos()
            setSplit(false)
        })
    }

    let renderDropzone = () => {
        return (
            <Dropzone onSubmitButtonClicked={files => uploadFiles(files)} />
        )
    }

    let deleteCurrentImage = () => {
        if (window.confirm("Confirmati stergerea imaginei?")) {
            RESTAURANT_REQUESTS.deleteImage(props.restaurantId, imageFull.id)
                .then(() => {
                    NOTIFICATION.success("Imaginea a fost stearsa")
                    getRestaurantPhotos()
                    setImageFull(null)
                }).catch(() => {
                    NOTIFICATION.error("Imaginea nu a fost stearsa")
                })
        }
    }

    let renderGallery = () => {
        return (
            <div className={classes.root}>
                <GridList cellHeight={300} cols={cols} spacing={10} className={classes.gridList}>
                    {restaurantImages.map((image, key) => (
                        <GridListTile key={key} style={{ cursor: 'pointer' }} onClick={() => setImageFull({ url: image.url, id: image.id })}>
                            <img height={300} src={image.thumbUrl} alt={image.id} />
                        </GridListTile>
                    ))}
                </GridList>
            </div>
        );
    }

    if (done) {
        return (
            <div className={classes.container}>
                <Modal
                    open={imageFull ? true : false}
                    onCancel={() => setImageFull(null)}
                    onClose={() => setImageFull(null)}
                    utilsButton={() => <Button color='secondary' onClick={() => deleteCurrentImage()}>Delete</Button>}
                    cancelButtonText="Cancel"
                    maxWidth='md'
                >
                    {imageFull ? <img src={imageFull.url} className={classes.fullImage} alt='' /> : null}
                </Modal>
                <div className={classes.contentContainer}>
                    <div className={classes.content}>
                        <div className={classes.header}>
                            <div className={classes.uploadButtonContainer}>
                                <Tooltip placement={"left-end"} classes={{
                                    tooltip: classes.tooltipStyle
                                }} title="Upload new photos">
                                    <Button className={classes.uploadButton} onClick={() => setSplit(!split)}><AddToPhotos className={classes.uploadButtonIcon} /></Button>
                                </Tooltip>
                            </div>
                        </div>
                        <div className={classes.restaurantGallery}>
                            {renderGallery()}
                        </div>
                    </div>
                    {split ?
                        <div className={classes.content}>
                            <div className={classes.uploadContainer}>
                                {renderDropzone()}
                            </div>
                        </div> : null}
                </div>
            </div>
        )
    } else return <FullPageLoading />
}

let styles = theme => ({
    fullImage: {
        width: '100%'
    },
    container: {
        width: '100%',
        overflow: 'auto'
    },
    contentContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    content: {
        margin: 20,
        backgroundColor: 'white',
        width: '100%'
    },
    restaurantGallery: {
        flex: 1,
        padding: 16,
        paddingTop: 12,
        display: 'flex',
        flexDirection: 'row'
    },
    uploadContainer: {
        flex: 1
    },
    uploadButtonContainer: {
        marginLeft: 'auto',
        padding: '8px 18px 8px 8px'
    },
    uploadButton: {
        backgroundColor: 'white'
    },
    uploadButtonIcon: {
        color: '#303f9f !important',
        fontSize: '36px !important',
    },
    tooltipStyle: {
        fontSize: 18
    },
    header: {
        height: 50,
        padding: '0px 4px 18px 4px',
        display: 'flex',
        flexDirection: 'row'
    },
    restaurantDetails: {
        padding: 16
    },
    restaurantName: {
        fontWeight: 'bold',
        fontSize: 22,
        letterSpacing: 2,
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: '100%'
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    }
})

export default withRouter(withStyles(styles)(RestaurantPhotos))