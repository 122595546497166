import React, { Component } from 'react'
import JoditEditor from '../../../common/JoditWrapper'

class RestaurantJodit extends Component {
    state = {
        content: ''
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.editing) {
            this.setState({ content: nextProps.currentValue })
        }
    }

    render() {

        const editor = React.createRef()
        const config = {
            readonly: false,
            placeholder: 'Restaurant description...',
            removeButtons: ['image',
                'file', 'video',
                'table', 'link',
                'font', 'fontsize',
                'source', 'eraser',
                'superscript',
                'subscript',
                'brush',
                'undo',
                'selectall',
                'cut', 'copy', 'paste', 'insert',
                'symbol', 'fullsize', 'print',
                'preview','find', 'about',
                'copyformat', 'hr', 'outdent', 'redo',
                'left', 'paragraph']
        }

        return (
            <JoditEditor
                ref={editor}
                value={this.state.content}
                tabIndex={1}
                config={config}
                onChange={newContent => {
                    this.setState({ content: newContent }, () => this.props.setValue(this.state.content))
                }}
                props={this.props}
            />
        )
    }
}

export default RestaurantJodit