import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import GlobalProductDetails from './GlobalProductDetails'

import ProductTable from '../../common/Table'

import * as GLOBAL_PRODUCT_REQUESTS from '../../utils/requests/globalProducts'
import * as NOTIFICATIONS from '../../utils/notification'
import FullPageLoading from '../../common/FullPageLoading'

let allProducts = []

let Restaurant = props => {

    let { classes } = props
    const [products, setProducts] = useState([])
    const [renderPage, setRenderPage] = useState(false)
    const [productForDetails, setProductForDetails] = useState(null)

    const sortKeys = original => {
        let copy = {}
        Object.keys(original).sort().forEach(key => {
            copy[key] = original[key]
        })

        return copy
    }

    const getProducts = () => {
        GLOBAL_PRODUCT_REQUESTS.get().then(products => {
            let parsedProducts = products.map((product, index) => {

                product.name = sortKeys(product.name)
                product.description = sortKeys(product.description)
                product.ingredients = sortKeys(product.description)

                return {
                    index: index + 1,
                    name: product.name[Object.keys(product.name)[0]],
                    description: product.description[Object.keys(product.description)[0]],
                    languages: Object.keys(product.name).join(', '),
                    _id: product.id
                }
            })
            setProducts(parsedProducts)
            allProducts = parsedProducts
            setRenderPage(true)
        })
    }

    const onSearchHandler = value => {
        if(value.trim() === '') return setProducts(allProducts)
        setProducts(allProducts.filter(product => {
            return product.name.toLowerCase().search(value.toLowerCase()) > -1
        }))
    }

    const deleteProduct = productId => {
        if (window.confirm("Confirmati stergerea produsului?")) {
            GLOBAL_PRODUCT_REQUESTS.deleteProduct(productId)
                .then(() => {
                    NOTIFICATIONS.success("Product deleted")
                    getProducts()
                })
                .catch(() => {
                    NOTIFICATIONS.error("Could not delete product")
                })
        }
    }

    const viewProduct = productId => {
        GLOBAL_PRODUCT_REQUESTS.getById(productId)
            .then(product => {
                product.name = sortKeys(product.name)
                product.description = sortKeys(product.description)
                product.ingredients = sortKeys(product.description)

                product.currentLanguage = Object.keys(product.name)[0]
                setProductForDetails(product)
            })
            .catch(() => {
                NOTIFICATIONS.error("Could not get product")
            })
    }

    React.useEffect(() => {

        getProducts()

    }, [])

    if (renderPage) {
        return (
            <div className={classes.container}>
                {productForDetails && !props.disableDetails && <GlobalProductDetails
                    open={productForDetails ? true : false}
                    onClose={() => {
                        setProductForDetails(null)
                    }}
                    changeLanguage={lang => {
                        let currentProductCopy = JSON.parse(JSON.stringify(productForDetails))
                        currentProductCopy.currentLanguage = lang
                        setProductForDetails(currentProductCopy)
                    }}
                    product={productForDetails}
                />}
                <ProductTable
                    title={'GLOBAL PRODUCTS'}
                    search={{
                        title: 'Press ENTER to search',
                        onSearch: value => onSearchHandler(value)
                    }}
                    disableBorders={!props.disableDetails ? false : true}
                    header={['#', 'NAME', 'DESCRIPTION', 'LANGUAGES']}
                    onClickRow={row => !props.disableDetails ? viewProduct(row._id) : props.onClickRow(row._id)}
                    rows={products}
                    actions={!props.disableDetails ? [
                        {
                            icon: <Delete />,
                            tooltip: 'Delete',
                            onClick: (event, row) => deleteProduct(row._id)
                        }
                    ] : []}
                />
            </div >
        )
    } else return <FullPageLoading />
}

let styles = theme => ({
    container: {
        width: '100%',
        height: '100%'
    }
})

export default withRouter(withStyles(styles)(Restaurant))