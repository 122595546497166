import axios from '../axios'
import { errorHandling } from '../helpers'

export const get = (restaurantId, menuId) => axios.get(`/restaurants/${restaurantId}/menus/${menuId}/categories`)
    .then(res => res.data)
    .catch(errorHandling)

export const reorder = (restaurantId, menuId, categories) => axios.put(`/restaurants/${restaurantId}/menus/${menuId}/categories/reorder`, categories)
    .then(res => res.data)
    .catch(errorHandling)

export const create = (restaurantId, menuId, body) => axios.post(`/restaurants/${restaurantId}/menus/${menuId}/categories`, { ...body })
    .then(res => res.data)
    .catch(errorHandling)

export const getById = (restaurantId, menuId, categoryId) => axios.get(`/restaurants/${restaurantId}/menus/${menuId}/categories/${categoryId}`)
    .then(res => res.data)
    .catch(errorHandling)

export const deleteById = (restaurantId, menuId, categoryId) => axios.delete(`/restaurants/${restaurantId}/menus/${menuId}/categories/${categoryId}`)
    .then(res => res.data)
    .catch(errorHandling)

export const getSubcategories = (restaurantId, menuId, categoryId) => axios.get(`/restaurants/${restaurantId}/menus/${menuId}/categories/${categoryId}/subcategories`)
    .then(res => res.data)
    .catch(errorHandling)

export const createSubcategory = (restaurantId, menuId, categoryId, subcategory) => axios.post(`/restaurants/${restaurantId}/menus/${menuId}/categories/${categoryId}/subcategories`, { ...subcategory })
    .then(res => res.data)
    .catch(errorHandling)

export const getSubcategoryById = (restaurantId, menuId, categoryId, subcategoryId) => axios.get(`/restaurants/${restaurantId}/menus/${menuId}/categories/${categoryId}/subcategories/${subcategoryId}`)
    .then(res => res.data)
    .catch(errorHandling)

export const deleteSubcategoryById = (restaurantId, menuId, categoryId, subcategoryId) => axios.delete(`/restaurants/${restaurantId}/menus/${menuId}/categories/${categoryId}/subcategories/${subcategoryId}`)
    .then(res => res.data)
    .catch(errorHandling)

export const reorderSubcategories = (restaurantId, menuId, categoryId, subcategories) => axios.put(`/restaurants/${restaurantId}/menus/${menuId}/categories/${categoryId}/subcategories/reorder`, subcategories)
    .then(res => res.data)
    .catch(errorHandling)

export const hideSubcategoryById = (restaurantId, menuId, categoryId, subcategoryId, subcategory) => axios.put(`/restaurants/${restaurantId}/menus/${menuId}/categories/${categoryId}/subcategories/${subcategoryId}`,subcategory)
    .then(res => res.data)
    .catch(errorHandling)
    
export const renameCategory = (restaurantId, menuId, categoryId, category) => axios.put(`/restaurants/${restaurantId}/menus/${menuId}/categories/${categoryId}`,category)
    .then((res) => res.data)
    .catch(errorHandling)

export const rename = (restaurantId, menuId, categoryId, subcategoryId, subcategory) => axios.put(`/restaurants/${restaurantId}/menus/${menuId}/categories/${categoryId}/subcategories/${subcategoryId}`,subcategory)
    .then(res => res.data)
    .catch(errorHandling)

export const addTimeFrame = (restaurantId, menuId, categoryId, category) => axios.put(`/restaurants/${restaurantId}/menus/${menuId}/categories/${categoryId}`,category)
    .then((res) => res.data)
    .catch(errorHandling)

export const upload = (restaurantId, menuId, categoryId, icon) => axios.put(`/restaurants/${restaurantId}/menus/${menuId}/categories/${categoryId}/upload`, icon)
    .then((res) => res.data)
    .catch(errorHandling)
    