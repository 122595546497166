import React, { useEffect, useRef, useState } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { withStyles, Card, CardActionArea, CardMedia, CardContent, Typography, Button, Collapse, IconButton, MenuItem, Popper, Grow, Paper, ClickAwayListener, MenuList, List, FormControlLabel, Checkbox, makeStyles } from '@material-ui/core'
import { ExpandMore, ExpandLess, ArrowBackIos, KeyboardArrowDown, Language, RadioButtonUnchecked, RadioButtonChecked, ArrowRightAlt } from '@material-ui/icons'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import * as PRODUCT_REQUESTS from '../../../utils/requests/mobile'
import Modal from '../../../common/Modal'
import { analytics, getPrice } from '../../../utils/helpers'
import { EVENTS, COLORTHEME, SWIPER_BREAKPOINTS } from '../../../utils/constants'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Controller, Thumbs } from 'swiper'
import 'swiper/swiper-bundle.css'
import i18n from '../../../utils/i18n.json'
SwiperCore.use([Navigation, Pagination, Controller, Thumbs])

const READ_MORE_DESCRIPTION_SIZE = 200;

let ProductDetails = props => {

    let [product, setProduct] = useState(null)
    let [products, setProducts] = useState(null)
    let [restaurant, setRestaurant] = useState(null)
    let [openImageModal, setImageModal] = useState(false)
    const [currentMenu, setCurrentMenu] = useState(null)
    const [openDescription, setOpenDescription] = useState(false)
    const [readMoreDescriptionOpen, setReadMoreDescription] = useState(false);
    const [readMoreIngredientsOpen, setReadMoreIngredients] = useState(false);
    const [openGoesWellWith, setOpenGoesWellWith] = useState(true)
    const [open, setOpen] = useState(false)
    let actualTheme = localStorage.getItem(COLORTHEME)
    const [colorTheme, setColorTheme] = useState(actualTheme)
    const [circleState, setCircleState] = useState({
        colorTheme: actualTheme === 'lightMode' ? false : true
    })
    const anchorRef = useRef(null)

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }
    const [allowTouch, setAllowTouch] = useState(true)
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setOpen(false)
    }

    const handleChange = (event) => {
        if (event.target.checked) {
            setColorTheme('darkMode')
            localStorage.setItem(COLORTHEME, 'darkMode')
        }
        else {
            setColorTheme('lightMode')
            localStorage.setItem(COLORTHEME, 'lightMode')
        }
        setCircleState({ ...circleState, [event.target.name]: event.target.checked })
    }

    const getCurrentMenu = () => {
        PRODUCT_REQUESTS.getById(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID).then((res) => {
            setCurrentMenu(res)
        })
    }

    const Nl2br = (props) => {
        return props.text.split('\n').map((item, key) => <span key={key}>{item}<br /></span>)
    }

    const getTextBasedOnReadMore = (text, type = 'description') => {
        if (type === 'description') {
            return <>
                <span>{text.length > READ_MORE_DESCRIPTION_SIZE && !readMoreDescriptionOpen ? <Nl2br text={(text.substring(0, READ_MORE_DESCRIPTION_SIZE)) + '...'} /> : <Nl2br text={(text)} />}</span>
                {text.length > READ_MORE_DESCRIPTION_SIZE ? <a onClick={() => setReadMoreDescription(!readMoreDescriptionOpen)} className={classes.readMore} href='#'>{!readMoreDescriptionOpen ? i18n.read_more[lang] : i18n.read_less[lang]}</a> : null}
            </>;
        } else {
            return <>
                <span>{text.length > READ_MORE_DESCRIPTION_SIZE && !readMoreIngredientsOpen ? <Nl2br text={(text.substring(0, READ_MORE_DESCRIPTION_SIZE)) + '...'} /> : <Nl2br text={(text)} />}</span>
                {text.length > READ_MORE_DESCRIPTION_SIZE ? <a onClick={() => setReadMoreIngredients(!readMoreIngredientsOpen)} className={classes.readMore} href='#'>{!readMoreIngredientsOpen ? i18n.read_more[lang] : i18n.read_less[lang]}</a> : null}
            </>;
        }
    }

    useEffect(() => {
        getCurrentMenu()
        PRODUCT_REQUESTS.getProductById(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID, props.match.params.PRODUCT_ID).then(res => {
            setProduct(res)
            PRODUCT_REQUESTS.getMenuProductsAll(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID, res.category, res.subcategory, res.id)
                .then(products => {
                    setProducts(products)
                    setAllowTouch(true)
                })
        })

    }, [props.match.params.PRODUCT_ID])

    let lang = localStorage.getItem('languageMobile')

    useEffect(() => {
        getCurrentMenu()
        PRODUCT_REQUESTS.getRestaurant(props.match.params.RESTAURANT_ID).then(res => {
            setRestaurant(res)
        })
    }, [props.match.params.RESTAURANT_ID])

    const useStyles = makeStyles({
        root: {
            display: 'flex',
            height: '4em',
            '& hr': {
                height: '2em',
                backgroundColor: 'white',
                zIndex: '999',
                margin: 'auto'
            }
        },
        readMore: {
            textDecoration: 'none',
            fontWeight: 'bold',
            color: 'inherit'
        },
        gridProductDetails: {
            width: '80%',
            '& .MuiGrid-grid-xs-6': {
                display: 'flex',
                flexBasis: 'auto',
                justifyContent: 'center'
            },
            '& hr': {
                backgroundColor: 'lightgray',
                marginRight: '80px',
                marginLeft: '80px',
                marginTop: '1em',
                height: '30px'
            },
            '@media (max-width: 860px)': {
                '& hr': {
                    marginRight: '40px',
                    marginLeft: '40px',
                    marginTop: '1em',
                    height: '30px',
                    transitionDuration: '1s'
                }
            },
            '@media (max-width: 540px)': {
                '& hr': {
                    marginRight: '25px',
                    marginLeft: '25px',
                }
            },
            '@media (max-width: 420px)': {
                '& hr': {
                    marginRight: '10px',
                    marginLeft: '10px',
                    display: 'none'
                },
                '& .MuiGrid-grid-xs-6': {
                    flexBasis: '50%'
                }
            },

        },
        gridProductOrderInfo: {
            padding: '0 30px 30px 30px'
        },
        gridTopButtons: {
            width: 'fit-content',
            '& hr': {
                backgroundColor: 'white',
                marginTop: '10px',
                height: '30px',
                zIndex: 2
            },
        },
        container: {
            width: '100%',
            height: 'fit-content',
            display: 'block'
        },
        containerLightMode: {
            backgroundColor: props.design.light.primary,
            color: props.design.light.primaryText,
            '& .swiper-button-prev, .swiper-button-next': {
                position: 'absolute',
                top: '50%',
                width: '40px',
                height: '40px',
                marginTop: 'calc(0px - (40px / 2))',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'gray',
                backgroundColor: 'white',
                boxShadow: 'rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.2) 0px 0px 0px 1px',
            },

            '& .swiper-button-next::before': {
                content: '" "',
                width: '120px',
                height: '350px',
                backgroundImage: `linear-gradient(to left, ${props.design.light.primary} 30px, rgba(255, 255, 255, 0))`,
                position: 'absolute',
                '@media (max-width: 380px)': {
                    display: 'none'
                },
            },
            '& .swiper-button-prev::before': {
                content: '" "',
                width: '120px',
                height: '350px',
                backgroundImage: `linear-gradient(to right, ${props.design.light.primary} 40px, rgba(255, 255, 255, 0))`,
                position: 'absolute',
                '@media (max-width: 380px)': {
                    display: 'none'
                },
            },
        },
        containerDarkMode: {
            backgroundColor: props.design.dark.primary,
            color: props.design.dark.primaryText,
            '& .MuiSvgIcon-root': {
                color: 'white',
            },
            '& h1': {
                color: 'white'
            },
            '& .MuiCardContent-root': {
                backgroundColor: props.design.dark.secondary
            },
            '& .swiper-button-prev, .swiper-button-next': {
                position: 'absolute',
                top: '50%',
                width: '40px',
                height: '40px',
                marginTop: 'calc(0px - (40px / 2))',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                backgroundColor: '#333333',
                boxShadow: 'rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(255, 255, 255, 1) 0px 0px 0px 1px',
            },
            '& .swiper-button-next::before': {
                content: '" "',
                width: '120px',
                height: '350px',
                backgroundImage: `linear-gradient(to left, ${props.design.dark.primary} 30px, rgba(255, 255, 255, 0))`,
                position: 'absolute',
                '@media (max-width: 380px)': {
                    display: 'none'
                },
            },
            '& .swiper-button-prev::before': {
                content: '" "',
                width: '120px',
                height: '350px',
                backgroundImage: `linear-gradient(to right, ${props.design.dark.primary} 40px, rgba(255, 255, 255, 0))`,
                position: 'absolute',
                '@media (max-width: 380px)': {
                    display: 'none'
                },
            }
        },
        whiteImage: {
            filter: 'invert(1)'
        },
        priceContainer: {
            position: 'relative',
            top: '-3em',
            width: '90%',
            minHeight: 150,
            height: 'fit-content',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            borderRadius: '0.5em',
            margin: 'auto auto',
        },
        priceContainer_lightMode: {
            backgroundColor: props.design.light.secondary,
            color: props.design.light.primaryText,
            boxShadow: `rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px`
        },
        priceContainer_darkMode: {
            backgroundColor: props.design.dark.secondary,
            color: props.design.dark.primaryText,
            boxShadow: 'rgb(255 255 255 / 5%) 0px 10px 36px 0px, rgb(255 255 255 / 6%) 0px 0px 0px 1px'
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            maxWidth: '1200px'
        },
        productDetails: {
            padding: '1em',
            display: 'flex',
            justifyContent: 'center',
            whiteSpace: 'nowrap',
            '@media (max-width: 420px)': {
                textAlign: 'center',
                padding: 0
            },
        },
        detailsContainer: {
            wordWrap: 'break-word',
            flex: 2,
            padding: 30,
            paddingTop: 0
        },
        headerContainer: {
            height: '300px',
            position: 'relative',
            '& img': {
                width: '100%',
                maxHeight: '100%',
                maxWidth: '100%',
                objectFit: 'cover',
                height: '100%'
            },
            '@media (min-width: 767px)': {
                height: '450px'
            }
        },
        icon: {
            fontSize: 24,
            position: 'relative',
            color: '#ffffff'
        },
        linkStyle: {
            position: 'absolute',
            left: 10,
            top: 10,
            textDecoration: 'none',
            color: 'inherit',
        },
        currenncyStyle: {
            position: 'absolute',
            right: 10,
            top: 10,
            textDecoration: 'none',
            color: 'inherit',
            '& .MuiSvgIcon-root': {
                color: 'white',
            },
        },
        topButtonsStyle: {
            backgroundColor: 'none',
            outline: 'none',
            background: 'transparent',
            border: 'none',
            height: '4em',
            color: 'white',
            zIndex: '999',
            '&:hover': {
                background: 'none',
                boxShadow: 'none'
            }
        },
        product: {
            marginLeft: '20px',
            display: 'inline-block',
            marginTop: '20px',
            borderRadius: '0.5em',
            width: '10em',
            '&:nth-of-type(2n+1)': {
                marginLeft: '0px'
            },
            '@media (min-width: 767px)': {
                '&:nth-of-type(2n+1)': {
                    marginLeft: '20px'
                }
            },
            '@media (max-width: 414px)': {
                width: '7.5em'
            },
        },
        cardMediaContainer: {
            '@media (max-width: 414px)': {
                height: '120px',
            },
        },
        pageSwiper: {
            backgroundColor: colorTheme === 'darkMode' ? props.design.dark.primary : props.design.light.primary
        },
        productBackgroundDarkMode: {
            backgroundColor: props.design.dark.secondary
        },
        productBackgroundLightMode: {
            backgroundColor: props.design.light.secondary
        },
        swipe: {
            width: 'calc(50% - 10px)',
            display: 'inline-block',
            marginTop: '20px',
            '&:nth-of-type(2n+1)': {
                marginLeft: '0px'
            },
            '&:nth-of-type(1)': {
                marginLeft: '10px'
            },
            '@media (min-width: 767px)': {
                width: 'calc(25% - 20px)',
            }
        },
        productName: {
            lineHeight: 1.1,
            textAlign: 'auto',
        },
        currentProductName: {
            fontWeight: '400'
        },
        backButton: {
            textDecoration: 'none !important',
            boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.35)',
            height: 25,
            opacity: 0.9,
            padding: '6px 16px 6px 10px'
        },
        price: {
            padding: 4,
            width: '30%',
            background: '#35B558',
            color: '#fff',
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: '10%',
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5
        },
        flexRow: {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 10
        },
        separatorSections: {
            height: '1px',
            background: colorTheme === 'darkMode' ? `${props.design.light.secondary} !important` : `${props.design.dark.secondary} !important`,
            margin: '20px 0px'
        },
        separator_darkMode: {
            border: '1px solid rgba(255,255,255,0.2)',
            width: '100%',
            margin: '20px 0px'
        },
        goesWellContainer: {
            marginTop: '10px',
            marginBottom: '10px',
            paddingBottom: '3em',
            '& .swiper-button-next:after': {
                content: 'next',
                fontSize: '1.5em',
                fontWeight: '900',
                zIndex: '999',
            },
            '& .swiper-button-prev:after': {
                content: 'prev',
                fontSize: '1.5em',
                fontWeight: '900',
                zIndex: '999',
            },
            '& .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled': {
                opacity: 0,
                pointerEvents: 'none'
            },
            '& .swiper-button-prev, .swiper-container-rtl .swiper-button-next': {
                left: '10px',
                right: 'auto'
            }
        },
        goesWell: {
            width: 75,
            height: 120,
            display: 'flex',
            flexDirection: 'column',
            margin: '0px 12px 12px 0px',
            backgroundColor: 'red'
        },
        priceGoWell: {
            fontWeight: 900,
        },
        info: {
            margin: '0.5em !important',
            '& img': {
                height: 24,
                position: 'relative',
                bottom: -5,
                paddingRight: 5,
            }
        },
        buttonCollapse: {
            fontSize: '1em',
            color: colorTheme === 'darkMode' ? props.design.dark.secondaryText : props.design.light.secondaryText,
            padding: 0,
            marginTop: '1em',
            '&:hover': {
                background: 'none'
            }

        },
        buttonDetails: {
            fontSize: '1em',
            color: '#f97037',
            '&:hover': {
                background: 'none'
            }
        },
        goesWellWith: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        productDescriptionFade: {
            WebkitMaskImage: `linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 40px)`
        },
        WeightPrice: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0px 10px 0px 10px',
            height: '3em',
            borderBottom: 'none',
            alignItems: 'center',
            '@media (max-width: 414px)': {
                padding: '0px 5px 0px 5px',
                '& h1': {
                    fontSize: '12px'
                },
                '& span': {
                    fontSize: '12px'
                }
            },
        },
        weightGray: {
            color: 'gray !important'
        },
        card: {
            borderRadius: '1em',
            width: '11em'
        },
        navigateBefore: {
            position: 'absolute',
            top: 'calc(50% - 0.725em)',
            left: '1.225em',
            backgroundColor: 'white',
            fontSize: '3em',
            zIndex: '999',
            cursor: 'pointer',
            '@media (max-width: 700px)': {
                left: '0.625em',
            }
        },
        navigateNext: {
            position: 'relative',
            top: 'calc(50% - 0.725em)',
            backgroundColor: 'white',
            fontSize: '3em',
            cursor: 'pointer'
        },
        navigateNextFade: {
            margin: 'auto auto',
            position: 'relative',
            height: '370px',
            right: '4%',
            background: 'linear-gradient(to left,rgba(245,245,245,255),transparent)',
            zIndex: '999',
        },
        header: {
            position: 'fixed',
            top: '0',
            width: '100%',
            zIndex: '999'
        },
        topFade: {
            position: 'absolute',
            width: '100%',
            height: '80px',
            zIndex: '-1',
            top: '0',
            background: `linear-gradient(to bottom, rgba(0,0,0,0.5), transparent) no-repeat bottom`
        },
        topButtons: {
            color: 'white',
            zIndex: 999,
            fontSize: '2em'
        },
        fadeEffect: {
            position: 'absolute',
            width: '40px',
            top: '0',
            right: '0',
            background: 'linear-gradient(to left,rgba(245,245,245,255), transparent) no-repeat bottom',
            height: '360px',
            paddingLeft: '30px'
        },
        productImage: {
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        },
        expandIcon: {
            color: colorTheme === 'darkMode' ? `${props.design.dark.secondaryText} !important` : `${props.design.light.secondaryText} !important`
        },
        allergenStyle: {
            marginLeft: '5px'
        },
        cardProductName: {
            borderBottom: 'none',
            padding: '10px 10px 5px 10px',
            height: 30,
            '@media (max-width: 414px)': {
                padding: '5px 5px 4px 5px',
                height: 20,
            },
        },
        cardProductNameText: {
            fontWeight: '700',
            minHeight: '2em',
            maxHeight: '2em',
            textAlign: 'left',
            overflow: 'hidden',
            wordBreak: 'break-all'
        },
        cardProductDetails: {
            padding: '0px 10px 5px 10px',
            borderBottom: 'none',
            textAlign: 'left',
            '@media (max-width: 414px)': {
                padding: '0px 5px 4px 5px',
            },
        },
        textDetails: {
            fontWeight: '700',
            minHeight: '2em',
            textAlign: 'left',
            color: colorTheme === 'darkMode' ? `${props.design.dark.secondaryText} !important` : `${props.design.light.secondaryText} !important`,
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'left'
        },
        checkBoxColorTheme: {
            paddingLeft: '1.5em'
        },
        arrowColor: {
            color: colorTheme === 'darkMode' ? `${props.design.dark.secondaryText} !important` : `${props.design.light.secondaryText} !important`
        },
        minHeight: {
            minHeight: '100vh'
        },
        swiperContainer: {
            width: '100%'
        },
        gridProductDevider: {
            height: '1px',
            // background: colorTheme === 'darkMode' ? `${props.design.light.secondary} !important` : `${props.design.dark.secondary} !important`
            background: colorTheme === 'darkMode' ? `linear-gradient(45deg, ${props.design.light.secondary} 0%, transparent 95% ) !important` : `linear-gradient(45deg, ${props.design.dark.secondary} 0%, transparent 95% ) !important`
        },
        productSpiceVeganContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        productSpicyContainer: {
            background: colorTheme === 'darkMode' ? props.design.dark.primary : props.design.light.primary,
            borderRadius: '50%',
            width: '2em',
            height: '2em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 0.1em 0 0.1em'
        },
        vegetarianImage: {
            height: '1em',
            width: '1em'
        },
        spicyImage: {
            height: '2em',
            width: '2em'
        }
    })
    const classes = useStyles();

    let quantityTranslate = (quantity, unit) => {
        if (unit === 'person' && quantity > 1) return i18n.quantityTranslate.persons[lang]
        if (unit === 'piece' && quantity > 1) return i18n.quantityTranslate.pieces[lang]
        if (unit === 'glass' && quantity > 1) return i18n.quantityTranslate.glasses[lang]
        if (unit === 'bottle' && quantity > 1) return i18n.quantityTranslate.bottles[lang]
        return i18n.quantityTranslate[unit] ? i18n.quantityTranslate[unit][lang] : ''
    }

    let onCardClick = (goesWellProductId, productName) => {
        analytics.logEvent(EVENTS.RELATED, { restaurant_id: props.match.params.RESTAURANT_ID, product_name: productName })
        props.history.push(`/mobile/${props.match.params.RESTAURANT_ID}/menu/${props.match.params.MENU_ID}/product/${goesWellProductId}`)
        setOpenDescription(false)
        setOpenGoesWellWith(true)
    }

    let setLanguage = language => {
        localStorage.setItem('languageMobile', language)
        window.location.reload()
    }

    let renderLanguages = () => {
        return (
            restaurant.languages.map((language, index) => {
                const languageName = Object.keys(language)[0]
                return (
                    <MenuItem
                        key={index}
                        onClick={() => { setLanguage(languageName); analytics.logEvent(EVENTS.LANG, { restaurant_id: restaurant.id, language: languageName }) }}
                        // className={classes.circle}
                        style={{
                            backgroundColor: localStorage.getItem('languageMobile') === languageName ? 'rgba(0,0,0,0.5)' : '',
                            color: localStorage.getItem('languageMobile') === languageName ? '#ffffff' : ''
                        }}>
                        <span>{languageName}</span>
                    </MenuItem>
                )
            })
        )
    }

    const ProductsSwiper = ({ initialSlide }) => {
        return (
            <Swiper
                allowTouchMove={allowTouch}
                className={`${classes.pageSwiper} ${classes.minHeight}`}
                onSwiper={(swiper) => {
                    swiper.slideTo(initialSlide, false, false)
                }}
                onSlideChangeTransitionStart={(swiper) => setTimeout(() => {
                    const index = swiper.activeIndex
                    setAllowTouch(false)
                    setProduct(products[index])
                    props.history.push(`/mobile/${props.match.params.RESTAURANT_ID}/menu/${props.match.params.MENU_ID}/product/${products[index].id}`)
                }, 100)}>
                {products.map(product => {
                    return (
                        <SwiperSlide key={product.id} className={`${classes.container} ${colorTheme === 'darkMode' ? classes.containerDarkMode : classes.containerLightMode}`}>
                            <Modal
                                open={openImageModal}
                                onClose={() => setImageModal(false)}
                                onCancel={() => setImageModal(false)}
                                fullScreen={true}
                                title={product.name[localStorage.getItem('languageMobile')]}
                            >
                                <img src={product.photoUrl} alt='' className={classes.productImage} />
                            </Modal>
                            <div className={classes.headerContainer}>
                                <img src={product.photoUrl} alt='' />
                            </div>
                            <div className={classes.content}>
                                <div className={`${classes.priceContainer} ${colorTheme === 'darkMode' ? classes.priceContainer_darkMode : classes.priceContainer_lightMode}`}>
                                    <h1 className={classes.currentProductName}>{product.name[localStorage.getItem('languageMobile')]}</h1>
                                    <h1>{getPrice(product.price, restaurant.currencies, restaurant.languages)}</h1>
                                </div>
                                {/* <div className={classes.productDetails}>
                                    <Grid justifyContent='center' columnSpacing={{ xs: 1, sm: 2, md: 3 }} container alignItems='center' className={`${classes.gridProductDetails} ${colorTheme === 'darkMode' && classes.whiteImage}`}>
                                        {product.waitingTime ?
                                            <Grid item xs={6}>
                                            <p className={classes.info}> <img src='/assets/preparation_time.png' alt='' />{product.waitingTime} {i18n.waitingTime[lang]}</p> {product.weight && <Divider orientation='vertical' flexItem />}
                                            </Grid> : null}
                                        {product.weight ?
                                        <Grid item xs={6}>
                                            <p className={classes.info}> <img src='/assets/weight.png' alt='' />{product.weight} {product.weightUnit}</p> {product.quantity && <Divider orientation='vertical' flexItem /> }
                                        </Grid> : null}
                                        {product.quantity ?
                                        <Grid item xs={6}>
                                            <p className={classes.info}> <img src='/assets/quantity.png' alt='' />{product.quantity} {quantityTranslate(product.quantity, product.quantityUnit)}</p> {product.calories && <Divider orientation='vertical' flexItem />}
                                        </Grid> : null}
                                        {product.calories ?
                                            <Grid item xs={6}>
                                            <p className={classes.info}> <img src='/assets/calories.png' alt='' />{product.calories} {i18n.calories[lang]}</p>
                                            </Grid> : null}
                                        </Grid>
                                    </div> */}

                                <div className={classes.gridProductOrderInfo}>
                                    {product.waitingTime ?
                                        <Grid item xs={12}>
                                            <p className={classes.info}> <img className={`${colorTheme === 'darkMode' && classes.whiteImage}`} src='/assets/preparation_time.png' alt='' />{product.waitingTime} {i18n.waitingTime[lang]}</p>
                                            <Divider orientation='horizontal' flexItem className={classes.gridProductDevider} />
                                        </Grid> : null}

                                    {product.weight ?
                                        <Grid item xs={12}>
                                            <p className={classes.info}> <img className={`${colorTheme === 'darkMode' && classes.whiteImage}`} src='/assets/weight.png' alt='' />{product.weight} {product.weightUnit}</p>
                                            <Divider orientation='horizontal' flexItem className={classes.gridProductDevider} />
                                        </Grid> : null}
                                    {product.quantity ?
                                        <Grid item xs={12}>
                                            <p className={classes.info}> <img className={`${colorTheme === 'darkMode' && classes.whiteImage}`} src='/assets/quantity.png' alt='' />{product.quantity} {quantityTranslate(product.quantity, product.quantityUnit)}</p>
                                            <Divider orientation='horizontal' flexItem className={classes.gridProductDevider} />
                                        </Grid> : null}
                                    {product.calories ?
                                        <Grid item xs={12}>
                                            <p className={classes.info}> <img className={`${colorTheme === 'darkMode' && classes.whiteImage}`} src='/assets/calories.png' alt='' />{product.calories} {i18n.calories[lang]}</p>
                                            <Divider orientation='horizontal' flexItem className={classes.gridProductDevider} />
                                        </Grid> : null}
                                    {product.fieldName && product.fieldValue && product.fieldValue[lang] ? <Grid item xs={12}>
                                        <p className={classes.info}>{product.fieldName[lang]}: {product.fieldValue[lang]}</p>
                                    </Grid> : null}
                                </div>

                                <div className={classes.detailsContainer}>
                                    {/* <Collapse in={openDescription} collapsedSize={55} >
                                        <p className={!openDescription && classes.productDescriptionFade}>{product.description[localStorage.getItem('languageMobile')]}</p>
                                    </Collapse>
                                    {product.description[localStorage.getItem('languageMobile')].length > 256 && 
                                        <IconButton className={classes.buttonCollapse} onClick={() => setOpenDescription(!openDescription)}> 
                                            {i18n.read_more[lang]} 
                                            {openDescription ? <ExpandLess className={classes.expandIcon}/> : 
                                            <ExpandMore className={classes.expandIcon}/>}  
                                        </IconButton>} */}
                                    {getTextBasedOnReadMore(product.description[localStorage.getItem('languageMobile')] || '', 'ingredients')}
                                    {product.ingredients[localStorage.getItem('languageMobile')] &&
                                        <>
                                            <h2>{i18n.ingredients[lang]}:</h2>
                                            <p>{getTextBasedOnReadMore(product.ingredients[localStorage.getItem('languageMobile')] || '', 'description')}</p>
                                        </>}

                                    {product.allergens.length ? <div className={classes.flexRow}>
                                        <h2>{i18n.allergens[lang]}:</h2>
                                        <div>
                                            {product.allergens.map((allergen, index) => {
                                                return (
                                                    <span className={classes.allergenStyle}>{`${i18n.allergensTranslate[allergen][lang]}${index < product.allergens.length - 1 ? ',' : ''}`}</span>
                                                )
                                            })}
                                        </div>
                                    </div> : null}
                                    {product.goesWellWith && product.goesWellWith.length ?
                                        <>
                                            <Divider orientation='horizontal' flexItem className={classes.separatorSections} />
                                            <div>
                                                <div className={classes.goesWellWith}>
                                                    <span>{currentMenu && currentMenu.goesWellWith && currentMenu.goesWellWith[lang] && currentMenu.goesWellWith[lang].length ? currentMenu.goesWellWith[lang] : i18n.goesWellWith[lang]}</span>
                                                    <IconButton onClick={() => setOpenGoesWellWith(!openGoesWellWith)}>
                                                        {openGoesWellWith ? <ExpandLess fontSize='large' /> : <ExpandMore fontSize='large' />}
                                                    </IconButton>
                                                </div>
                                                <Collapse in={openGoesWellWith} >
                                                    <div className={classes.goesWellContainer}>
                                                        <Swiper
                                                            spaceBetween={0}
                                                            navigation
                                                            slidesPerView='auto'
                                                            className={classes.swiperContainer}
                                                            breakpoints={SWIPER_BREAKPOINTS}
                                                        >
                                                            {product.goesWellWith.map(goesWellProduct => {
                                                                return (
                                                                    <SwiperSlide key={goesWellProduct.id}>
                                                                        <Card className={`${classes.product} ${colorTheme === 'darkMode' ? classes.productBackgroundDarkMode : classes.productBackgroundLightMode}`} onClick={() => onCardClick(goesWellProduct.id, goesWellProduct.name[localStorage.getItem('defaultLanguage')])} >
                                                                            <CardActionArea>
                                                                                <CardMedia
                                                                                    component='img'
                                                                                    height='160'
                                                                                    image={goesWellProduct.photoThumbUrl}
                                                                                    className={classes.cardMediaContainer}
                                                                                />
                                                                                <CardContent className={classes.cardProductName}>
                                                                                    <Typography component='h1' className={`${classes.productName} ${classes.cardProductNameText}`}>
                                                                                        {Number(goesWellProduct.name[localStorage.getItem('languageMobile')] && goesWellProduct.name[localStorage.getItem('languageMobile')].length) > 25 ? goesWellProduct.name[localStorage.getItem('languageMobile')].slice(0, 25) + '...' : goesWellProduct.name[localStorage.getItem('languageMobile')]}
                                                                                    </Typography>
                                                                                </CardContent>
                                                                                <CardContent className={classes.cardProductDetails}>
                                                                                    <Typography className={classes.textDetails} component='h1' >
                                                                                        Detalii &nbsp; <ArrowRightAlt className={classes.arrowColor} fontSize='small' />
                                                                                    </Typography>
                                                                                </CardContent>
                                                                                <CardContent className={classes.WeightPrice}>
                                                                                    {/* {goesWellProduct.weight ? <Typography component='span' className={`${classes.productName} ${classes.weightGray}`}> {goesWellProduct.weight} {goesWellProduct.weightUnit}</Typography> : null} */}
                                                                                    {goesWellProduct.price ? <Typography component='h1' className={classes.priceGoWell}>{getPrice(goesWellProduct.price, restaurant.currencies, restaurant.languages)}</Typography> : null}
                                                                                    <div className={classes.productSpiceVeganContainer}>
                                                                                        {goesWellProduct.veganTag && goesWellProduct.veganTag !== "none" ? <div className={classes.productSpicyContainer}> <img className={classes.vegetarianImage} src="/assets/vegetarian.png" alt="vegetarian" /> </div> : null}
                                                                                        {goesWellProduct.isSpicy ? <div className={classes.productSpicyContainer}> <img className={classes.spicyImage} src="/assets/spicy.png" alt="spicy" /> </div> : null}
                                                                                    </div>
                                                                                </CardContent>
                                                                            </CardActionArea>
                                                                        </Card>
                                                                    </SwiperSlide>
                                                                )
                                                            })}
                                                        </Swiper>
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </> : null}
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        )
    }

    if (product && restaurant && products) {
        return (
            <>
                <div className={classes.header}>
                    <div className={classes.topFade} />
                    <Link
                        className={classes.linkStyle}
                        to={{
                            pathname: `/mobile/${props.match.params.RESTAURANT_ID}/menu/${props.match.params.MENU_ID}/category/${product.category}`,
                            state: { selectedSubcategoryId: product.subcategory }
                        }}>
                        <Button className={classes.topButtonsStyle}><ArrowBackIos className={classes.topButtons} fontSize='large' /></Button>
                    </Link>

                    <div className={classes.currenncyStyle}>
                        <List component='nav' className={classes.root}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={circleState.colorTheme}
                                        icon={<RadioButtonUnchecked />}
                                        checkedIcon={<RadioButtonChecked />}
                                        onChange={handleChange}
                                        name={COLORTHEME}
                                    />
                                }
                                className={classes.checkBoxColorTheme}
                            />
                            <Divider orientation='vertical' flexItem />
                            <Button
                                ref={anchorRef}
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup='true'
                                onClick={handleToggle}
                            >
                                <Language /> <KeyboardArrowDown />
                            </Button>
                            <Popper style={{ zIndex: '1' }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList autoFocusItem={open} id='menu-list-grow'>
                                                    {renderLanguages()}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </List>
                    </div>
                </div>
                <ProductsSwiper initialSlide={products.findIndex(prod => prod.id === product.id)} />
            </>
        )
    } else return null
}

export default withRouter(ProductDetails)