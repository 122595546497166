import React from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'

let RestaurantAnalytics = props => {
    let { classes } = props

    let link = `https://datastudio.google.com/embed/reporting/1ab1de4c-7452-407d-8a96-9b93f8affa0c/page/rchRC/?params=%7B%22ds131.restaurant_id%22%3A%22${props.restaurantId}%22%7D`

    return (
        <div className={classes.container}>
            <iframe title="RestaurantAnalyticsTitle" className={classes.responsiveIframe} src={link} frameBorder="0" style={{ border: 0 }}></iframe>
        </div>
    )
}

let styles = theme => ({
    container: {
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        paddingTop: '80%'
    },
    responsiveIframe: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%'
    }
})

export default withRouter(withStyles(styles)(RestaurantAnalytics))