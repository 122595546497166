import axios from '../axios'
import { errorHandling } from '../helpers'

export const getByRestaurantId = restaurantId => axios.get(`restaurants/${restaurantId}/menus`)
    .then(res => res.data)
    .catch(errorHandling)

export const create = (restaurantId, menu) => axios.post(`restaurants/${restaurantId}/menus`, menu)
    .then(res => res.data)
    .catch(errorHandling)

export const edit = (restaurantId, menuId, menu) => axios.put(`restaurants/${restaurantId}/menus/${menuId}`, menu)
    .then(res => res.data)
    .catch(errorHandling)

export const getById = (restaurantId, menuId) => axios.get(`restaurants/${restaurantId}/menus/${menuId}`)
    .then(res => res.data)
    .catch(errorHandling)

export const deleteMenu = (restaurantId, menuId) => axios.delete(`restaurants/${restaurantId}/menus/${menuId}`)
    .then(res => res.data)
    .catch(errorHandling)

// mobile

export const getByRestaurantIdMobile = (restaurantId, isHidden) => axios.get(`/mobile/restaurants/${restaurantId}/menus?isHidden=${isHidden}`)
    .then(res => res.data)
    .catch(errorHandling)