export const DEFAULT_DECIMAL_SIZE = 10
export const DEFAULT_ROWS_PER_PAGE = [10]
export const DEFAULT_ROWS_PER_PAGE_VALUE = 10

export const CATEGORIES = [
    { label: 'Food', name: 'food', value: true },
    { label: 'Drinks', name: 'drinks', value: true },
    { label: 'Daily Menu', name: 'dailyMenu', value: true }
]

export const SUB_CATEGORIES = [
    { label: 'Salads', name: 'salads', value: true },
    { label: 'Snacks', name: 'snacks', value: true },
    { label: 'Soups', name: 'soups', value: true }
]

export const ALLERGENS = [
    { label: 'Cereals containing gluten', value: 'cerealsContainingGluten' },
    { label: 'Crustaceans', value: 'crustaceans' },
    { label: 'Eggs', value: 'eggs' },
    { label: 'Fish', value: 'fish'},
    { label: 'Peanuts', value: 'peanuts'},
    { label: 'Soybeans', value: 'soybeans'},
    { label: 'Milk', value: 'milk'},
    { label: 'Nuts', value: 'nuts'},
    { label: 'Celery', value: 'celery'},
    { label: 'Sesame seeds', value: 'sesameSeeds'},
    { label: 'Sulphur dioxide and sulphites', value: 'sulphurDioxideAndSulphites'},
    { label: 'Lupin', value: 'lupin'},
    { label: 'Molluscs', value: 'molluscs'},
    { label: 'Mustard', value: 'mustard'}
]

export const EVENTS = {
    FIRST_VISIT: 'first_visit',
    SESSION_START: 'session_start',
    QR_CODE: 'qr_code_scan',
    ABOUT: 'about_clicked',
    CONTACT: 'contact_clicked',
    LANG: 'lang_clicked',
    RELATED: 'related_clicked',
    PRODUCT: 'product_clicked',
    CATEGORY: 'category_clicked'
}

export const AXIS = {
    AXISX: 'x',
    AXISY: 'y',
    AXISXY: 'xy'
}

export const SUBCATEGORY_OPTIONS = {
    DELETE: 'delete',
    HIDE: 'hide',
    EDIT: 'edit'
}

export const CATEGORY_AVAILABILITY = {
    START_TIME: 'Start Time',
    END_TIME: 'End Time',
    TIMEFRAME_SUCCES: 'Timeframe successfully added',
    TIMEFRAME_ERROR: 'Error setting a timeframe',
    SETTINGS: 'SETTINGS',
    SAVE: 'SAVE',
    CANCEL: 'CANCEL',
  }

export const CATEGORY_LIST_TEXT = {
    AVAILABILITY: 'AVAILABILITY TIMEFRAME',
}

export const MOMENT_FORMATS = {
    HOURS_AND_MINUTES : 'HH:mm'
}

export const PRODUCT_DETAILS_ACTIONS = {
    CLOSE: 'CLOSE',
    DELETE: 'DELETE',
    SLIDER_LIMIT: 5
}

export const SUBCATEGORY_GOESWELL_PRODUCTS = {
    LIMIT: 4
}

export const COLORTHEME = 'colorTheme'

export const MENU_CONTENT_MOBILE = {
    SEARCH: 'search',
    SEARCH_PLACEHOLDER: 'Search..',
    PRODUCT_NAME_LENGTH: 30
}

export const RESTAURANT_DESIGN = {
    light: 'light',
    dark: 'dark',
    primary: 'primary',
    secondary: 'secondary',
    primaryText: 'primaryText',
    secondaryText: 'secondaryText',
    LIGHT_THEME: 'Light theme',
    DARK_THEME: 'Dark theme',
    PRIMARY_COLOR: 'Primary',
    SECONDARY_COLOR: 'Secondary',
    PRIMARY_TEXT_COLOR: 'Primary text',
    SECONDARY_TEXT_COLOR: 'Secondary text'
}

export const SWIPER_BREAKPOINTS = {
    320: {
        width: 320,
        slidesPerView: 2.3
    },
    360: {
        width: 360,
        slidesPerView: 2.7
    },
    500: {
        width: 500,
        slidesPerView: 2.9,
    },
    768: {
        width: 768,
        slidesPerView: 4.4,
    },
    1024: {
        width: 1024,
        slidesPerView: 5.5
    },
    1150: {
        width: 1150,
        slidesPerView: 5.9
    }
}