import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import { MoreVert, Add } from '@material-ui/icons'
import CreateSubcategory from './CreateSubcategory'
import TooltipMenu from '../../../common/TooltipMenu'
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc'
import { i18n } from '../../../utils/helpers'
import i18nLang  from '../../../utils/i18n.json'
import { AXIS, SUBCATEGORY_OPTIONS } from '../../../utils/constants'
let SubcategoryList = props => {
    
    let { classes } = props

    const [openModal, setOpenModal] = useState(false)
    const [editing, setEditing] = useState(false)
    const [subcategoryToEdit, setSubcategoryToEdit] = useState([])
    let lang = localStorage.getItem('currentLanguage')
    const tooltipMenuOptions = [
        { label: i18nLang.subcategoryOptions.edit[lang], value: SUBCATEGORY_OPTIONS.EDIT },
        { label: i18nLang.subcategoryOptions.hide[lang], value: SUBCATEGORY_OPTIONS.HIDE },
        { label: i18nLang.subcategoryOptions.delete[lang], value: SUBCATEGORY_OPTIONS.DELETE },
    ]

    const tooltipMenuOptionsUnhide = [
        { label: i18nLang.subcategoryOptions.edit[lang], value: SUBCATEGORY_OPTIONS.EDIT },
        { label: i18nLang.subcategoryOptions.unHide[lang], value: SUBCATEGORY_OPTIONS.HIDE },
        { label: i18nLang.subcategoryOptions.delete[lang], value: SUBCATEGORY_OPTIONS.DELETE },
    ]

    React.useEffect(() => {
    }, [])

    const onClickButton = (data, id, category) => {
        if (data === SUBCATEGORY_OPTIONS.DELETE) props.onDelete(id)
        if (data === SUBCATEGORY_OPTIONS.HIDE) props.onHide(id)
        if (data === SUBCATEGORY_OPTIONS.EDIT) {
            setEditing(true)
            setOpenModal(true)
            setSubcategoryToEdit(category)
        }
    }

    const onSortEndHandler = ({ oldIndex, newIndex }) => {
        props.setSubcategoriesOrder(arrayMove(props.categories, oldIndex, newIndex)
            .map((subcategory, index) => ({ ...subcategory, order: index })))
    }

    const SortableItem = SortableElement(({ category, sortIndex }) => <div key={sortIndex} className={classes.categoryContainer} style={props.selectedSubCategory.id === category.id ? { backgroundColor: '#666b70', color: '#ffffff' } : {}}>
        <TooltipMenu onClick={data => onClickButton(data, category.id, category)} buttonClass={classes.optionsClass} frontIcon={<MoreVert className={classes.iconSizeSmall} />} options={category.isHidden ? tooltipMenuOptionsUnhide : tooltipMenuOptions} />
        <div onClick={() => props.onClickSubcategory(category)} className={`${classes.equalFlex} ${classes.sortableSubcategory} ${classes.categoryName} ${category.isHidden && classes.hiddenSubCategory}`} > <p>{i18n(category.label)} ({category.count || 0})</p></div>
        {/* <div className={`${classes.alignCenter}`}><Close onClick={() => deleteSubcategory(category.label)} className={classes.iconSize} /></div> */}
    </div>)

    const SortableList = SortableContainer(({ items }) => {
        return (
            <div className={classes.sortableContainer}>
                {items.map((category, index) => {
                  return (<SortableItem key={index} index={category.order} sortIndex={category.order} category={category} />)
                })}
            </div>
        )
    })

    return (
        <div className={classes.container}>
            <CreateSubcategory
                menu={props.menu}
                getSubcategories={props.getSubcategories}
                selectedCategory={props.selectedCategory}
                languages={props.restaurant.languages}
                open={openModal}
                subcategoryToEdit={subcategoryToEdit}
                editing={editing}
                onClose={() => {
                    setOpenModal(false)
                    setSubcategoryToEdit([])
                }}
            />
            {props.showAddButton && <div className={`${classes.categoryContainer} ${classes.addSubcategory}`} 
                onClick={() => { 
                    setEditing(false)  
                    setOpenModal(true)
                }}>
                <Add className={classes.iconSize} />
                <div className={`${classes.equalFlex} ${classes.categoryName}`} > <p>Add subcategory</p></div>
                {/* <div className={`${classes.alignCenter}`}><Close onClick={() => deleteSubcategory(category.label)} className={classes.iconSize} /></div> */}
            </div>}
            <SortableList distance={2} axis={AXIS.AXISX} lockAxis={AXIS.AXISX} onSortEnd={onSortEndHandler} items={props.categories} />
            {/* <Button onClick={() => setOpenModal(true)}><Add className={classes.addIcon} />
                <span className={classes.buttonText}>Add Subcategory</span>
            </Button> */}
        </div>
    )
}

let styles = theme => ({
    optionsClass: {
        minWidth: 20,
        height: '100%',
        paddingLeft: 2,
        paddingRight: 2,
        boxSizing: 'border-box'
    },
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    categoryContainer: {
        height: 35,
        paddingRight: '10px',
        border: '1px solid rgba(0,0,0,0.2)',
        borderRadius: 5,
        margin: '0px 10px 5px 10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        boxSizing: 'border-box',
        backgroundColor: 'white',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    equalFlex: {
        flex: 1
    },
    alignCenter: {
        textAlign: 'center'
    },
    iconSize: {
        fontSize: 20,
        paddingLeft: 8,
        paddingRight: 8
    },
    categoryName: {
        paddingLeft: 8
    },
    buttonText: {
        color: 'white',
        paddingTop: 3
    },
    addIcon: {
        color: 'white'
    },
    wrapper: {
        margin: theme.spacing(0.1),
        position: 'relative',
      },
    buttonSuccess: {
        color: 'white',
        backgroundColor: 'transparent',
        '&:hover': {
          background: 'transparent',
        },
    },
    buttonProgress: {
        color: 'white',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    hiddenSubCategory: {
        textDecoration: 'line-through',
        fontStyle: 'italic'
    },
    addSubcategory: {
        color: 'white',
        backgroundColor: 'rgb(52, 170, 68)'
    },
    sortableSubcategory: {
        display: 'flex', 
        flexDirection: 'row', 
        width: 'max-content'
    },
    sortableContainer: {
        width: 'calc(100% - 200px)', 
        overflowX: 'auto', 
        display: 'flex', 
        flexDirection: 'row', 
        overflowY: 'hidden'
    }
})

export default withRouter(withStyles(styles)(SubcategoryList))