import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { Dialog, DialogContent, DialogActions, DialogTitle, Button, TextField, DialogContentText, withStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import i18nLang from '../../../utils/i18n.json'
import CategoryIconSelector from './CategoryIconSelector'
const styles = (theme) => ({
    dialogContent: {
      display: 'flex',
      justifyContent: 'space-around',
    },
    timePicker: {
      flex: '0.4',
    },
    dialogActions: {
      borderTop: '1px solid #eaedf3',
      marginTop: 10,
      paddingTop: 25,
    },
    closeStyle: {
      marginRight: 'auto',
    },
    topRightCancelButton: {
      float: 'right',
      margin: '5px 15px 0px 0px',
      cursor: 'pointer',
      fontSize: 18,
    },
    description: {
      marginLeft: '1.5em',
      fontSize: '15px'
    }
})

const CategorySettings = (props) => {
    const [startTime, setStartTime] = useState(props.startTime)
    const [endTime, setEndTime] = useState(props.endTime)
    const [categoryLightIcon, setLightIcon] = useState(undefined)
    const [categoryDarkIcon, setDarkIcon] = useState(undefined);

    const saveSettings = () => {
        const data = new FormData();
        if(categoryDarkIcon)
          data.append('imageDark', categoryDarkIcon);
        if(categoryLightIcon)
          data.append('imageLight', categoryLightIcon);

        props.saveChanges(startTime, endTime, data)
    }
    const lang = localStorage.getItem('currentLanguage')
    let { classes } = props
    return (
        <Dialog aria-labelledby='customized-dialog-title' open={props.open} fullWidth={true}>
            <DialogTitle>
                {props.title}
                <Close className={classes.topRightCancelButton} onClick={() => props.onClose()}/>
            </DialogTitle>
            <DialogContentText className={classes.description}>
                {i18nLang.categorySettings.description.timeframe[lang]} :
            </DialogContentText>
            <DialogContent id='customized-dialog-title' className={classes.dialogContent}>
                <TextField
                  className={classes.timePicker}
                  id='time'
                  label={i18nLang.categorySettings.startTime[lang]}
                  type='time'
                  defaultValue={props.startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                />
                <TextField
                  className={classes.timePicker}
                  id='time'
                  label={i18nLang.categorySettings.endTime[lang]}
                  type='time'
                  defaultValue={props.endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                />
            </DialogContent>
            <CategoryIconSelector 
               category={props.categoryToEdit} 
               setDarkIcon={setDarkIcon}
               setLightIcon={setLightIcon}   
            />
            <DialogActions className={classes.dialogActions}>
                {props.cancelButtonText && (
                <Button className={classes.closeStyle} onClick={() => props.onClose()} color='secondary'>
                    {props.cancelButtonText}
                </Button>
                )}
                {props.acceptButtonText && (
                <Button disabled={props.loading} onClick={() => saveSettings()} color='primary'>
                    {props.acceptButtonText}
                </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

export default withRouter(withStyles(styles)(CategorySettings))