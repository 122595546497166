import axios from '../axios'
import { errorHandling } from '../helpers'

export const login = (email, password) => axios.post('/auth', { email, password })
    .then(res => res.data)
    .catch(errorHandling)


export const isAuth = () => axios.get('/isAuth')
    .then(res => res.data)
