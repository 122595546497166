import React from 'react'
import { withStyles, CircularProgress } from '@material-ui/core'


let FullPageLoading = props => {
    let { classes } = props
    return (
        <div className={`${props.fullHeight ? classes.container : classes.marginTop} ${classes.center}`}>
            <CircularProgress />
            <h4 className={classes.loadingText}>LOADING...</h4>
        </div>
    )
}

let styles = theme => ({
    marginTop: {
        marginTop: '100px'
    },
    container: {
        height: '100%',
        width: '100%'
    },
    center: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    loadingText: {
        letterSpacing: '3px'
    }
})

export default withStyles(styles)(FullPageLoading)