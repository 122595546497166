import axios from '../axios'
import { errorHandling } from '../helpers'

export const deleteRestaurant = restaurantId => axios.delete(`restaurants/${restaurantId}`).then(res => res.data)

export const createRestaurant = restaurant => axios.post(`restaurants`, restaurant, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
})
    .then(res => res.data)
    .catch(errorHandling)

export const edit = (restaurantId, restaurant) => axios.patch(`restaurants/${restaurantId}`, restaurant, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
})
    .then(res => res.data)
    .catch(errorHandling)

export const setCurrencies = (restaurantId, restaurant) => axios.patch(`restaurants/${restaurantId}/currencies`, restaurant)
    .then(res => res.data)
    .catch(errorHandling)


export const getImagesByRestaurantId = restaurantId => axios.get(`restaurants/${restaurantId}/images`)
    .then(res => res.data)
    .catch(errorHandling)

export const deleteImage = (restaurantId, imageId) => axios.delete(`restaurants/${restaurantId}/images/${imageId}`)
    .then(res => res.data)
    .catch(errorHandling)

export const uploadFiles = (restaurantId, files) => axios.post(`restaurants/${restaurantId}/images`, files)
    .then(res => res.data)
    .catch(errorHandling)

export const get = () => axios.get(`/restaurants`)
    .then(res => res.data)
    .catch(errorHandling)

export const getById = restaurantId => axios.get(`/restaurants/${restaurantId}`)
    .then(res => res.data)
    .catch(errorHandling)

export const getRestaurantDesign = restaurantId => axios.get(`/restaurants/${restaurantId}/design`)
    .then(res => res.data)
    .catch(errorHandling)

export const updateRestaurantDesign = (restaurantId, design) => axios.patch(`/restaurants/${restaurantId}/design`, design)
    .then(res => res.data)
    .catch(errorHandling)


// mobile

export const getByIdMobile = restaurantId => axios.get(`/mobile/restaurants/${restaurantId}`)
    .then(res => res.data)
    .catch(errorHandling)