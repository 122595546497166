import axios from '../axios'
import { errorHandling } from '../helpers'

export const getByRestaurantId = restaurantId => axios.get(`restaurants/${restaurantId}/tablets`)
    .then(res => res.data)
    .catch(errorHandling)

export const create = (restaurantId, tablet) => axios.post(`restaurants/${restaurantId}/tablets`, tablet)
    .then(res => res.data)
    .catch(errorHandling)

export const edit = (restaurantId, tabletId, tablet) => axios.patch(`restaurants/${restaurantId}/tablets/${tabletId}`, tablet)
    .then(res => res.data)
    .catch(errorHandling)

export const getById = (restaurantId, tabletId) => axios.get(`restaurants/${restaurantId}/tablets/${tabletId}`)
    .then(res => res.data)
    .catch(errorHandling)

export const deleteTablet = (restaurantId, tabletId) => axios.delete(`restaurants/${restaurantId}/tablets/${tabletId}`)
    .then(res => res.data)
    .catch(errorHandling)