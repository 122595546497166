import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core'

import Modal from '../../../common/Modal'

import * as RESTAURANTS_REQUESTS from '../../../utils/requests/restaurants'

let ImagesGalleryModal = props => {

    const [images, setImages] = useState([])

    useEffect(() => {
        RESTAURANTS_REQUESTS.getImagesByRestaurantId(props.restaurantId).then(images => setImages(images))
    }, [props.restaurantId])

    return (
        <Modal
            title={"Select product image"}
            maxWidth={'md'}
            onCancel={props.onClose}
            open={props.open}>
            <div className={props.classes.imagesContainer}>
                {images.map((image, index) => <img key={index} src={image.thumbUrl || image.url} alt='' className={props.classes.imageGrid} onClick={() => props.selectImage(image)} />)}
            </div>
        </Modal>
    )
}

let styles = theme => ({
    imagesContainer: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    imageGrid: {
        width: '25%',
        padding: '5px',
        boxSizing: 'border-box',
        cursor: 'pointer',
        border: '2px solid #ffffff',
        '&:hover': {
            border: '2px solid #e0e0e0'
        }
    }
})

export default withStyles(styles)(ImagesGalleryModal)