import React, { useState } from 'react'
import { withStyles } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { withRouter } from 'react-router-dom'

import Card from '../../../common/Card'
import CreateProduct from './CreateProduct'
import ProductDetails from './ProductDetails'
import * as PRODUCTS_REQUESTS from '../../../utils/requests/products'
import * as CATEGORIES_REQUESTS from '../../../utils/requests/categories'
import * as NOTIFICATION from '../../../utils/notification'
import i18nLang  from '../../../utils/i18n.json'
import { i18n } from '../../../utils/helpers'
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc'
import { AXIS } from '../../../utils/constants'
let ProductsList = props => {

    let { classes } = props

    const [openModal, setOpenModal] = useState(false)
    const [editing, setEditing] = useState(false)
    const [productToEdit, setProductToEdit] = useState(null)
    const [openDetails, setOpenDetails] = useState(false)
    const [productForDetails, setProductForDetails] = useState(null)
    const [products, setProducts] = useState(props.products)
    let lang = localStorage.getItem('currentLanguage')

    React.useEffect(() => {
    }, [])

    let onEditHandler = productId => {
        setOpenModal(true)
        setEditing(true)
        setProductToEdit(productId)
    }

    let getProductDetails = async product => {
        let productCopy = { ...product }
        let results = await Promise.all([
            CATEGORIES_REQUESTS.getById(props.restaurantId, props.menuId, product.category),
            CATEGORIES_REQUESTS.getSubcategoryById(props.restaurantId, props.menuId, product.category, product.subcategory)
        ])

        productCopy.category = i18n(results[0].name)
        productCopy.subcategory = i18n(results[1].name)
        setProductForDetails(productCopy)
        setOpenDetails(true)
    }

    let onDeleteHandler = product => {
        if (window.confirm(`Please confirm deletion of product: ${i18n(product.name)}`)) {
            PRODUCTS_REQUESTS.deleteById(props.restaurantId, product.id).then(() => {
                NOTIFICATION.success('Product deteled succcessfully')
                props.getProducts()
            }).catch(() => {
                NOTIFICATION.error('Could not delete product')
            })
        }
    }

    let onCloneHandler = () => {
        PRODUCTS_REQUESTS.setGlobal(props.restaurantId, productForDetails.id)
            .then(() => {
                NOTIFICATION.success('Product set as global')
                setOpenDetails(false)
            }).catch(() => {
                NOTIFICATION.error('Could not set product as global')
            })
    }

    const onHideHandler = (product) => {
        const nextState = [...products]
        nextState.forEach((productProp) => {
            if(productProp.id === product.id)
                productProp.isHidden = !productProp.isHidden
        })
        PRODUCTS_REQUESTS.hide(props.restaurantId,product.id,{
            isHidden: product.isHidden
        }).then(() => {
            setProducts(nextState) 
            product.isHidden ? NOTIFICATION.success(i18nLang.hideProduct.succesHide[lang]) : NOTIFICATION.success(i18nLang.hideProduct.succesUnhide[lang])
        }).catch(() => {
            product.isHidden ? NOTIFICATION.error(i18nLang.hideProduct.errorHide[lang]) : NOTIFICATION.error(i18nLang.hideProduct.errorUnhide[lang])
        })
    }

    const onSortEndHandler = ({ oldIndex, newIndex }) => {
      let nextState = arrayMove(products, oldIndex, newIndex)
      PRODUCTS_REQUESTS.reorder(props.restaurantId, nextState.map((product, index) => ({ id: product.id, order: index })))
      setProducts(nextState)
    }

    const SortableItem = SortableElement(({ product, sortIndex }) => (
      <div key={`${sortIndex}_SortableItem`} className={classes.productContainer}>
        <Card
          onClick={(product) => {
            getProductDetails(product)
          }}
          onEdit={(productId) => onEditHandler(productId)}
          onHide={(product) => onHideHandler(product)}
          item={product}
        />
      </div>
    ))
    const SortableList = SortableContainer(({ items }) => {
      return (
        <div className={classes.list}>
          <div
            onClick={() => setOpenModal(true)}
            className={classes.addContainer}
          >
            <div className={classes.circle}>
              <Add className={classes.addIcon} />
            </div>
            <h4>{i18nLang.addProduct[lang]}</h4>
          </div>
          {items.map((product, index) => {
            return (
              <SortableItem
                key={index}
                index={index}
                sortIndex={index}
                product={product}
              />
            )})}
        </div>
      )
    })
    
    return (
        <div className={classes.container}>
            {productForDetails && <ProductDetails
                open={openDetails}
                onClose={() => {
                    setProductForDetails(null)
                    setOpenDetails(false)
                }}
                onClone={onCloneHandler}
                onDelete={onDeleteHandler}
                translateFunction={i18n}
                product={productForDetails}
                currencies={props.restaurant.currencies}
                languages={props.restaurant.languages}
            />}
            {props.showAddButton && <><CreateProduct
                currentMenu={props.currentMenu}
                editing={editing}
                productToEdit={productToEdit}
                onSearch={props.onSearch}
                categoriesAndSubcategories={props.categoriesAndSubcategories}
                selectedCategory={props.selectedCategory}
                getRestaurantProducts={props.getRestaurantProducts}
                menuId={props.menuId}
                restaurantId={props.restaurantId}
                currencies={props.restaurant.currencies}
                languages={props.restaurant.languages}
                availableProducts={props.goesWellProducts}
                getProducts={() => props.getProducts()}
                open={openModal}
                onClose={() => {
                  setOpenModal(false)
                  setEditing(false)
                  setProductToEdit(null)
                }}
                order={props.products.length}/>
              </>}
            <SortableList
              distance={1}
              items={products}
              onSortEnd={onSortEndHandler}
              axis={AXIS.AXISXY}
            />
        </div>
    )
}

let styles = theme => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    addIcon: {
        fontSize: 25
    },
    equalFlex: {
        flex: 1
    },
    alignCenter: {
        textAlign: 'center'
    },
    iconSize: {
        fontSize: 24,
        paddingLeft: 8,
        paddingRight: 8
    },
    categoryName: {
        paddingLeft: 8
    },
    productContainer: {
        margin: 10,
        height: 220
    },
    addContainer: {
        height: 220,
        width: 274,
        border: '1px solid rgba(0,0,0,0.1)',
        borderRadius: 5,
        margin: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#9ca4ab',
        backgroundColor: 'white',
        boxShadow: ' 7px 9px 16px 0px rgba(176,176,176,1)',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    circle: {
        backgroundColor: '#9ca4ab',
        borderRadius: '50%',
        height: 50,
        width: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        opacity: 0.3
    },
    list: {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: 'repeat(auto-fill, minmax(16em,1fr))',
      gridGap: '2em',
    },
    listOneLine: {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: 'repeat(4 ,1fr)',
      gridGap: '2em',
    },
})

export default withRouter(withStyles(styles)(ProductsList))