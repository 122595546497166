const language = {
    category: {
        food: "Food",
        drinks: "Drinks",
        dailyMenu: "Daily Menu"
    },
    subcategory: {
        salads: "Salads",
        snacks: "Snacks",
        soups: "Soups"
    }
}

export default language