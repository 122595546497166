import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const styles = theme => ({
    root: {
        display: 'flex',
        width: '100%'
    },
    formControl: {
        width: '100%'
    },
    formGroup: {
        display: 'inline'
    },
    radioGroup: {
        flexDirection: 'row',
        flexWrap: 'nowrap'
    },
    formLabelContainer: {
        fontSize: '10px',
        fontWeight: 500,
        color: '#9ea0a5',
        textTransform: 'uppercase',
        letterSpacing: 'normal'
    },
    label: {
        fontSize: '12px',
        color: '#78797d',
        flex: 1
    }
});

class RadioSelector extends React.Component {

    handleChange = event => {
        this.props.onChange({
            target: {
                name: this.props.name,
                value: event.target.value
            }
        })
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel className={this.props.classes.formLabelContainer} component="legend">{this.props.utils}</FormLabel>
                    <RadioGroup className={classes.radioGroup} value={this.props.value} disabled={this.props.disabled} onChange={event => this.handleChange(event)}>
                        {this.props.options.map((op, index) => {
                            return (
                                <FormControlLabel className={classes.label} disabled={this.props.disabled} key={index} value={op.value} control={<Radio />} label={op.label} />
                            )
                        })}
                    </RadioGroup>
                </FormControl>
            </div>
        );
    }
}

export default withStyles(styles)(RadioSelector)