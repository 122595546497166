import React, { useState } from 'react'
import { withStyles } from '@material-ui/core'
import { ChevronRight, Add, MoreVert } from '@material-ui/icons'
import { i18n } from '../../../utils/helpers'
import i18nLang from '../../../utils/i18n.json'
import TooltipMenu from '../../../common/TooltipMenu'
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc'
import * as NOTIFICATION from '../../../utils/notification'
import CreateCategory from './CreateCategory'
import moment from 'moment'
import CategorySettings from './CategorySettings'
import * as CATEGORIES_REQUESTS from '../../../utils/requests/categories'
import { AXIS, MOMENT_FORMATS } from '../../../utils/constants'
let CategoryList = props => {

    let { classes } = props
    const [openModal, setOpenModal] = useState(false)
    const [save, setSave] = useState(false)
    const [openAvailabilityModal, setOpenAvailabilityModal] = useState(false)
    const [categoryId, setCategoryId] = useState(undefined)
    const [startTime, setStartTime] = useState(undefined)
    const [endTime, setEndTime] = useState(undefined)
    const [editing, setEditing] = useState(false)
    const [categoryToEdit, setCategoryToEdit] = useState([])
    const lang = localStorage.getItem('currentLanguage')
    const tooltipMenuOptions = [
        { label: `${i18nLang.categorySettings.rename[lang]}`, value: 'rename' },
        { label : `${i18nLang.generateProducts[lang]}`, value: 'generate'},
        { label: `${i18nLang.categorySettings.settings[lang]}`, value: 'settings' },
        { label: `${i18nLang.categorySettings.delete[lang]}`, value: 'delete' },
    ]

    const onClickButton = (data, id, category) => {
        if (data === 'generate') {
            props.onGenerate(id);
        }
        if (data === 'settings') {
            setCategoryToEdit(category)
            setOpenAvailabilityModal(true)
            setSave(false)
            setCategoryId(id)
            setStartTime(moment('00:00', MOMENT_FORMATS.HOURS_AND_MINUTES).add(category.startTime, 'seconds').format(MOMENT_FORMATS.HOURS_AND_MINUTES))
            setEndTime(moment('00:00', MOMENT_FORMATS.HOURS_AND_MINUTES).add(category.endTime, 'seconds').format(MOMENT_FORMATS.HOURS_AND_MINUTES))
        }
        if (data === 'delete') props.onDelete(id)
        if (data === 'rename') {
            setCategoryToEdit(category)
            setEditing(true)
            setOpenModal(true)
        }
    }

    const saveChanges = async (newStartTime, newEndTime, icons) => {
        setSave(true)
        await CATEGORIES_REQUESTS.addTimeFrame(
            props.restaurant.id,
            props.menu.id,  
            categoryId, 
            {  
                startTime: newStartTime ? newStartTime : startTime,
                endTime: newEndTime ? newEndTime : endTime,
            }
        ).then(() => {
            props.getCategories(props.restaurant.id, props.menu.id)
            setOpenAvailabilityModal(false)
            NOTIFICATION.success(i18nLang.categorySettings.notification_succes[lang])
        }).catch(() => {
            NOTIFICATION.error(i18nLang.categorySettings.notification_error[lang])
        })
        
        await CATEGORIES_REQUESTS.upload(
            props.restaurant.id,
            props.menu.id,  
            categoryId, 
            icons
        ).then(() => {
            NOTIFICATION.success('Icons uploaded with success')
        }).catch(() => {
            NOTIFICATION.error('Error uploading icons, please check file size to be smaller than 50KB');
        })
    }

    const onSortEndHandler = ({ oldIndex, newIndex }) => {
        props.setCategoriesOrder(arrayMove(props.categories, oldIndex, newIndex)
            .map((category, index) => ({ ...category, order: index })))
    }

    const SortableItem = SortableElement(({ category, sortIndex }) => <div key={sortIndex} className={classes.categoryContainer}
        style={props.selectedCategory ? { background: i18n(props.selectedCategory.name).toLowerCase() === i18n(category.name).toLowerCase() ? '#9ca4ab' : 'white', color: i18n(props.selectedCategory.name).toLowerCase() === i18n(category.name).toLowerCase() ? 'white' : 'black', } : {}}
    >
        <TooltipMenu onClick={data => onClickButton(data, category.id, category)} buttonClass={classes.optionsClass} frontIcon={<MoreVert className={classes.iconSizeSmall} />} options={tooltipMenuOptions} />
        <div onClick={() => props.onClickCategory(category)} className={`${classes.equalFlex} ${classes.categoryName}`} > <p>{i18n(category.name)}</p></div>
        <div onClick={() => props.onClickCategory(category)} className={`${classes.alignCenter}`}><ChevronRight className={classes.iconSize} /></div>
    </div>)

    const SortableList = SortableContainer(({ items }) => {
        return (
            <div className={classes.sortableList}>
                {items.map((category, index) => {
                    return (<SortableItem key={index} index={category.order} sortIndex={category.order} category={category} />)
                })}
            </div>
        )
    })

    return (
        <div className={classes.container}>
            <CreateCategory
                menu={props.menu}
                categories={props.categories}
                getCategories={props.getCategories}
                languages={props.restaurant.languages}
                open={openModal}
                editing={editing}
                categoryId={categoryId}
                setSelectedCategory={props.setSelectedCategory}
                selectedCategory={props.selectedCategory}
                categoryToEdit={categoryToEdit}
                onClose={() => {
                    setOpenModal(false)
                    setCategoryToEdit([])
                }}
            />
            <div className={`${classes.categoryContainer} ${classes.addCategory}`}
                onClick={() => {
                    setOpenModal(true)
                    setEditing(false)
                }}
            >
                <Add className={classes.iconSize} />
                <div className={`${classes.equalFlex} ${classes.categoryName}`} > <p>Add category</p></div>
                <div className={`${classes.alignCenter}`}><ChevronRight className={classes.iconSize} /></div>
            </div>
            <SortableList distance={2} axis={AXIS.AXISY} onSortEnd={onSortEndHandler} items={props.categories} />
            <CategorySettings
                loading={save}
                saveChanges={saveChanges}
                open={openAvailabilityModal}
                onClose={() => {
                    setOpenAvailabilityModal(false)
                    setCategoryToEdit([])
                    setSave(false)
                }}
                categoryToEdit={categoryToEdit}
                title={i18nLang.categorySettings.title[lang]}
                acceptButtonText={i18nLang.categorySettings.save[lang]}
                cancelButtonText={i18nLang.categorySettings.cancel[lang]}
                startTime={startTime}
                endTime={endTime}
            />

        </div>
    )
}

let styles = theme => ({
    optionsClass: {
        minWidth: 20,
        height: '100%',
        paddingLeft: 2,
        paddingRight: 2,
        boxSizing: 'border-box'
    },
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    categoryContainer: {
        height: 60,
        width: 200,
        border: '1px solid rgba(0,0,0,0.2)',
        borderRadius: 5,
        marginTop: 15,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        boxSizing: 'border-box',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    addCategory: {
        color: '#ffffff', 
        backgroundColor: '#34aa44'
    },
    equalFlex: {
        flex: 1
    },
    alignCenter: {
        textAlign: 'center'
    },
    iconSize: {
        fontSize: 24,
        paddingLeft: 8,
        paddingRight: 8
    },
    iconSizeSmall: {
        fontSize: 24,
        paddingLeft: 2,
        paddingRight: 2
    },
    categoryName: {
        paddingLeft: 8
    },
    buttonText: {
        color: '#9ca4ab',
        paddingTop: 3
    },
    addIcon: {
        color: '#9ca4ab'
    },
    buttonWidth: {
        width: 130
    },
    sortableList: {
        display: 'flex', 
        flexWrap: 'wrap'
    }
})

export default withStyles(styles)(CategoryList)