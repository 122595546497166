import React, { useEffect, useRef, useState } from 'react'

import { withRouter, useLocation } from 'react-router-dom'
import { withStyles, Divider , Button, Typography, Popper, Grow, Paper, ClickAwayListener, MenuList, List, MenuItem, FormControlLabel, Checkbox, IconButton } from '@material-ui/core'
import { VerifiedUser, MenuBook, Message, Info, Wifi, LocalDining, KeyboardArrowDown, Language, ArrowRightAlt, RadioButtonUnchecked, RadioButtonChecked, ArrowBackIos, VpnKey, Close } from '@material-ui/icons'
import * as RESTAURANT_REQUEST from '../../utils/requests/restaurants'
import * as MOBILE_REQUESTS from '../../utils/requests/mobile'
import { EVENTS, COLORTHEME } from '../../utils/constants'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import MenuType from './Menu/MenuList'
import About from './About/About'
import Contact from './Contact/Contact'
import i18n from '../../utils/i18n.json'
import { analytics } from '../../utils/helpers'


let Restaurant = props => {
    
    let { classes } = props
    let lang = localStorage.getItem('languageMobile')

    let [restaurant, setRestaurant] = useState(null)
    let [sentAnalytics, setSentAnalytics] = useState(false)
    let [renderPage, setRenderPage] = useState(false)
    let [selectMenuType, setSelectMenuType] = useState(false)
    let [renderAbout, setRenderAbout] = useState(false)
    let [renderContact, setRenderContact] = useState(false)
    const [categoryClicked, setCategoryClicked] = useState(null)
    const [subcategories, setSubcategories] = useState(null)
    const [displayReadAndWifi, setDisplayReadAndWifi] = useState(true)
    const [displayPhoto, setDisplayPhoto] = useState(true)
    const [isItMenu, setIsItMenu] = useState(false)
    let actualTheme = localStorage.getItem(COLORTHEME)
    const [colorTheme, setColorTheme] = useState(actualTheme)
    const [circleState, setCircleState] = useState({
        colorTheme: actualTheme === 'lightMode' || actualTheme === null ? false : true
    })
    const [open, setOpen] = useState(false)
    const anchorRef = useRef(null)
    const [openGuvernModal, setOpenGuvernModal] = useState(false)

    const handleOpenGuvernModal = () => {
        setOpenGuvernModal(true);
      };
    
      const handleCloseGuvernModal = () => {
        setOpenGuvernModal(false);
      };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setOpen(false)
    }

    const handleChange = (event) => {
        if(event.target.checked){
            setColorTheme('darkMode')
            localStorage.setItem(COLORTHEME, 'darkMode')
        }
        else{
            setColorTheme('lightMode')
            localStorage.setItem(COLORTHEME, 'lightMode')
        }
        setCircleState({ ...circleState, [event.target.name]: event.target.checked })
      }

    let actions = [
        { label: i18n.contactUs[lang], icon: <Message className={classes.icon} />, name: 'contact' },
        { label: i18n.aboutUs[lang], icon: <Info className={classes.icon} />, name: 'about' }
    ]

    let useQuery = () => {
        return new URLSearchParams(useLocation().search)
    }

    let query = useQuery().get('ref')
    
    useEffect(() => {
        if (props.location.state && props.location.state.renderMenus) {
            setSelectMenuType(true)
            setDisplayPhoto(false)
            props.history.push({
                pathname: window.location.pathname,
                state: {
                    renderMenus: false,
                    categoryId: props.location.state.categoryId,
                }
            })
        }
    }, [props.location.state])
     
    useEffect(() => {
        getRestaurant()
        checkFirstVisit()
    }, [props.match.params.RESTAURANT_ID])

    useEffect(() => {
        if (query && !sentAnalytics) {
            analytics.logEvent(EVENTS.QR_CODE, { restaurant_id: props.match.params.RESTAURANT_ID })
            setSentAnalytics(true)
        }
    })

    let setLanguage = language => {
        localStorage.setItem('languageMobile', language)
        window.location.reload()
    }

    let getRestaurant = () => {
        return RESTAURANT_REQUEST.getByIdMobile(props.match.params.RESTAURANT_ID).then(res => {
            setRestaurant(res)
            localStorage.setItem('defaultLanguage', Object.keys(res.languages[0])[0])
            if (!localStorage.getItem('languageMobile')) {
                const firstLangauge = Object.keys(res.languages[0])[0]
                localStorage.setItem('languageMobile', firstLangauge)
            }
            else {
                const localStorageLanguage = localStorage.getItem('languageMobile')
                const currentRestaurantLanguages = res.languages.map(language => Object.keys(language)[0])
                if (!currentRestaurantLanguages.includes(localStorageLanguage)) {
                    const firstLangauge = Object.keys(res.languages[0])[0]
                    localStorage.setItem('languageMobile', firstLangauge)
                }
            }
            if (!localStorage.getItem(COLORTHEME)) {
                localStorage.setItem(COLORTHEME, 'lightMode')
            }
            setRenderPage(true)
        })
    }

    let renderLanguages = () => {
        return (
            restaurant.languages.map((language, index) => {
                const languageName = Object.keys(language)[0]
                return (
                <MenuItem
                        key={index}
                        onClick={() => { setLanguage(languageName); analytics.logEvent(EVENTS.LANG, { restaurant_id: restaurant.id, language: languageName }) }}
                        // className={classes.circle}
                        style={{
                            backgroundColor: localStorage.getItem('languageMobile') === languageName ? 'rgba(0,0,0,0.5)' : '',
                            color: localStorage.getItem('languageMobile') === languageName ? '#ffffff' : ''
                        }}>
                        <span>{languageName}</span>
                </MenuItem>
                )
            })
        )
    }

    let clickAction = action => {
        switch (action) {
            case 'about': {
                setRenderAbout(true)
                analytics.logEvent(EVENTS.ABOUT, { restaurant_id: restaurant.id })
                break
            }
            case 'contact': {
                setRenderContact(true)
                analytics.logEvent(EVENTS.CONTACT, { restaurant_id: restaurant.id })
                break
            }
            default: {
                setRenderContact(false)
                setRenderAbout(false)
            }
        }
    }

    let renderActions = () => {
        return (
            actions.map((action, index) => {
                return (
                    <div onClick={() => clickAction(action.name)} key={index} className={classes.actionContainer}>
                        <div className={classes.circle} style={{ backgroundColor: 'rgba(0,0,0,0.5)', marginLeft: 0, marginBottom: 7 }}>
                            {action.icon}
                        </div>
                        <span className={classes.actionText}>{action.label}</span>
                    </div>
                )
            })
        )
    }

    let checkFirstVisit = async () => {
        const visited = await JSON.parse(localStorage.getItem('Bits&BitesFirstVisit')) ? JSON.parse(localStorage.getItem('Bits&BitesFirstVisit')) : []

        if (!visited.includes(props.match.params.RESTAURANT_ID)) {
            analytics.logEvent(EVENTS.FIRST_VISIT, { restaurant_id: props.match.params.RESTAURANT_ID })
            visited.push(props.match.params.RESTAURANT_ID)
            localStorage.setItem('Bits&BitesFirstVisit', JSON.stringify(visited))
        }

    }
    let onMenuClick = () => {
        analytics.logEvent(EVENTS.SESSION_START, { restaurant_id: restaurant.id })
        setSelectMenuType(true)
        setSelectMenuType(true)
        setIsItMenu(true)
        setDisplayPhoto(false)
        props.history.push({
            pathname: window.location.pathname,
            state: {
                renderMenus: false,
                showBackButton: true
            }
            
        })
    }

    const handleReturn = () => {
        if(isItMenu) {
            setSelectMenuType(false) 
            setDisplayPhoto(true)
        }
        else {
            setIsItMenu(true)
            setSelectMenuType(true)
            setDisplayReadAndWifi(true)
        }
    }
    
    let onCategoryClick = category => {
        if(category.subcategories)
            analytics.logEvent(EVENTS.CATEGORY, { restaurant_id: props.match.params.RESTAURANT_ID, category_name: category.name[localStorage.getItem('defaultLanguage')] })
        setIsItMenu(false)
        setDisplayReadAndWifi(false)
        setCategoryClicked(category.id)
        setSubcategories(category.subcategories)
    }

    const stylesDesign = colorTheme === 'darkMode' ? 
        {
            primary: { backgroundColor : props.design.dark.primary },
            secondary: { backgroundColor: props.design.dark.secondary },
            primaryText: { color: props.design.dark.primaryText },
            secondaryText: { color: props.design.dark.secondaryText }
        } 
        : 
        {
            primary: { backgroundColor : props.design.light.primary },
            secondary: { backgroundColor: props.design.light.secondary },
            primaryText: { color: props.design.light.primaryText },
            secondaryText: { color: props.design.light.secondaryText }
        }

    if (renderPage && props.design) {
        return (
            <>
                {renderContact ? <Contact colorTheme={colorTheme} design={props.design} onCancel={() => setRenderContact(false)} /> : null}
                {renderAbout ? <About design={props.design} renderLanguages={renderLanguages()} colorTheme={colorTheme} restaurant={restaurant} onCancel={() => setRenderAbout(false)} /> : null}
                <Dialog
                    open={openGuvernModal}
                    onClose={handleCloseGuvernModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogActions>
                        <IconButton onClick={handleCloseGuvernModal}>
                            <Close />
                        </IconButton>
                    </DialogActions>
                    <DialogContent>
                        <img style={{width: '100%', height: '100%'}} src="/assets/guvern.jpeg" alt="guvern" />
                    </DialogContent>
                    <DialogActions>
                    </DialogActions>
                </Dialog>
                {!renderContact && !renderAbout && 
                <div
                    style={Object.assign(stylesDesign.primary,stylesDesign.primaryText)}
                    className={`${classes.container} 
                    ${colorTheme === 'darkMode' ? classes.darkMode : classes.lightMode}`}>
                    <div 
                        style={stylesDesign.secondary}
                        className={classes.header}>
                        
                        <div className={classes.restaurantName}>
                        {selectMenuType &&
                            <>
                                <div className={classes.minHeightBackButton}>
                                    <Button 
                                        className={props.theme === 'darkMode' ? classes.darkModeBackButton : classes.lightModeBackButton} 
                                        onClick={() => handleReturn()}    
                                    >
                                        <ArrowBackIos/> 
                                    </Button> 
                                </div>
                                <Divider orientation='vertical' flexItem/>
                            </>}
                            <img 
                                className={`${classes.restaurantLogo} ${props.design.additionalSettings.hideRestaurantName && classes.marginLeft}`} alt='' 
                                src={colorTheme === 'darkMode' ? restaurant.logoUrlDark : restaurant.logoUrlLight} />
                            <div className={classes.company}>
                            {!props.design.additionalSettings.hideRestaurantName &&
                                <div className={classes.company}>
                                    <h4 style={stylesDesign.secondaryText}>{restaurant.name}</h4>
                                </div>}
                            </div>
                        </div>
                        <List component='nav' className={classes.list}>
                            <Divider orientation='vertical' flexItem/>
                            <div className={classes.listItems}>
                                <VerifiedUser onClick={handleOpenGuvernModal} fontSize='large'/>
                            </div>
                            <Divider orientation='vertical' flexItem/>
                            <FormControlLabel
                                className={classes.listItems}
                                control={
                                    <Checkbox
                                        checked={circleState.colorTheme}
                                        icon={<RadioButtonUnchecked />}
                                        checkedIcon={<RadioButtonChecked />}
                                        onChange={handleChange}
                                        name={COLORTHEME}
                                    />
                                    }
                            />
                            <Divider orientation='vertical' flexItem />
                            <Button
                                ref={anchorRef}
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup='true'
                                onClick={handleToggle}
                                >
                                    <Language /> <KeyboardArrowDown />
                            </Button>
                            <Popper style={{zIndex: '1'}} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                    {...TransitionProps}
                                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                    >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList autoFocusItem={open} id='menu-list-grow'>
                                            {renderLanguages()}
                                        </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                    </Grow>
                                )}
                            </Popper>                        
                            <Divider orientation='vertical' flexItem/>
                            <Button onClick={() => clickAction('contact')}>
                                <Message />
                            </Button>
                        </List>
                    </div>
                    {displayPhoto ?
                    <div 
                        style={{ backgroundImage: `url(${restaurant.photoUrl})`,backgroundSize:'cover', backgroundPosition:'bottom'}}
                        className={`${classes.headerContainer} ${colorTheme === 'darkMode' && classes.darkMode}`}
                        >
                    </div> : <div 
                        className={`${classes.displayNone} ${colorTheme === 'darkMode' && classes.darkMode}`}
                        >
                    </div>}
                    <div>
                    <div 
                        // style={{background: !selectMenuType ? `linear-gradient(0deg, ${stylesDesign.primary.backgroundColor}, transparent)` : stylesDesign.primary.backgroundColor}}
                        className={classes.contentContainer}>
                        {selectMenuType ? 
                            <MenuType 
                                stylesDesign={stylesDesign}
                                isItMenu={isItMenu}
                                setIsItMenu={setIsItMenu}
                                theme={colorTheme} 
                                restaurant={restaurant} 
                                selectMenuType={selectMenuType} 
                                setSelectMenuType={setSelectMenuType}
                                onCategoryClick={onCategoryClick}
                                categoryClicked={categoryClicked}
                                setCategoryClicked={setCategoryClicked}
                                subcategories={subcategories}
                                setSubcategories={setSubcategories}
                                handleReturn={handleReturn}
                                setDisplayPhoto={setDisplayPhoto}
                                setDisplayReadAndWifi={setDisplayReadAndWifi}
                                /> 
                            :
                            <div 
                                onClick={() => onMenuClick()} 
                                style={stylesDesign.secondary}
                                className={`${classes.menuButtonContainer} ${colorTheme === 'darkMode' ? classes.menuButtonContainerDarkMode : classes.menuButtonContainerLightMode}`}>
                                <MenuBook
                                    style={stylesDesign.secondaryText}
                                    className={classes.menuIcon}/><span className={classes.menuButtonText}>{i18n.menuBtn[lang]}</span>
                            </div>
                        }   
                    </div>
                    <div style={stylesDesign.primary} className={`${classes.welcome}`}>
                        {!selectMenuType && 
                            <div className={classes.description}>
                                <h3 className={classes.descriptionEllipsis}>
                                    {restaurant.description ? restaurant.description.replace(/(<([^>]+)>)/gi, "").replace(/&nbsp;/gi,' ') : ''}
                                </h3>
                            </div>}
                        {displayReadAndWifi && 
                        <>
                            <div style={stylesDesign.secondaryText} className={classes.readStory}>
                                <Typography onClick={() => clickAction('about')} className={classes.story} component='h1' >
                                    {i18n.story[lang]} &nbsp; <ArrowRightAlt className={classes.storyArrow} fontSize='small'/>
                                </Typography>
                            </div>
                           
                            {/* <Button className={colorTheme === 'darkMode' ? classes.restaurantWifiButtonDarkMode : classes.restaurantWifiButtonLightMode}>
                                <Wifi style={stylesDesign.secondaryText} className={classes.wifiIcon}/> 
                                <span className={classes.restaurantNameWifi}> {restaurant.wifiName} </span>
                                <VpnKey style={stylesDesign.secondaryText} className={classes.wifiIcon}/> 
                                <span className={classes.restaurantNameWifi}> {restaurant.wifiPassword} </span>
                            </Button> */}
                            {/* <Button className={colorTheme === 'darkMode' ? classes.restaurantWifiButtonDarkMode : classes.restaurantWifiButtonLightMode}>
                                <VpnKey style={stylesDesign.secondaryText} className={classes.wifiIcon}/> 
                                <span className={classes.restaurantNameWifi}> {restaurant.wifiPassword} </span>
                            </Button> */}
                            <div className={colorTheme === 'darkMode' ? classes.restaurantWifiButtonDarkMode : classes.restaurantWifiButtonLightMode}>
                                <div className={classes.wifiInfo}><Wifi style={stylesDesign.secondaryText} className={classes.wifiIcon} /><span>{restaurant.wifiName}</span></div>
                                <div className={classes.wifiInfo}><VpnKey style={stylesDesign.secondaryText} className={`${classes.wifiIcon} ${classes.vpnKey}`} /><span>{restaurant.wifiPassword}</span></div>
                            </div>
                           
                        </>}
                    </div>
                    </div>
                </div>}
            </>
        )
    }
    else return null
}


let styles = theme => ({
    list: {
        display: 'flex',
        height: '4em',
        '& hr': {
            height: '4em'
        },
        '& .MuiFormControlLabel-root': {
            margin: 0,
        },
        '& .MuiButton-text':{
            padding: 0,
            width: '4em'
        },
        '@media (max-width: 414px)': {
            '& .MuiButton-text': {
                minWidth: '0',
                width: '3em'
            },
        },
    },
    listItems: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        width: '4em',
        '@media (max-width: 414px)': {
            width: '3em'
        },
    },
    container: {
        overflow: 'auto',
        width: '100%',
        height: '100%', 
    },
    headerContainer: {
        width: '100%',
        height: '40%',
        position: 'relative'
    },
    noBackgroundPhoto: {
        width: '100%',
        height: '20%',
        position: 'relative'
    },
    displayNone: {
        display: 'none'
    },
    darkMode: {
        color: 'white',
        '& .MuiSvgIcon-root':{
            color: 'white'
        },
        '& .MuiDivider-root':{
            backgroundColor: theme.palette.primaryDarkModeColor
        }
    },
    lightMode: {
        color: 'black',
        '& .MuiSvgIcon-root':{
            color: 'black'
        }
    },
    contentContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight:'120px',
        paddingTop:'1em'
    },
    menuButtonContainer: {
        height: '120px',
        width: '15em',
        maxWidth: '500px',
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        top: '-50px'
    },
    menuButtonContainerDarkMode: {
        boxShadow: 'rgb(241 225 218 / 12%) 0px 2px 4px 0px, rgb(241 225 218 / 12%) 0px 2px 16px 0px'
    },
    menuButtonContainerLightMode: {
        boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
    },
    menuButtonText: {
        fontSize: 20,
        fontWeight: 500,
        paddingLeft:'1.5em'
    },
    languageContainer: {
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        top: '10px',
        right: '10px'
    },
    languages: {
        float: 'right',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '12px 12px 12px 0px',
        opacity: 0.7,
        fontSize: 10,
        marginLeft: 'auto'
    },
    circle: {
        width: 30,
        height: 30,
        borderRadius: '50%',
        marginLeft: 12,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.35)',
    },
    backButtonContainer: {
        padding: 12,
        position: 'absolute',
        left: '10px',
        top: '10px'
    },
    backButton: {
        boxShadow: '1px 1px 3px 0px rgba(0,0,0,0.35)',
        height: 25,
        opacity: 0.9,
        padding: '6px 16px 6px 10px'
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: 12
    },
    actionText: {
        fontSize: 10,
        color: 'rgba(0,0,0,0.8)',
        fontWeight: 500
    },
    icon: {
        fontSize: 16,
        color: '#ffffff !important'
    },
    wifiContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 40,
        borderRadius: 10
    },
    wifiIcon: {
        padding:'0.3em 0.5em 0.3em 0.5em'
    },
    vpnKey: {
        paddingLeft: '0.2em'
    },
    wifiInfo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    header: {
        display:'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    restaurantName: {
        display:'flex',
        alignItems:'center',
        overflow: 'hidden'
    },
    restaurantLogo: {
        marginLeft:'0.5em',
        maxHeight: '4em',
        maxWidth: '8em',
        '@media (max-width: 414px)': {
            marginLeft:'0em',
        },
    },
    marginLeft: {
        '@media (max-width: 414px)': {
            marginLeft:'1em',
        },
    },
    company: {
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        '& h1': {
            margin: '0',
            '@media (max-width: 414px)': {
                fontSize:'10px'
            },
        },
        '& h4': {
            fontSize: '1.5em',
            margin:'0', 
            '@media (max-width: 414px)': {
                fontSize:'15px'
            },
        }
    },
    welcome: {
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        textAlign:'center',
        width:'100%',
        margin:'auto',
        backgroundColor:'inherit',
        alignItems: 'center'
    },
    story: {
        fontWeight:'400',
        minHeight:'2em',
        color:'inherit',
        alignItems:'center',
        textAlign:'center',
        display:'flex',
        justifyContent:'center',
        cursor: 'pointer',
        '& > .MuiSvgIcon-root':{
            color: 'inherit !important'
        },
        width: 'fit-content'
    },
    restaurantWifiButton: {
        backgroundColor:'#e4e4e4',
        width:'fit-content', 
        borderRadius:'0.5em',
        marginTop:'3em'
    },
    restaurantWifiButtonDarkMode: {
        backgroundColor:'#1a1a1a',
        width:'10em', 
        borderRadius:'0.5em',
        marginTop:'3em',
        marginBottom:'3em',
        color: 'white',
        '&:hover': {
            backgroundColor:'#1a1a1a'
        }
    },
    restaurantWifiButtonLightMode: {
        backgroundColor:'#e4e4e4',
        width:'10em', 
        borderRadius:'0.5em',
        marginBottom:'3em',
        marginTop:'3em',
        color: 'black',
        '&:hover': {
            backgroundColor:'#e4e4e4'
        }
    },
    backButtonArrow: {
        padding: '1em'
    },
    topSearchButton: {
        height: '4em',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    restaurantNameWifi: {
        paddingRight: '1.1em'
    },
    storyArrow: {
        color: '#f97037 !important', 
    },
    descriptionEllipsis: {
        overflow: 'hidden',
        position: 'relative',
        lineHeight: '1.2em',
        maxHeight: 'calc(1.2em * 3)',
        margin: 0,
        '&::after': {
            content: '"..."',
            position: 'absolute',
            right: '0',
            bottom: '0',
        },
    },
    description: {
        width:'80%',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center'
    },
    menuIcon: {
        fontSize: '4em', 
    },
    readStory: {
        marginTop: '20px'
    },
    restaurantPhoto: {
        objectFit: 'fill',
        width: '100%',
        height: '100%'
    },
    darkModeBackButton: {
        height:'100%',
        borderRadius: '0',
        color: 'white'
    },
    lightModeBackButton: {
        height:'100%',
        borderRadius: '0',
        color: 'black',
        '&:hover': {
            backgroundColor:'transparent'
        }
    },
    minHeightBackButton: {
        display:'flex',
        minHeight: '4em',
        width: '4em',
        flexDirection:'column',
        justifyContent:'center',
        '@media (max-width: 414px)': {
            width: '2em',
            '& .MuiButton-text':{
                minWidth: '3em'
            },
        },
    }
})

export default withRouter(withStyles(styles)(Restaurant))