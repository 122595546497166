import React from 'react'
import { withStyles } from '@material-ui/core'

import Modal from '../../../common/Modal'
import { getPrice } from '../../../utils/helpers'
import { PRODUCT_DETAILS_ACTIONS } from '../../../utils/constants'


let ProductDetails = props => {

    let { classes } = props
    const fields = [
        { name: 'price', label: 'Price' },
        { name: 'calories', label: 'Calories' },
        { name: 'category', label: 'Category' },
        { name: 'subcategory', label: 'Subcategory' },
        { name: 'ingredients', label: 'Ingredients' },
        { name: 'quantity', label: 'Quantity' },
        { name: 'quantityUnit', label: 'Quantity Unit' },
        { name: 'weight', label: 'Weight' },
        { name: 'weightUnit', label: 'Weight Unit' },
        { name: 'waitingTime', label: 'Waiting Time' },
        { name: 'isSpicy', label: 'Is spicy' },
        { name: 'veganTag', label: 'Vegan Tag' },
        { name: 'alergens', label: 'Alergens Tags' },
        { name: 'fieldName', label: 'fieldName' },
        { name: 'fieldValue', label: 'fieldValue' }
    ]

    const renderContent = () => {
        const lang = localStorage.getItem('currentLanguage')
        return props.product ? (
            <div className={classes.flexColumn}>
                <div className={classes.imageContainer}>
                    <img src={props.product.photoUrl} className={classes.imageStyle} alt='' />
                </div>
                <div>
                    <div className={classes.titleContainer}>
                        <div className={classes.equalFlex}>
                            <p className={`${classes.description}`}>{props.translateFunction(props.product.description)}</p>
                        </div>
                    </div>
                    <div className={classes.flexColumn}>
                        {fields.map(field => {
                            let name = props.product[field.name]
                            let fieldName = props.product['fieldName'] ? props.product['fieldName'][lang] : null
                            let fieldValue = props.product['fieldValue'] ? props.product['fieldValue'][lang] : null
                            if (typeof props.product[field.name] === 'object') name = props.translateFunction(props.product[field.name])
                            if (typeof props.product[field.name] === 'boolean') name = props.product[field.name] ? 'True' : 'False'
                            if (field.name === 'price') name = getPrice(props.product[field.name], props.currencies, props.languages)
                            if (field.label !== 'fieldValue')
                                return (
                                    <>
                                        {field.label !== 'fieldName' ? (
                                            <div className={`${classes.flexRow} ${classes.detailsPadding}`}>
                                                <span className={classes.detailsText}>{field.label}:</span>&nbsp; <span>{name || '-'}</span>
                                            </div>
                                        ) : (
                                            <>
                                                {fieldName && fieldValue &&
                                                    <div className={`${classes.flexRow} ${classes.detailsPadding}`}>
                                                        <span className={classes.detailsText}>{fieldName}{fieldName ? ':' : null} </span>&nbsp;<span>{fieldValue ? fieldValue : null}</span>
                                                    </div>}
                                            </>
                                        )}
                                    </>

                                )
                            else return
                        })}
                    </div>
                </div>
            </div>
        ) : null
    }

    const onClose = () => {
        props.onClose()
    }

    return (
        <Modal
            onCancel={onClose}
            onClose={onClose}
            onDelete={props.onDelete}
            product={props.product}
            onAccept={props.onClone ? props.onClone : undefined}
            title={props.product ? props.translateFunction(props.product.name) : ''}
            cancelButtonText={PRODUCT_DETAILS_ACTIONS.CLOSE}
            delButtonText={PRODUCT_DETAILS_ACTIONS.DELETE}
            acceptButtonText={props.onClone ? 'SET GLOBAL' : undefined}
            maxWidth={'sm'}
            open={props.open}>
            {renderContent()}
        </Modal>
    )
}

let styles = theme => ({
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    imageContainer: {
        height: 200,
        width: '100%'
    },
    imageStyle: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: 5
    },
    equalFlex: {
        flex: 1
    },
    priceContainer: {
        marginLeft: 'auto'
    },
    titleContainer: {
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        padding: 8,
        marginBottom: 8,
        display: 'flex',
        flexDirection: 'row'
    },
    detailsPadding: {
        padding: 8
    },
    detailsText: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    description: {
        fontSize: 15,
        color: 'rgba(0, 0, 0, 0.54)'
    }
})

export default withStyles(styles)(ProductDetails)