import React, { useState } from 'react'
import { Button, Menu, MenuItem } from '@material-ui/core'


let TooltipMenu = props => {

    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = value => {
        setAnchorEl(null)
        props.onClick(value)
    }

    return (<><Button className={props.buttonClass} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        {props.frontIcon ? props.frontIcon : ''} {props.buttonText}
    </Button>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            {props.options.map(option =>
                <MenuItem key={option.value} onClick={() => handleClose(option.value)}>{option.label}</MenuItem>
            )}
        </Menu></>)
}

export default TooltipMenu