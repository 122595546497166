import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import { Restaurant as RestaurantIcon, Language as LocalisationIcon } from '@material-ui/icons'
// import Dashboard from './Dashboard/Dasboard'
import MenuDetails from './Menus/MenuDetails'
import Restaurants from './Restaurants/Restaurants'
import RestaurantContent from './Restaurants/RestaurantContent/RestaurantContent'
import Sidebar from '../common/Sidebar'
import Login from './Login/Login'
import GlobalProducts from '../components/GlobalProducts/GlobalProducts'
import RestaurantMobile from '../components/Mobile/Restaurant'
import RestaurantMobileMenu from '../components/Mobile/MenuType/MenuContent'
import RestaurantProduct from '../components/Mobile/ProductDetails/ProductDetails'

import * as USER_REQUESTS from '../utils/requests/user'
import * as MOBILE_REQUESTS from '../utils/requests/mobile'
let Root = props => {

    const [isAuth, setAuth] = useState(false)
    const [authDone, setAuthDone] = useState(false)
    const [design, setDesign] = useState(null)
    useEffect(() => {
        if (window.location.href.search('mobile') === -1) {
            USER_REQUESTS.isAuth()
                .then((response) => {
                    if (response.accessToken && response.accessToken.length) {
                        localStorage.setItem('token', response.accessToken)
                    }
                    setAuth(true)
                    setAuthDone(true)
                })
                .catch(() => {
                    setAuth(false)
                    setAuthDone(true)
                })
            setInterval(() => {
                USER_REQUESTS.isAuth()
                    .then((response) => {
                        if (response.accessToken && response.accessToken.length) {
                            localStorage.setItem('token', response.accessToken)
                        }
                    })
                    .catch(() => { })
                // check auth once every 10 minutes
            }, 10 * 60 * 1000)
        } else {
            setAuth(false)
            setAuthDone(true)
            let pathnameParams = window.location.pathname.split('/')
            const restaurantId = pathnameParams[2]
            MOBILE_REQUESTS.getRestaurantDesign(restaurantId).then(res => setDesign(res))
        }
    }, [])

    return !authDone ? null : isAuth ? (

        <div className={props.classes.appHolder}>
            <Router>
                <div className={props.classes.sidebar}>
                    <Sidebar
                        signOut={() => setAuth(false)}
                        roles={[]}
                        listItems={[
                            {
                                role: [],
                                label: 'Restaurants',
                                to: '/restaurants',
                                icon: <RestaurantIcon />
                            },
                            {
                                role: [],
                                label: 'Global Products',
                                to: '/globalProducts',
                                icon: <LocalisationIcon />
                            }
                        ]}
                    />
                </div>
                <div className={props.classes.content}>

                    <Switch>
                        <Route path="/" exact render={() => <Restaurants />} />
                        <Route path="/globalProducts" exact render={() => <GlobalProducts />} />
                        <Route path="/restaurants" exact render={() => <Restaurants />} />
                        <Route path="/restaurants/:RESTAURANT_ID" exact component={RestaurantContent} />
                        <Route path="/restaurants/:RESTAURANT_ID/menus/:MENU_ID/edit" exact component={MenuDetails} />
                        <Redirect from="*" to='/' />
                    </Switch>

                </div>
            </Router>
        </div>
    ) :
        (<>
            {window.location.href.search('mobile') > -1 ?
                <>
                    <Router>
                        <Route path="/mobile/:RESTAURANT_ID" exact render={() => design && <RestaurantMobile design={design} />} />
                        <Route path="/mobile/:RESTAURANT_ID/menu/:MENU_ID/category/:CATEGORY_ID" exact render={() => design && <RestaurantMobileMenu design={design} />} />
                        <Route path="/mobile/:RESTAURANT_ID/menu/:MENU_ID/product/:PRODUCT_ID" exact render={() => design && <RestaurantProduct design={design} />} />
                    </Router>
                </> :
                <div className={props.classes.appHolder}><Login isAuth={() => setAuth(true)} /></div>}
        </>
        )
}

let styles = theme => ({
    appHolder: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'row'
    },
    content: {
        width: 'calc(100% - 250px)',
        marginLeft: '250px'
    },
    sidebar: {
        width: 250,
        height: '100%',
        position: 'fixed'
    }
})

export default withStyles(styles)(Root)
