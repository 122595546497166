import axios from '../axios'
import { getLanguage, errorHandling } from '../helpers'

export const get = (restaurantId, menuId, category) => axios.get(`restaurants/${restaurantId}/products/menu/${menuId}?categoryId=${category}&isHidden=${true}`)
    .then(res => res.data)
    .catch(errorHandling)

export const getByRestaurant = (restaurantId, search = '') => axios.get(`restaurants/${restaurantId}/products/search?term=${search}&language=${getLanguage()}`)
    .then(res => res.data)
    .catch(errorHandling)

export const getById = (restaurantId, productId) => axios.get(`restaurants/${restaurantId}/products/${productId}`)
    .then(res => res.data)
    .catch(errorHandling)

export const setGlobal = (restaurantId, productId) => axios.post(`restaurants/${restaurantId}/products/${productId}/global`)
    .then(res => res.data)
    .catch(errorHandling)

export const create = (restaurantId, menuId, product) => axios.post(`restaurants/${restaurantId}/products/menu/${menuId}`, product)
    .then(res => res.data)
    .catch(errorHandling)

export const edit = (restaurantId, productId, product) => axios.put(`/restaurants/${restaurantId}/products/${productId}`, product)
    .then(res => res.data)
    .catch(errorHandling)

export const reorder = (restaurantId, product) => axios.put(`/restaurants/${restaurantId}/products/reorder`, product)
    .then(res => res.data)
    .catch(errorHandling)

export const hide = (restaurantId, productId, product) => axios.put(`/restaurants/${restaurantId}/products/${productId}/hide`, product)
    .then(res => res.data)
    .catch(errorHandling)

export const deleteById = (restaurantId, productId) => axios.delete(`/restaurants/${restaurantId}/products/${productId}`)
    .then(res => res.data)
    .catch(errorHandling)

export const getGoesWellWith = (restaurantId, menuId, category) => axios.put(`restaurants/${restaurantId}/products/menu/${menuId}/goesWellWith/${category}`)
    .then((res) => res.data)
    .catch(errorHandling)

export const updateGoesWell = (restaurantId, product) => axios.put(`/restaurants/${restaurantId}/products/updateGoesWell`,product)
    .then((res) => res.data)
    .catch(errorHandling)