import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip'
import { i18n } from '../utils/helpers'
import i18Lang from '../utils/i18n.json'
const useStyles = makeStyles({
    root: {
        width: 275
    },
    subcategoryContainer: {
        border: '1px solid rgba(0,0,0,0.4)',
        borderRadius: 5,
        padding: 7,
        margin: '10px 0px',
        width: 75
    },
    subcategoryText: {
        fontWeight: 'bold'
    },
    textTitle: {
        fontSize: 20,
        fontFamily: 'serif'
    },
    media: {
        height: 100,
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    equalFlex: {
        flex: 1
    }
});

export default function MediaCard(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const lang = localStorage.getItem('currentLanguage')

    const hideProduct = () => {
        props.onHide(props.item)
        setLoading(true)
    }

    return (
        <Card className={classes.root}>
            <CardActionArea onClick={() => props.onClick(props.item)}>
                <CardMedia
                    className={classes.media}
                    image={props.item.photoThumbUrl}
                />
                <CardContent style={{ padding: 8 }}>
                    <Typography variant='h6' component='h2'>
                        {i18n(props.item.name).slice(0, 25)} {i18n(props.item.name).length > 25 ? '...' : ''}
                    </Typography>
                    <Typography style={{ minHeight: 40 }} variant='body2' color='textSecondary' component='p'>
                        {i18n(props.item.description).slice(0, 60)}...
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Tooltip title={!loading ? props.item.isHidden ? i18Lang.hideProduct.unHide[lang] : i18Lang.hideProduct.hide[lang] : ''}>
                    <Button disabled={loading} onClick={() => hideProduct()} size='small' color='primary'>
                        {props.item.isHidden ? <VisibilityOff/> : <Visibility/>}
                    </Button>
                </Tooltip>
                <Button style={{ marginLeft: 'auto' }} onClick={() => props.onEdit(props.item.id)} size='small' color='primary'>
                    Edit
            </Button>
            </CardActions>
        </Card >
    );
}