import React, { useState } from 'react';
import {SketchPicker} from 'react-color'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
let ColorPicker = (props) => {

    const { classes } = props
    const [displayColorPicker, setdisplayColorPicker] = useState(false)
    const [color, setColor] = useState(props.color)

    let handleClick = () => {
        setdisplayColorPicker(!displayColorPicker)
    };

    let handleClose = () => {
        setdisplayColorPicker(false)
    };

    let handleChange = (color) => {
        setColor(color)
        props.onChange(color, props.name, props.theme)
    };

    return (
        <div>
            <div className={classes.swatch} onClick={() => handleClick()}>
              <div style={{background:color}} className={classes.color}/>
            </div>
            {displayColorPicker ? 
                <div className={ classes.popover }>
                  <div className={classes.cover} onClick={() => handleClose()}/>
                  <SketchPicker color={color} 
                    onChange={(event) => handleChange(event.hex, props.name)}/>
                  </div> 
                : null 
            }
        </div>
    )
}

let styles = (theme) => ({
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          //background: `${color}`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      
})

export default withRouter(withStyles(styles)(ColorPicker))