import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles, Button } from '@material-ui/core'
import { Add, Delete, Edit } from '@material-ui/icons'

import MenuTable from '../../common/Table'
import SimpleFormGenerator from '../../common/SimpleFormGenerator'
import LANGUAGE from '../../utils/language'

import * as MENUS_REQUESTS from '../../utils/requests/menus'
import * as NOTIFICATIONS from '../../utils/notification'
import FullPageLoading from '../../common/FullPageLoading'
import { i18n } from '../../utils/helpers'
import i18nLang from '../../utils/i18n.json'

let initialFields = [
    { value: '', type: 'text', name: 'name', label: 'Menu Name', breakline: "true" },
    // { type: 'multiselector', name: 'categories', utils: 'Available Categories', value: [...CONSTANTS.CATEGORIES].map(category => ({ ...category })), breakline: true },
    // { type: 'multiselector', name: 'subCategories', utils: 'Available Sub-categories', value: [...CONSTANTS.SUB_CATEGORIES].map(subCategory => ({ ...subCategory })), breakline: true }
]

let Menus = props => {

    let { classes } = props
    const [menus, setMenus] = useState([])
    const [renderPage, setRenderPage] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [editing, setEditing] = useState(false)
    const [menuToEdit, setMenuToEdit] = useState(null)
    const [menusFields, setMenusFields] = useState(initialFields)
    const [hideMenuId, setMenuToHideId] = useState([])
    const [loading, setLoading] = useState(false)
    let lang = localStorage.getItem('currentLanguage')
    let toggleEditModal = menuId => {
        setEditing(true)
        setMenuToEdit(menuId)
        setOpenModal(true)
        MENUS_REQUESTS.getById(props.restaurantId, menuId).then(currentMenu => {
            Object.keys(currentMenu).forEach(key => {
                if (key.toLowerCase() === 'categories' || key.toLowerCase() === 'subcategories') {
                    onInputChange({ target: { name: key, value: currentMenu[key].map(item => ({ value: true, name: item, label: key.toLowerCase() === 'categories' ? LANGUAGE.category[item] || item : LANGUAGE.subcategory[item] || item })) } })
                }
                else {
                    if (typeof currentMenu[key] === 'object') {
                        Object.keys(currentMenu[key]).forEach(language => {
                            let short = language
                            let value = currentMenu[key][language]
                            onInputChange({ target: { name: `${key}-${short}`, value: value } })
                        })
                    } else onInputChange({ target: { name: key, value: currentMenu[key] } })
                }
            })
        })
    }

    let getMenus = () => MENUS_REQUESTS.getByRestaurantId(props.restaurantId)
        .then(result => {
            const mappedMenus = result.map((menu, index) => ({
                id: menu.id,
                name: i18n(menu.name),
                _isHidden: menu.isHidden
            }))
             
            setMenus(mappedMenus)
            clearForm()
            setLoading(false)
            setRenderPage(true)
        })
    

    let deleteMenu = menuId => {
        if (window.confirm("Are you sure?")) {
            MENUS_REQUESTS.deleteMenu(props.restaurantId, menuId).then(() => {
                NOTIFICATIONS.success("Menu successfully deleted")
                getMenus()
            })
                .catch(() => {
                    NOTIFICATIONS.error("Fail to delete")
                })
        }
    }
    const hideMenu =  menuId => {
        setLoading(true)
        const menuToHide = menus.filter(menu =>  menu.id === menuId)
        if(menuToHide) {
            setMenuToHideId(menuToHide[0].id)
            MENUS_REQUESTS.edit(props.restaurantId, menuId, {isHidden : !menuToHide[0]._isHidden})
            .then(() => {
                getMenus()
                NOTIFICATIONS.success(menuToHide[0].isHidden ? i18nLang.hideMenu.unhide_succes[lang] : i18nLang.hideMenu.hide_succes[lang])
            })
            .catch(() => {
                NOTIFICATIONS.error(menuToHide[0].isHidden ? i18nLang.hideMenu.unhide_error[lang] : i18nLang.hideMenu.hide_error[lang])
            })
        }
    }

    let onInputChange = event => {
        let fieldIndex = menusFields.findIndex(f => f.name === event.target.name)

        if (!(fieldIndex > -1)) return

        let newFields = [...menusFields]

        newFields[fieldIndex].value = event.target.value

        setMenusFields(newFields)

    }

    let createMenu = () => {
        //check for empty strings
        let allGood = true
        let menuFieldsCopy = [...menusFields].map(obj => ({ ...obj }))
        menuFieldsCopy.forEach(field => {
            if (!field.value && field.key !== 'goesWellWith') {
                field.error = true
                allGood = false
            }else{
                field.error = false
            }
        })

        if (!allGood) {
            setMenusFields(menuFieldsCopy)
            return NOTIFICATIONS.error("Please complete all forms")
        }

        //get language values from initialFields 
        let languagesName = {}
        let languagesGoesWellWith = {}
        menusFields.map(field => {
            let short = field.name.split('-')[1]
            if(field.name === `name-${short}`)
                languagesName[short] = field.value
            if(field.name === `goesWellWith-${short}`)
                languagesGoesWellWith[short] = field.value
        })
        let menuJson = {
            name: languagesName,
            goesWellWith: languagesGoesWellWith
        }

        if (editing) {
            MENUS_REQUESTS.edit(props.restaurantId, menuToEdit, menuJson).then((result) => {
                getMenus()
                NOTIFICATIONS.success("Menu successfully edited")
            })
                .catch(() => NOTIFICATIONS.error("Fail to edit"))
        }
        else {
            MENUS_REQUESTS.create(props.restaurantId, menuJson).then((result) => {
                getMenus()
                NOTIFICATIONS.success("Menu successfully added")
                props.history.push(`/restaurants/${props.restaurantId}/menus/${result.id}/edit`, { baseCurrencySet: props.baseCurrencySet })
            })
                .catch(() => NOTIFICATIONS.error("Fail to add"))
        }
    }

    let clearForm = () => {
        let newFields = [...initialFields.map(field => ({ ...field }))]
        setOpenModal(false)
        setMenusFields(newFields)
    }

    React.useEffect(() => {
        let finalFields = []
        if (props.restaurant && props.restaurant.languages) {
            let baseFields = [{ value: '', type: 'text', name: 'name', label: 'Menu Name', breakline: "true" },{ key: 'goesWellWith', value: '', type: 'text', name: 'goesWellWith', label: 'Goes Well With', breakline: "true" }]
            props.restaurant.languages.forEach(language => {
                let short = Object.keys(language)[0]
                let name = Object.values(language)[0]
                baseFields.map((baseField) => {
                    finalFields.push({
                        ...baseField, name: `${baseField.name}-${short}`, label: `${baseField.label} - ${name.name}`
                    })
                })
            })
            initialFields = finalFields.sort((a, b) => {
                return (a.name) > (b.name) ? -1 : 1
            })
        }
        getMenus()
    }, [])

    if (renderPage) {
        return (
            <div className={classes.container}>
                <SimpleFormGenerator
                    dialogTitle={editing ? 'EDIT MENU' : 'CREATE NEW MENU'}
                    fields={menusFields}
                    onChange={event => onInputChange(event)}
                    onClose={() => {
                        clearForm(initialFields)
                        setEditing(false)
                        setMenuToEdit(null)
                    }}
                    onCancel={() => {
                        clearForm(initialFields)
                        setEditing(false)
                        setMenuToEdit(null)
                    }}
                    onSave={() => createMenu()}
                    submitButtonText={editing ? 'EDIT' : 'CREATE'}
                    cancelButtonText={'CANCEL'}
                    open={openModal}>
                </SimpleFormGenerator>

                <MenuTable
                    title={'MENUS'}
                    headerButton={{
                        icon: <Button color='primary' variant='contained'><Add /> <span className={classes.headerButtonText}>MENU</span></Button>,
                        tooltip: 'Add new menu',
                        onClick: () => {
                            if (props.restaurant.baseCurrency) {
                                clearForm(initialFields)
                                setOpenModal(true)
                                setEditing(false)
                                setMenuToEdit(null)
                            }else{
                                NOTIFICATIONS.error("You must set the base currency")
                            }
                        }
                    }}
                    header={['ID', 'NAME']}
                    onClickRow={row => {
                        props.history.push(`/restaurants/${props.restaurantId}/menus/${row.id}/edit`, { baseCurrencySet: props.baseCurrencySet })
                    }}
                    rows={menus}
                    actions={
                        [
                            {
                                icon: <Delete />,
                                tooltip: 'Delete',
                                onClick: (event, row) => deleteMenu(row.id)
                            },
                            {
                                icon: <Edit />,
                                tooltip: 'Edit',
                                onClick: (event, row) => {
                                    toggleEditModal(row.id)
                                }
                            },
                            {
                                tooltipHide: i18nLang.hideMenu.hide[lang],
                                tooltipUnhide: i18nLang.hideMenu.unHide[lang],
                                hide: true,
                                load: loading,
                                menuId: hideMenuId,
                                onClick: (event, row) => {
                                    hideMenu(row.id)
                                }
                            },
                        ]}
                />
            </div >
        )
    } else return <FullPageLoading fullHeight={false} />
}

let styles = theme => ({
    container: {
        width: '100%',
        height: '100%'
    },
    headerButtonText: {
        marginTop: 2
    },
    displayColumn: {
        display: 'flex',
        flexDirection: 'column',
        margin: 2
    },
    fileMessage: {
        padding: 4
    }
})

export default withRouter(withStyles(styles)(Menus))