import React, { useRef, useState } from 'react'

import { withRouter } from 'react-router-dom'
import { withStyles, Button, Divider, List, Paper, MenuList, Popper, Grow, ClickAwayListener } from '@material-ui/core'
import { Facebook, Instagram, ArrowBackIos, VerifiedUser, Language, KeyboardArrowDown, MenuBook  } from '@material-ui/icons'
import ReactHtmlParser from 'react-html-parser'
import i18n from '../../../utils/i18n.json'

let About = props => {

    let { classes } = props

    let lang = localStorage.getItem('languageMobile')
    const [open, setOpen] = useState(false)
    const anchorRef = useRef(null)
 
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setOpen(false)
    }

    const stylesDesign = props.colorTheme === 'darkMode' ? 
        {
            primary: { backgroundColor : props.design.dark.primary },
            secondary: { backgroundColor: props.design.dark.secondary },
            primaryText: { color: props.design.dark.primaryText },
            secondaryText: { color: props.design.dark.secondaryText }
        } 
        : 
        {
            primary: { backgroundColor : props.design.light.primary },
            secondary: { backgroundColor: props.design.light.secondary },
            primaryText: { color: props.design.light.primaryText },
            secondaryText: { color: props.design.light.secondaryText }
        }
    
    return (
        <div 
            style={Object.assign(stylesDesign.primary,stylesDesign.primaryText)}
            className={`${classes.container} ${props.colorTheme === 'darkMode' && classes.darkModePrimaryColor}`}>
            <div style={stylesDesign.secondary} className={`${classes.headerContainer} ${props.colorTheme === 'darkMode' && classes.darkModeSecondaryColor}`}>
                <div className={classes.backButtonContainer}>
                    <Button className={classes.cancelButton} onClick={props.onCancel}><ArrowBackIos /></Button>
                    <Divider className={classes.verticalDivider} orientation='vertical' flexItem/> 
                </div>
                <div className={classes.restaurantName}>
                    {props.restaurant.logoThumbUrl ? <div className={classes.logo}>
                        <img src={props.colorTheme === 'darkMode' ? props.restaurant.logoUrlDark : props.restaurant.logoUrlLight } alt='' />
                    </div> : null}
                    {!props.design.additionalSettings.hideRestaurantName &&
                    <div className={classes.company}>
                        <h4 style={stylesDesign.secondaryText}>{props.restaurant.name}</h4>
                    </div>}
                </div> 
                <List component='nav' className={classes.root}>
                    <Divider orientation='vertical' flexItem/>
                    <div className={classes.verifiedRestaurant}>
                        <VerifiedUser fontSize='large'/>
                    </div>
                    <Divider orientation='vertical' flexItem />
                    <Button
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup='true'
                        onClick={handleToggle}
                        >
                            <Language /> <KeyboardArrowDown />
                    </Button>
                    <Popper style={{zIndex: 1}} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id='menu-list-grow'>
                                    {props.renderLanguages}
                                </MenuList>
                                </ClickAwayListener>
                            </Paper>
                            </Grow>
                        )}
                    </Popper>                        
                    <Divider orientation='vertical' flexItem/>
                </List>
            </div>
            <Divider orientation='horizontal' className={classes.horizontalDivider} flexItem/>
            <div className={classes.content}>
                {/* {props.restaurant.logoThumbUrl ? <div className={classes.logo}>
                    <img src={props.restaurant.logoThumbUrl} />
                </div> : null} */}
                <div className={classes.description}>
                    {ReactHtmlParser(props.restaurant.description)}
                </div>
                <div className={classes.menuBookContainer}>
                    <div 
                        onClick={props.onCancel}
                        style={stylesDesign.secondary}
                        className={`${classes.menuButtonContainer} ${props.colorTheme === 'darkMode' ? classes.darkModeSecondaryColor : classes.lightMode}`}>
                        <MenuBook style={stylesDesign.secondaryText} className={classes.menuBook} /><span className={classes.menuButtonText}>{i18n.menuBtn[lang]}</span>
                    </div>
                </div>
                <div className={classes.contact}>
                    <h1>{i18n.contact[lang]}</h1>
                    <div className={classes.contactDetails}>
                        <span>{props.restaurant.websiteUrl ? <a href={props.restaurant.websiteUrl}>{props.restaurant.websiteUrl} </a> : null}</span>
                        <div className={classes.socialMediaContainer}>
                            <div className={classes.socialMedia}><a href={props.restaurant.facebookPageUrl}><Facebook className={classes.socialMediaIcon} /></a></div>
                            <div className={classes.socialMedia}><a href={props.restaurant.instagramPageUrl}><Instagram className={classes.socialMediaIcon} /></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


let styles = theme => ({
    root: {
        display: 'flex',
        height: '4em',
        '& hr': {
            height: '4em'
        },
        '@media (max-width: 380px)': {
            '& .MuiButton-text': {
                minWidth: '0',
                width: '3em',
                paddingLeft: '13px'
            },
        },
    },
    container: {
        width: '100%',
        height: '100%',
        overflow:'hidden'
    },
    cancelButton: {
        float: 'right',
        width: '4em',
        '@media (max-width: 380px)': {
            width: '3em'
        },
    
    },
    headerContainer: {
        height: '4em',
        display: 'flex',
        justifyContent: 'space-between',
    },
    darkModePrimaryColor:{
        '& .MuiSvgIcon-root': {
            color:'white'
        }
    },
    darkModeSecondaryColor: {
        color: 'white',
    },
    icon: {
        marginRight: 8,
        fontSize: 16,
        position: 'relative',
        top: '-2px'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 60px)',
        width: '100%',
        backgroundColor:'inherit'
    },
    logo: {
        display:'flex',
        alignItems:'center',
        height: '4em',
        '& img':{
            maxHeight: '4em',
            maxWidth: '8em',
            marginLeft: '1em',
        },
        backgroundColor: 'transparent',
    },
    description: {
        flex: 10,
        padding: '10px 30px 30px 30px',
        overflow: 'auto',
    },
    contact: {
        flex: 3,
        padding: '0px 30px 30px 30px',
        display: 'flex',
        flexDirection: 'column'
    },
    socialMediaContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 18
    },
    socialMedia: {
        height: 40,
        width: 40,
        backgroundColor: 'rgba(0,0,0,0.5)',
        borderRadius: '50%',
        marginRight: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    socialMediaIcon: {
        color: '#ffffff',
        marginTop: '3px'
    },
    company: {
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        '& h1': {
            margin: '0',
            '@media (max-width: 380px)': {
                fontSize:'1em'
            },
        },
        '& h4': {
            margin:'0', 
            '@media (max-width: 380px)': {
                fontSize:'1em'
            },
        }
    },
    restaurantName: {
        display:'flex',
        alignItems:'center',
        flex: '1',
    },
    restaurantLogo: {
        marginLeft:'0.5em',
        marginRight:'0.5em',
        fontSize:'3em'
    },
    menuButtonContainer: {
        height: '130px',
        width: '15em',
        maxWidth: '500px',
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '3em',
    },
    lightMode:{
        backgroundColor: 'white'
    },
    menuButtonText: {
        fontSize: 20,
        fontWeight: 500,
        paddingLeft: '1.5em'
    },
    verifiedRestaurant: {
        display:'flex',
        alignItems:'center',
        padding: '6px 16px',
        '@media (max-width: 380px)': {
            padding: '6px 6px',
        }
    },
    verticalDivider: {
        height:'4em'
    },
    horizontalDivider: {
        height:'0.05em'
    },
    backButtonContainer: {
        display:'flex'
    },
    menuBookContainer: {
        display:'flex',
        justifyContent:'center',
        width:'100%'
    },
    menuBook: {
        fontSize: '4em',
    }
})

export default withRouter(withStyles(styles)(About))