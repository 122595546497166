import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles, Button } from '@material-ui/core'
import { Add, Delete, Edit } from '@material-ui/icons'

import RestaurantTable from '../../common/Table'
import SimpleFormGenerator from '../../common/SimpleFormGenerator'
import RestaurantJodit from './RestaurantJodit/RestaurantJodit'
import FullPageLoading from '../../common/FullPageLoading'

import * as RESTAURANT_REQUESTS from '../../utils/requests/restaurants'
import * as NOTIFICATIONS from '../../utils/notification'

import LANGUAGES from '../../utils/languages.json'

const mapLanguages = () => {
    let mappedLanguages = []

    Object.keys(LANGUAGES).forEach(key => {
        const currentCurrency = { label: LANGUAGES[key].name, value: { [key]: LANGUAGES[key] } }
        mappedLanguages = mappedLanguages.concat(currentCurrency)
    })

    return mappedLanguages
}

const initialFields = [
    { value: '', type: 'text', name: 'name', label: 'Restaurant Name', breakline: true },
    { value: '', type: 'text', name: 'facebookPageUrl', label: 'Facebook Page URL', breakline: true },
    { value: '', type: 'text', name: 'instagramPageUrl', label: 'Instagram Page URL', breakline: true },
    { value: '', type: 'text', name: 'websiteUrl', label: 'Website URL', breakline: true },
    { value: '', type: 'text', name: 'email', label: 'E-mail', breakline: true },
    { value: '', type: 'text', name: 'phone', label: 'Phone number', breakline: true },
    { value: '', type: 'text', name: 'wifiName', label: 'WiFi name', breakline: true },
    { value: '', type: 'text', name: 'wifiPassword', label: 'WiFi password', breakline: true },
    { value: [], type: 'multiselector', name: 'languages', utils: 'Languages', options: mapLanguages() },
]

let Restaurant = props => {

    let { classes } = props
    const [restaurants, setRestaurants] = useState([])
    const [renderPage, setRenderPage] = useState(false)
    const [addModal, setAddModal] = useState(false)
    const [restaurantFields, setRestaurantFields] = useState(initialFields)
    const [editing, setEditing] = useState(false)
    const [restaurantToEdit, setRestaurantToEdit] = useState(null)
    const [fileToUpload, setFileToUpload] = useState(null)
    const [createLoading, setCreateLoading] = useState(false)
    const [fileLogoLightToUpload, setFileLogoLightToUpload] = useState(null)
    const [fileLogoDarkToUpload, setFileLogoDarkToUpload] = useState(null)
    const [restaurantDescription, setRestaurantDescription] = useState('')

    let toggleEditModal = restaurantId => {
        setRestaurantToEdit(restaurantId)
        setFileLogoLightToUpload(null)
        setFileLogoDarkToUpload(null)
        setFileToUpload(null)
        setAddModal(true)
        setEditing(true)
        RESTAURANT_REQUESTS.getById(restaurantId).then(currentRestaurant => {
            Object.keys(currentRestaurant).forEach(key => {
                if (key.toLowerCase() === 'description') {
                    setRestaurantDescription(currentRestaurant[key])
                }
                let languagesIndex = restaurantFields.findIndex(field => field.name === 'languages')
                if (languagesIndex > -1 && currentRestaurant.languages.length && key.toLowerCase() === 'languages') {
                    let languagesValues = []
                    currentRestaurant.languages.forEach(currency => {
                        const currentKey = Object.keys(currency)[0]

                        languagesValues = languagesValues.concat({
                            label: currency[currentKey].name,
                            value: { [currentKey]: LANGUAGES[currentKey] }
                        })
                    })
                    onInputChange({ target: { name: key, value: languagesValues } })
                }
                else {
                    onInputChange({ target: { name: key, value: currentRestaurant[key] } })
                }
            })
        })
    }

    let getRestaurants = async userRef => {
        RESTAURANT_REQUESTS.get(userRef)
            .then(result => {
                const mappedRestaurants = result.map(restaurant => ({
                    _id: restaurant.id,
                    imgUrl: { type: 'img', val: restaurant.photoThumbUrl },
                    name: restaurant.name,
                    description: restaurant.description,
                    // facebookPageUrl: restaurant.facebookPageUrl,
                    // instagramPageUrl: restaurant.instagramPageUrl,
                    // email: restaurant.email,
                    // wifiName: restaurant.wifiName,
                    // wifiPassword: restaurant.wifiPassword
                }))
                setRestaurants(mappedRestaurants)
                clearForm()
                setRenderPage(true)
            })
    }

    let deleteRestaurant = async restaurantId => {
        if (window.confirm("Are you sure?")) {
            RESTAURANT_REQUESTS.deleteRestaurant(restaurantId).then(() => {
                NOTIFICATIONS.success("Restaurant successfully deleted")
                getRestaurants()
            })
                .catch(() => {
                    NOTIFICATIONS.error("Fail to delete")
                })
        }
    }

    let onInputChange = event => {
        let fieldIndex = restaurantFields.findIndex(f => f.name === event.target.name)

        if (!(fieldIndex > -1)) return

        let newFields = [...restaurantFields]

        newFields[fieldIndex].value = event.target.value

        setRestaurantFields(newFields)

    }

    let createRestaurant = async () => {

        let restaurantJson = {}
        let restaurantBackgroundImage = Array.from(fileToUpload || [])
        let restaurantLogoImageLight = Array.from(fileLogoLightToUpload || [])
        let restaurantLogoImageDark = Array.from(fileLogoDarkToUpload || [])

        let allGood = true
        //check for error in formular
        let restaurantFieldsCopy = [...restaurantFields].map(obj => ({ ...obj }))
        restaurantFieldsCopy.forEach(field => {
            if (field.name === 'name' || field.name === 'description' || field.name === 'languages') {
                if (!field.value || !field.value.toString().trim()) {
                    field.error = true
                    allGood = false
                } else {
                    field.error = false
                }
            }
        })

        if (!allGood) {
            setRestaurantFields(restaurantFieldsCopy)
            return NOTIFICATIONS.error("Please complete required forms")
        }

        restaurantFields.map(field => {
            return restaurantJson[field.name] = field.value
        })

        let restaurantFormData = new FormData()
        restaurantFormData.append('backgroundImage', restaurantBackgroundImage[0])
        restaurantFormData.append('logoImageLight', restaurantLogoImageLight[0])
        restaurantFormData.append('logoImageDark', restaurantLogoImageDark[0])
        restaurantFormData.append('name', restaurantJson['name'] || '')
        restaurantFormData.append('description', restaurantDescription || '')
        restaurantFormData.append('facebookPageUrl', restaurantJson['facebookPageUrl'] || '')
        restaurantFormData.append('instagramPageUrl', restaurantJson['instagramPageUrl'] || '')
        restaurantFormData.append('websiteUrl', restaurantJson['websiteUrl'] || '')
        restaurantFormData.append('phone', restaurantJson['phone'] || '')
        restaurantFormData.append('email', restaurantJson['email'] || '')
        restaurantFormData.append('wifiName', restaurantJson['wifiName'] || '')
        restaurantFormData.append('wifiPassword', restaurantJson['wifiPassword'] || '')
        restaurantFormData.append('languages', JSON.stringify(restaurantJson['languages'] ? restaurantJson['languages'].map(language => language.value) : []))

        if (editing) {
            setCreateLoading(true)
            RESTAURANT_REQUESTS.edit(restaurantToEdit, restaurantFormData).then(() => {
                getRestaurants()
                NOTIFICATIONS.success("Restaurant successfully edited")
                setCreateLoading(false)
            })
                .catch(err => {
                    if (err.response && err.response.status === 413) {
                        NOTIFICATIONS.error("Error, image size limit is 1MB")
                    } else {
                        NOTIFICATIONS.error("Fail to edit restaurant")
                    }
                    setCreateLoading(false)
                })
        }
        else {
            if (!restaurantBackgroundImage.length)
                return NOTIFICATIONS.error("You must select a background photo for the restaurant")
            if (!restaurantLogoImageLight.length)
                return NOTIFICATIONS.error("You must select a background photo for the restaurant")
            if (!restaurantLogoImageDark.length)
                return NOTIFICATIONS.error("You must select a background photo for the restaurant")
            setCreateLoading(true)
            RESTAURANT_REQUESTS.createRestaurant(restaurantFormData).then(() => {
                getRestaurants()
                NOTIFICATIONS.success("Restaurant successfully added")
                setCreateLoading(false)
            })
                .catch(err => {
                    if (err.response && err.response.status === 413) {
                        NOTIFICATIONS.error("Error, image size limit is 1MB")
                    } else {
                        NOTIFICATIONS.error("Fail to add restaurant")
                    }
                    setCreateLoading(false)
                })
        }
    }

    let clearForm = () => {
        let newFields = [...restaurantFields.map(field => ({ ...field, value: null, error: false }))]
        setAddModal(false)
        setRestaurantFields(newFields)
    }

    React.useEffect(() => {

        getRestaurants()

    }, [])

    if (renderPage) {
        return (
            <div className={classes.container}>
                <SimpleFormGenerator
                    dialogTitle={editing ? 'EDIT RESTAURANT' : 'CREATE NEW RESTAURANT'}
                    fields={restaurantFields.map(field => {
                        if (field.name === 'languages' && editing) {
                            return {
                                ...field,
                                isDisabled: true
                            }
                        }
                        return field;
                    })}
                    onChange={event => onInputChange(event)}
                    onClose={() => {
                        setAddModal(false)
                        setEditing(false)
                        setRestaurantToEdit(null)
                        clearForm(initialFields)
                    }}
                    onCancel={() => {
                        setAddModal(false)
                        setEditing(false)
                        setRestaurantToEdit(null)
                        clearForm(initialFields)
                    }}
                    onSave={() => createRestaurant()}
                    spinner={createLoading}
                    submitButtonText={editing ? 'EDIT' : 'CREATE'}
                    cancelButtonText={'CANCEL'}
                    open={addModal}>
                    <div className={classes.displayColumn}>
                        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <RestaurantJodit currentValue={restaurantDescription} editing={editing} setValue={value => setRestaurantDescription(value)} />
                        </div>
                        <div>
                            <input onChange={event => setFileToUpload(event.target.files)} type="file" name="file" id="file" accept="image/x-png,image/gif,image/jpeg" className={classes.inputfile} />
                            <Button color="primary" variant="contained"><label htmlFor="file">Upload background image</label></Button>
                        </div>
                        {fileToUpload ? <div className={classes.fileMessage}>A background image is selected</div> : null}
                        <div>
                            <input onChange={event => {
                                setFileLogoLightToUpload(event.target.files)
                            }} type="file" name="logoLight" id="logoLight" accept="image/x-png,image/gif,image/jpeg" className={classes.inputfile} />
                            <Button color="primary" variant="contained"><label htmlFor="logoLight">Upload logo light theme</label></Button>
                        </div>
                        <div>
                            <input onChange={event => {
                                setFileLogoDarkToUpload(event.target.files)
                            }} type="file" name="logoDark" id="logoDark" accept="image/x-png,image/gif,image/jpeg" className={classes.inputfile} />
                            <Button color="primary" variant="contained"><label htmlFor="logoDark">Upload logo dark theme</label></Button>
                        </div>

                        {fileLogoLightToUpload ? <div className={classes.fileMessage}>A logo for light theme is selected</div> : null}
                        {fileLogoDarkToUpload ? <div className={classes.fileMessage}>A logo for dark theme is selected</div> : null}

                    </div>
                </SimpleFormGenerator>
                <RestaurantTable
                    title={'RESTAURANTS'}
                    headerButton={{
                        icon: <Button color='primary' variant='contained'><Add /> <span className={classes.headerButtonText}>RESTAURANT</span></Button>,
                        tooltip: 'Add new restaurant',
                        onClick: () => setAddModal(true)
                    }}
                    header={['LOGO', 'NAME', 'DESCRIPTION']}
                    onClickRow={row => props.history.push({ pathname: `restaurants/${row._id}` })}
                    rows={restaurants}
                    actions={[
                        {
                            icon: <Delete />,
                            tooltip: 'Delete',
                            onClick: (event, row) => deleteRestaurant(row._id)
                        },
                        {
                            icon: <Edit />,
                            tooltip: 'Edit',
                            onClick: (event, row) => {
                                toggleEditModal(row._id)
                            }
                        }
                    ]}
                />
            </div >
        )
    } else return <FullPageLoading />
}

let styles = theme => ({
    container: {
        width: '100%',
        height: '100%'
    },
    headerButtonText: {
        marginTop: 2
    },
    inputfile: {
        width: '0.1px',
        height: '0.1px',
        opacity: 0,
        overflow: 'hidden',
        position: 'absolute',
        zIndex: -1,
    },
    displayColumn: {
        display: 'flex',
        flexDirection: 'column',
        margin: 2,
        '& div': {
            marginTop: '10px'
        }
    },
    fileMessage: {
        padding: 4
    }
})

export default withRouter(withStyles(styles)(Restaurant))