import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, TextField } from '@material-ui/core'

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        spacing: 1,
        width: '100%'
    },
    selectEmpty: {
        spacing: 2
    },
    menu: {
        backgroundColor: 'white'
    },
    muiSelectRoot: {
        '& > select': {
            width: '100%',
            height: '100%',
            boxSizing: 'border-box',
            paddingTop: '8.5px'
        }
    }
})

class DropdownSelector extends React.Component {

    state = {
        value: ""
    }

    componentDidMount() {
        this.setState({ value: "" })
    }

    handleChange = event => {
        this.props.onChange({
            target: {
                name: this.props.name,
                value: event.target.value
            }
        })
        this.setState({ value: event.target.value })
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.root}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <TextField
                        select
                        disabled={this.props.disabled}
                        label={this.props.utils}
                        className={classes.textField}
                        value={this.props.value}
                        error={this.props.error}
                        style={this.props.style}
                        onChange={event => this.handleChange(event)}
                        InputLabelProps={{
                            shrink: true
                        }}
                        SelectProps={{
                            native: true,
                            classes: {
                                root: classes.muiSelectRoot
                            },
                            MenuProps: {
                                className: classes.menu,
                            }
                        }}
                        margin="normal"
                        variant="outlined"
                        id='dropdownSelector'
                    >
                        {this.props.options.map((option, index) => (
                            <option key={index} value={option.value}>
                                {option.label || option.name}
                            </option>
                        ))}
                    </TextField>
                </FormControl>
            </div>
        );
    }
}



export default withStyles(styles)(DropdownSelector)