import axios from '../axios'
import { errorHandling } from '../helpers'

export const getMenuCategories = (restaurantId, menuId) => axios.get(`mobile/restaurants/${restaurantId}/menus/${menuId}/categories`)
    .then(res => res.data)
    .catch(errorHandling)

export const getMenuProducts = (restaurantId, menuId, categoryId) => axios.get(`mobile/restaurants/${restaurantId}/menus/${menuId}/products/categories/${categoryId}`)
    .then(res => res.data)
    .catch(errorHandling)

export const getMenuProductsAll = (restaurantId, menuId, categoryId, subcategoryId, productId) => axios.get(`mobile/restaurants/${restaurantId}/menus/${menuId}/products/categories/${categoryId}/${subcategoryId}/${productId}/all`)
    .then(res => res.data)
    .catch(errorHandling)

export const getRestaurant = restaurantId => axios.get(`mobile/restaurants/${restaurantId}`)
    .then(res => res.data)
    .catch(errorHandling)

export const getProductById = (restaurantId, menuId, productId) => axios.get(`/mobile/restaurants/${restaurantId}/menus/${menuId}/products/${productId}`)
    .then(res => res.data)
    .catch(errorHandling)

export const sendEmail = (restaurantId, obj) => axios.post(`/mobile/restaurants/${restaurantId}/contact`, obj)
    .then(res => res.data)
    .catch(errorHandling)

export const getById = (restaurantId, menuId) => axios.get(`/mobile/restaurants/${restaurantId}/menus/${menuId}`)
    .then(res => res.data)
    .catch(errorHandling)

export const getRestaurantDesign = restaurantId => axios.get(`mobile/restaurants/${restaurantId}/design`)
    .then(res => res.data)
    .catch(errorHandling)
