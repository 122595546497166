import axios from '../axios'
import { errorHandling } from '../helpers'

export const get = () => axios.get(`globalProducts`)
    .then(res => res.data)
    .catch(errorHandling)

export const getById = productId => axios.get(`globalProducts/${productId}`)
    .then(res => res.data)
    .catch(errorHandling)

export const deleteProduct = productId => axios.delete(`globalProducts/${productId}`)
    .then(res => res.data)
    .catch(errorHandling)