import React, { useState } from "react";
import { DialogContent, DialogContentText } from "@material-ui/core";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  iconSelectorContainer: {
    marginTop: "2em",
  },
  dialogContent: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: 'column',
    marginLeft: "1em",
  },
  description: {
    marginLeft: "1.5em",
    fontSize: "15px",
  },
  icon: {
    marginLeft: "2em",
  },
  flexRow: {
    display: 'flex',
    fontSize: "15px",
    color: 'rgba(0, 0, 0, 0.54)',
    borderBottom: '1px solid #e0e0e0',
    width: '100%',
    marginLeft: '0px',
    padding: '2px',
    marginTop: '20px',
    '& > input': {
      marginLeft: '20px'
    },
    '& > img': {
      maxWidth: '40px',
      maxHeight: '40px',
      position: 'relative',
      top: '-10px',
      display: 'flex',
      marginLeft: 'auto',
      marginRight: '30px',
    }
  }
});

const CategoryIconSelector = (props) => {
  const { classes } = props;

  const [lightPreview, setLightPreview] = useState(undefined);
  const [darkPreview, setDarkPreview] = useState(undefined);

  return (
    <div className={classes.iconSelectorContainer}>
      <DialogContentText className={classes.description}>
        Upload category icon (50 x 50)
      </DialogContentText>
      <DialogContent id="iconSelector" className={classes.dialogContent}>
        <div className={classes.flexRow}>
          <label for="imageDark">Dark theme icon</label>
          <input
            onChange={(event) => {
              setDarkPreview(URL.createObjectURL(event.target.files[0]));
              props.setDarkIcon(event.target.files[0]);
            }}
            type="file"
            name="imageDark"
            id="imageDark"
            accept="image/x-png,image/svg,image/jpeg"
            className={classes.inputfile}
          />
          {darkPreview || props.category.imageUrlDark ?
            <img alt='Dark theme category icon' src={darkPreview || props.category.imageUrlDark} />
            : null}

        </div>
        <div className={classes.flexRow}>
          <label for="imageLight">Light theme icon</label>
          <input
            onChange={(event) => {
              setLightPreview(URL.createObjectURL(event.target.files[0]));
              props.setLightIcon(event.target.files[0]);
            }}
            type="file"
            name="imageLight"
            id="imageLight"
            accept="image/x-png,image/svg,image/jpeg"
            className={classes.inputfile}
          />
          {lightPreview || props.category.imageUrlLight ?
            <img alt='Light theme category icon' src={lightPreview || props.category.imageUrlLight} />
            : null}
        </div>

        <div className={classes.icon}>

        </div>
      </DialogContent>
    </div>
  );
};

export default withRouter(withStyles(styles)(CategoryIconSelector));
