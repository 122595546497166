import React from 'react'
import { withStyles } from '@material-ui/core'

import Modal from '../../common/Modal'
import TooltipMenu from '../../common/TooltipMenu'
import { Language } from '@material-ui/icons'


let ProductDetails = props => {

    let { classes } = props
    const fields = [
        { name: 'price', label: 'Price' },
        { name: 'calories', label: 'Calories' },
        { name: 'ingredients', label: 'Ingredients' },
        { name: 'quantity', label: 'Quantity' },
        { name: 'quantityUnit', label: 'Quantity Unit' },
        { name: 'weight', label: 'Weight' },
        { name: 'weightUnit', label: 'Weight Unit' },
        { name: 'waitingTime', label: 'Waiting Time' },
        { name: 'isSpicy', label: 'Is spicy' },
        { name: 'veganTag', label: 'Vegan Tag' },
        { name: 'alergens', label: 'Alergens Tags' }
    ]

    const translateFunction = obj => {
        if (obj[props.product.currentLanguage]) return obj[props.product.currentLanguage]
        return obj[Object.keys(obj)[0]]
    }

    const renderContent = () => {

        return props.product ? (
            <div className={classes.flexColumn}>
                <div className={classes.imageContainer}>
                    <img src={props.product.photoUrl} className={classes.imageStyle} alt='' />
                </div>
                <div>
                    <div className={classes.titleContainer}>
                        <div className={classes.equalFlex}>
                            <p className={`${classes.description}`}>{translateFunction(props.product.description)}</p>
                        </div>
                    </div>
                    <div className={classes.flexColumn}>
                        {fields.map(field => {
                            let name = props.product[field.name]
                            if (typeof props.product[field.name] === 'object') name = translateFunction(props.product[field.name])
                            if (typeof props.product[field.name] === 'boolean') name = props.product[field.name] ? 'True' : 'False'
                            return (
                                <div className={`${classes.flexRow} ${classes.detailsPadding}`}>
                                    <span className={classes.detailsText}>{field.label}:</span> &nbsp;<span>{name || '-'}</span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        ) : null
    }

    const onClose = () => {
        props.onClose()
    }

    return (
        <Modal
            onCancel={onClose}
            onClose={onClose}
            title={props.product ? <span> {translateFunction(props.product.name)}<TooltipMenu onClick={lang => props.changeLanguage(lang)}
                options={Object.keys(props.product.name).map(key => ({ label: key, value: key }))}
                buttonText={props.product.currentLanguage}
                frontIcon={<Language style={{ marginRight: '5px', top: '-1px' }} />}
            /> </span> : ''}
            cancelButtonText={"CLOSE"}
            maxWidth={'sm'}
            open={props.open}>
            {renderContent()}
        </Modal>
    )
}

let styles = theme => ({
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    imageContainer: {
        height: 200,
        width: '100%'
    },
    imageStyle: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: 5
    },
    equalFlex: {
        flex: 1
    },
    priceContainer: {
        marginLeft: 'auto'
    },
    titleContainer: {
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        padding: 8,
        marginBottom: 8,
        display: 'flex',
        flexDirection: 'row'
    },
    detailsPadding: {
        padding: 8
    },
    detailsText: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    description: {
        fontSize: 15,
        color: 'rgba(0, 0, 0, 0.54)'
    }
})

export default withStyles(styles)(ProductDetails)