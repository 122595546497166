import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { withStyles, Button, CircularProgress } from '@material-ui/core';

class Basic extends Component {
    constructor() {
        super();
        this.onDrop = (files) => {
            this.setState({ files })
        };
        this.state = {
            files: [],
            spinner: false
        };
    }

    uploadImagesHandler = () => {
        this.setState({
            spinner: true
        }, async () => {
            await this.props.onSubmitButtonClicked(this.state.files)
            this.setState({ spinner: false, files: [] })
        })
    }

    render() {
        const files = this.state.files.map(file => {
            // let files = Array.from(this.state.filesToUpload)
            // if (!files.length) return;

            // const formData = new FormData()
            // files.forEach((file, i) => {
            //     formData.append('file', file)
            // })

            // return this.props.uploadFileToStock(stockId, formData)
            return (
                <li key={file.name}>
                    <span style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{file.name}</span> - {file.size} bytes
                </li>
            )
        });

        return (
            <div className={this.props.classes.container}>
                <div className={this.props.classes.dropzoneContainer}>
                    <Dropzone onDrop={this.onDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <section className={`container ${this.props.classes.insideDropzone}`}>
                                <div {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps()} />
                                    <p style={{ paddingLeft: 6, textAlign: 'center' }}>Click or drop the files here</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>
                <div>
                    <aside>
                        <h4>Files</h4>
                        {this.state.spinner ? <CircularProgress style={{ marginBottom: '20px' }} /> : <ul style={{ paddingLeft: '20px', fontSize: '14px', paddingBottom: '20px' }}>{files}</ul>}
                    </aside>
                </div>
                <div>
                    <Button color="primary" variant="contained" onClick={this.uploadImagesHandler}>UPLOAD</Button>
                </div>
            </div>
        );
    }
}
let styles = theme => ({
    container: {
        border: '1px solid rgba(0,0,0,0.1)',
        margin: 12,
        padding: 20,
        display: 'flex',
        flexDirection: 'column'
    },
    insideDropzone: {
        height: 200
    },
    dropzoneContainer: {
        borderWidth: 2,
        borderRadius: 2,
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    }
})

export default withStyles(styles)(Basic)