import React from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'

import Root from './components/Root'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import MuiTheme from './utils/GlobalMuiTheme'

import '../src/styles/App.css'

let App = () => {
    return (

        <MuiThemeProvider theme={MuiTheme}>
            <ToastContainer autoClose={5000} />
            <section className="appContainer">
                <Root />
            </section>
        </MuiThemeProvider >
    )
}

export default App;
