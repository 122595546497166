import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'

import Modal from '../../../common/Modal'
import InputGenerator from '../../../common/InputGenerator'

import * as NOTIFICATIONS from '../../../utils/notification'
import * as CATEGORIES_REQUESTS from '../../../utils/requests/categories'
import i18nLang from '../../../utils/i18n.json'

let InputWrapper = ({ onChange, shrink, input, keyProps }) => <div>
    <InputGenerator
        key={keyProps}
        fullWidth={true}
        InputLabelProps={shrink ? { shrink: true } : {}}
        margin="dense"
        {...input}
        value={input.value}
        onChange={onChange}
    />
</div>

let initialFields = [
    { value: '', type: 'text', name: 'name', label: 'Category Name' },
]

let CreateCategory = props => {

    let { classes } = props
    const [categoryFields, setCategoryFields] = useState([])
    const lang = localStorage.getItem('currentLanguage')

    React.useEffect(() => {
        if (props.languages) {
            let baseField = [...initialFields].map(obj => ({ ...obj }))[0]
            let newFields = props.languages.map(language => {
                let short = Object.keys(language)[0]
                let name = Object.values(language)[0]
                return {
                    ...baseField, value: props.categoryToEdit.name ? props.categoryToEdit.name[short] : '', name: `${baseField.name}-${short}`, label: `${baseField.label} - ${name.name}`
                }
            })
            setCategoryFields(newFields)
        }
    }, [props.open])

    let onInputChange = event => {
        let fieldIndex = categoryFields.findIndex(f => f.name === event.target.name)

        if (!(fieldIndex > -1)) return

        let newFields = [...categoryFields]

        newFields[fieldIndex].value = event.target.value
        setCategoryFields(newFields)

    }

    const renderFields = () => {
        return categoryFields.map((field, index) => {
            return (
                <div key={index} className={classes.flexRow}>
                    <div className={`${classes.flexColumn} ${classes.equalFlex}`}>
                        <div className={`${classes.equalFlex} ${classes.paddingTopButton}`}>
                            <InputWrapper onChange={event => onInputChange(event)} shrink={true} input={field} key={field.name} />
                        </div>
                    </div>
                </div>
            )
        })
    }

    const onCreate = () => {
        let languages = {}

        let allGood = true
        let uniqueName = true
        let categoryFieldsCopy = [...categoryFields].map(obj => ({ ...obj }))
        categoryFieldsCopy.forEach(field => {
            if (!field.value) {
                field.error = true
                allGood = false
            }
            props.categories.some((category) => {
                let keys = Object.keys(category.name)
                keys.forEach(lang => {
                    if(field.value === category.name[lang]){
                        field.error = true
                        uniqueName = false
                    } 
                })
            })
        })

        if (!allGood) {
            setCategoryFields(categoryFieldsCopy)
            return NOTIFICATIONS.error("Please complete all forms")
        }
        if (!props.editing && !uniqueName) {
            setCategoryFields(categoryFieldsCopy)
            return NOTIFICATIONS.error(i18nLang.createCategory.nameUsed[lang])
        }

        categoryFields.map(field => {
            let short = field.name.split('-')[1]
            languages[short] = field.value
        })
        let categoryJson = {
            name: languages
        }

        if(props.editing)
        {
            let categoryRenamed = props.categoryToEdit
            categoryRenamed.name = categoryJson.name
            CATEGORIES_REQUESTS.renameCategory(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID, props.categoryToEdit.id, { name: categoryJson.name }
            ).then(() => {
                if(categoryRenamed.id !== props.selectedCategory.id)
                    props.setSelectedCategory(props.selectedCategory)
                else
                    props.setSelectedCategory(categoryRenamed)
                props.getCategories(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID)
                setCategoryFields(categoryFields.map(field => ({ ...field, error: false, value: '' })))
                NOTIFICATIONS.success(i18nLang.createCategory.rename_succes[lang])
                props.onClose()
            })
            .catch(err => {
                NOTIFICATIONS.error(i18nLang.createCategory.rename_errors[lang])
            })
        }
        else{
            CATEGORIES_REQUESTS.create(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID, { name: categoryJson.name })
            .then(() => {
                props.getCategories(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID)
                props.onClose()
                setCategoryFields(categoryFields.map(field => ({ ...field, error: false, value: '' })))
                NOTIFICATIONS.success("Successfully added new category")
            })
            .catch(err => {
                NOTIFICATIONS.error("Fail to create")
            })
        }
    }

    const onClose = () => {
        props.onClose()
    }

    return (
        <Modal
            onCancel={onClose}
            onClose={onClose}
            acceptButtonText={props.editing ? i18nLang.createCategory.rename[lang] : i18nLang.createCategory.add[lang]}
            title={props.editing ? i18nLang.createCategory.rename[lang] : i18nLang.createCategory.create[lang]}
            cancelButtonText={i18nLang.createCategory.cancel[lang]}
            maxWidth={"sm"}
            onAccept={() => onCreate()}
            open={props.open}>
            {renderFields()}
        </Modal>
    )
}

let styles = theme => ({
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    equalFlex: {
        flex: 1
    }
})

export default withRouter(withStyles(styles)(CreateCategory))