import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'

import CategoryList from './Category/CategoryList'
import SubcategoryList from './Category/SubcategoryList'
import ProductList from './Products/ProductsList'
import RestaurantHeader from '../../common/RestaurantHeader'
import LANGUAGE from '../../utils/language'
import FullPageLoading from '../../common/FullPageLoading'
import * as NOTIFICATION from '../../utils/notification'
import * as PRODUCTS_REQUESTS from '../../utils/requests/products'
import * as MENUS_REQUESTS from '../../utils/requests/menus'
import * as RESTAURANTS_REQUESTS from '../../utils/requests/restaurants'
import * as CATEGORIES_REQUESTS from '../../utils/requests/categories'
import { i18n } from '../../utils/helpers'
import i18nLang from '../../utils/i18n.json'

let MenuDetails = props => {

    let { classes } = props
    const [renderPage, setRenderPage] = useState(false)
    const [currentMenu, setCurrentMenu] = useState(null)
    const [products, setProducts] = useState([])
    const [countProducts, setCountProducts] = useState([])
    const [goesWellProducts, setGoesWellProducts] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [categories, setCategories] = useState([])
    const [selectedSubCategory, setSelectedSubCategory] = useState('')
    const [subCategories, setSubCategories] = useState([])
    const [currentRestaurant, setRestaurant] = useState(null)
    const [isProductsLoading, setLoadingProducts] = useState(false)
    const lang = localStorage.getItem('currentLanguage')
    const getCurrentMenu = async () => {
        // Get current menu with details
        let responses = await Promise.all([
            MENUS_REQUESTS.getById(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID),
            CATEGORIES_REQUESTS.get(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID)
        ])
        let categories = addOrder(responses[1])
        let resultMenu = responses[0]
        if (categories.length) {
            // Set selected category to the first category of the menu
            setSelectedCategory(categories[0])
            setCategories(categories)
            let subCategories = await CATEGORIES_REQUESTS.getSubcategories(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID, categories[0].id)
            setSubCategories(addOrder(subCategories))
        }
        else {
            setSubCategories([])
            setCategories([])
        }

        // Get associated products
        setCurrentMenu(resultMenu)

        if (categories.length)
            await getProductsByMenuAndCategory(categories[0].id)

        setRenderPage(true)
    }

    const getCategories = async (restaurantId, menuId) => {
        let categories = await CATEGORIES_REQUESTS.get(restaurantId, menuId)
        setCategories(addOrder(categories))
        if (categories.length === 1) {
            setSelectedCategory(categories[0])
            return categories[0]
        }
        return null
    }

    const getSubcategories = async (restaurantId, menuId, categoryId) => {
        let subCategories = await CATEGORIES_REQUESTS.getSubcategories(restaurantId, menuId, categoryId)
        setSubCategories(addOrder(subCategories))
    }

    const getProductsByMenuAndCategory = async (categoryId, subcategory) => {
        setLoadingProducts(true)
        let associatedProducts = await PRODUCTS_REQUESTS.get(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID, categoryId, true)
        setCountProducts(associatedProducts)
        if (subcategory && subcategory.id) {
            associatedProducts = associatedProducts.filter(product => {
                return product.subcategory === subcategory.id
            })
        }
        setProducts(associatedProducts)
        setLoadingProducts(false)
    }

    const getProductsByRestaurant = async restaurantId => {
        const associatedProducts = await PRODUCTS_REQUESTS.get(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID, selectedCategory.id, true)
        setGoesWellProducts(associatedProducts)
    }

    const filterProductsBySubcategory = (products, subcategory) => {
        return products.filter(product => {
            return product.subcategory === subcategory.id
        }).length
    }

    const getCurrentRestaurant = async () => {
        let restaurant = await RESTAURANTS_REQUESTS.getById(props.match.params.RESTAURANT_ID)
        setRestaurant({
            name: restaurant.name,
            photoUrl: restaurant.photoUrl,
            id: restaurant.id,
            languages: restaurant.languages,
            currencies: restaurant.currencies
        })
    }

    const onSearchHandler = async productName => {
        const associatedProducts = await PRODUCTS_REQUESTS.getByRestaurant(props.match.params.RESTAURANT_ID, productName)
        setGoesWellProducts(associatedProducts)
    }

    const deleteCategory = async categoryId => {
        if (window.confirm("Delete category?")) {
            CATEGORIES_REQUESTS.deleteById(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID, categoryId).then(() => {
                NOTIFICATION.success("Category deleted")
                getCurrentMenu()
            }).catch(() => {
                NOTIFICATION.success("Cannot delete category")
            })

        }
    }

    const addOrder = list => list.map((item, index) => ({ order: index, ...item }))

    const deleteSubcategory = async subcategoryId => {
        if (window.confirm("Delete subcategory?")) {
            CATEGORIES_REQUESTS.deleteSubcategoryById(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID, selectedCategory.id, subcategoryId).then(() => {
                NOTIFICATION.success("Subcategory deleted")
                getSubcategories(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID, selectedCategory.id)
                setSelectedSubCategory('')
            }).catch(() => {
                NOTIFICATION.success("Cannot delete subcategory")
            })

        }
    }

    const setSubcategoriesOrder = subcategories => {
        setSubCategories(subcategories)
        CATEGORIES_REQUESTS.reorderSubcategories(
            props.match.params.RESTAURANT_ID,
            props.match.params.MENU_ID,
            selectedCategory.id,
            subcategories
                .map((subcategory, index) => ({ id: subcategory.id, order: index }))
        )
    }

    const setCategoriesOrder = categories => {
        setCategories(categories)
        CATEGORIES_REQUESTS.reorder(
            props.match.params.RESTAURANT_ID,
            props.match.params.MENU_ID,
            categories
                .map((category, index) => ({ id: category.id, order: index }))
        )
    }

    const hideSubcategory = (subcategoryId) => {
        const subCategoryToHide = subCategories.filter( subCategory => subCategory.id === subcategoryId)
        if(subCategoryToHide) {
            CATEGORIES_REQUESTS.hideSubcategoryById(
                props.match.params.RESTAURANT_ID,
                props.match.params.MENU_ID,
                selectedCategory.id,
                subcategoryId,
                { isHidden: !subCategoryToHide[0].isHidden }
            ).then(() => {
                NOTIFICATION.success(subCategoryToHide[0].isHidden ? `${i18nLang.subcategoryOptions.notification_succes_display[lang]}` : `${i18nLang.subcategoryOptions.notification_succes_hide[lang]}`)
                getSubcategories(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID, selectedCategory.id)
            }).catch(() => {
                NOTIFICATION.error(`${i18nLang.subcategoryOptions.notification_error[lang]}`)
            })}
        else return null
    }

    const onGenerate = async (id) => {
        if (window.confirm(i18nLang.autoPopulateQuestion[lang])) {
            await PRODUCTS_REQUESTS.getGoesWellWith(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID, id)
            .then(() => {
                NOTIFICATION.success(i18nLang.autocompleteGoesWellWith_succes[lang])
            }).catch(() => {
                NOTIFICATION.error(i18nLang.autocompleteGoesWellWith_error[lang]) 
            })
        }
    }

    React.useEffect(() => {
        // At component mount fetch all necesarlly data
        getCurrentMenu()
        getCurrentRestaurant()
    }, [])

    if (renderPage && currentRestaurant) {

        return (
            <div className={classes.container}>
                {currentRestaurant && <RestaurantHeader backButton={true} menu={currentMenu} restaurant={currentRestaurant} />}

                <div className={classes.contentContainer}>
                    <div className={classes.categoryContainer}><CategoryList
                        menu={currentMenu}
                        getCategories={getCategories}
                        restaurant={currentRestaurant}
                        categories={categories.map(category => ({ 
                          id: category.id,
                          name: category.name,
                          label: LANGUAGE.category[category], 
                          order: category.order, 
                          startTime: category.startTime, 
                          endTime: category.endTime,
                          imageUrlDark: category.imageUrlDark,
                          imageUrlLight: category.imageUrlLight
                        }))}
                        setCategoriesOrder={setCategoriesOrder}
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                        onDelete={deleteCategory}
                        onGenerate={onGenerate}
                        onClickCategory={async category => {
                            setSelectedCategory(category)
                            setSubCategories(await CATEGORIES_REQUESTS.getSubcategories(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID, category.id))
                            await getProductsByMenuAndCategory(category.id)
                        }}
                    /></div>
                    <div className={classes.productsContainer}>
                        <div className={classes.subcategoryContainer}>
                            <SubcategoryList
                                getSubcategories={getSubcategories}
                                setSubcategoriesOrder={setSubcategoriesOrder}
                                menu={currentMenu}
                                restaurant={currentRestaurant}
                                categories={subCategories.map(subcategory => ({ id: subcategory.id, name: subcategory.name, label: subcategory.name, count: filterProductsBySubcategory(countProducts, subcategory), order: subcategory.order, isHidden: subcategory.isHidden }))}
                                subCategories={subCategories}
                                selectedSubCategory={selectedSubCategory}
                                selectedCategory={selectedCategory}
                                products={products}
                                onDelete={deleteSubcategory}
                                onHide={hideSubcategory}
                                showAddButton={selectedCategory && i18n(selectedCategory.name).length ? true : false}
                                onClickSubcategory={async subcategory => {
                                    if (subcategory.id === selectedSubCategory.id) {
                                        setSelectedSubCategory('')
                                        await getProductsByMenuAndCategory(selectedCategory.id)
                                    } else {
                                        setSelectedSubCategory(subcategory)
                                        await getProductsByMenuAndCategory(selectedCategory.id, subcategory)
                                    }
                                }}
                            />
                        </div>
                        <div className={classes.products}>
                            {!isProductsLoading ? <ProductList
                                currentMenu={currentMenu}
                                baseCurrencySet={props.baseCurrencySet}
                                onSearch={productName => onSearchHandler(productName)}
                                categoriesAndSubcategories={{ categories: categories, subcategories: subCategories }}
                                getProducts={() => getProductsByMenuAndCategory(selectedCategory.id, selectedSubCategory)}
                                getRestaurantProducts={() => getProductsByRestaurant(props.match.params.RESTAURANT_ID)}
                                menuId={props.match.params.MENU_ID}
                                restaurantId={props.match.params.RESTAURANT_ID}
                                restaurant={currentRestaurant}
                                showAddButton={selectedCategory && i18n(selectedCategory.name).length ? true : false}
                                products={products}
                                goesWellProducts={goesWellProducts} /> : <FullPageLoading />}
                        </div>
                    </div>
                </div >
            </div>
        )
    } else return (
        <FullPageLoading />
    )
}

let styles = theme => ({
    container: {
        height: '100%',
        width: 'calc(100% - 40px)',
        padding: 20
    },
    contentContainer: {
        width: '100%',
        height: 'calc(100% - 60px)',
        display: 'flex',
        flexDirection: 'row'
    },
    imageHeaderContainer: {
        minHeight: 250,
        width: '100%',
        backgroundSize: 'cover',
        position: 'relative',
        margin: '0 auto'
    },
    imageStyle: {
        maxHeight: '100%',
        width: '100%',
        objectFit: 'fill'
    },
    imageText: {
        position: 'absolute',
        bottom: 0,
        background: 'rgba(0,0,0,0.5)',
        color: '#f1f1f1',
        width: '100%'
    },
    categoryContainer: {
        flex: 1
    },
    productsContainer: {
        flex: 4,
        marginLeft: 40,
        width: 'calc(100% - 230px)',
        display: 'flex',
        flexDirection: 'column'
    },
    header: {
        height: 60,
        borderBottom: '1px solid black'
    },
    subcategoryContainer: {
        backgroundColor: '#9ca4ab',
        opacity: 1,
        borderRadius: 5,
        margin: '15px 10px',
        padding: 10
    },
    products: {
        flex: 3
    },
    center: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    loadingText: {
        letterSpacing: '3px'
    }
})

export default withRouter(withStyles(styles)(MenuDetails))