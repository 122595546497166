import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles, Button, Paper, FormControlLabel, Checkbox } from '@material-ui/core'
import * as RESTAURANT_REQUESTS from '../../../utils/requests/restaurants'
import * as Notification from '../../../utils/notification'
import FullPageLoading from '../../../common/FullPageLoading'
import ColorPicker from './ColorPicker'
import { RESTAURANT_DESIGN } from '../../../utils/constants'
import i18n from '../../../utils/i18n.json'
let RestaurantDesign = (props) => {
  
    const { classes } = props
    const [renderPage, setRenderPage] = useState(false)
    const [design, setDesign] = useState({})
    let getDesign = async () => {
        let restaurantDesign = await RESTAURANT_REQUESTS.getRestaurantDesign(props.restaurantId)
        setDesign(restaurantDesign)
        setRenderPage(true)
    }

    useEffect(() => {
        getDesign()
    }, [])

    const handleChange = (color, name, theme) => {
        let newDesign = { ...design }
        newDesign[theme][name] = color
        setDesign(newDesign)
    }

    let changeDesign = () => {
        RESTAURANT_REQUESTS.updateRestaurantDesign(props.restaurantId, {design: design, reset: false})
        .then(() => {
            Notification.success('New theme set successfully')
        }).catch(() => {
            Notification.error('Could not set new theme')
        })
    }

    let resetDesign = () => {
        if (window.confirm("Reset theme?")) {
            setRenderPage(false)
            RESTAURANT_REQUESTS.updateRestaurantDesign(props.restaurantId, {reset: true})
            .then((res) => {
                setDesign(res)
                setRenderPage(true)
                Notification.success('Reset successfully done')
            }).catch(() => {
                Notification.error('Could not reset the theme')
            })
        }
    }

    const changeCheckbox = (event) => {
        let newDesign = { ...design }
        newDesign['additionalSettings'] = {hideRestaurantName : event.target.checked}
        setDesign(newDesign)
    }

    if(renderPage) {
        return (
            <Paper className={classes.container}>
                <div className={classes.themeContainer}>
                    <div className={classes.padding}>
                        <h4 className={classes.titles}>{RESTAURANT_DESIGN.LIGHT_THEME}</h4>
                        <div className={classes.colorContainer}>
                            <h4> {RESTAURANT_DESIGN.PRIMARY_COLOR} </h4>
                            <ColorPicker 
                                theme={RESTAURANT_DESIGN.light} 
                                name={RESTAURANT_DESIGN.primary} 
                                onChange={(color, name, theme) => handleChange(color, name, theme)} 
                                color={design[RESTAURANT_DESIGN.light].primary} />
                        </div>
                        <div className={classes.colorContainer}>
                            <h4> {RESTAURANT_DESIGN.SECONDARY_COLOR} </h4>
                            <ColorPicker 
                                theme={RESTAURANT_DESIGN.light} 
                                name={RESTAURANT_DESIGN.secondary} 
                                onChange={(color, name, theme) => handleChange(color, name, theme)} 
                                color={design[RESTAURANT_DESIGN.light].secondary} />
                        </div>
                        <div className={classes.colorContainer}>
                            <h4> {RESTAURANT_DESIGN.PRIMARY_TEXT_COLOR} </h4>
                            <ColorPicker 
                                theme={RESTAURANT_DESIGN.light} 
                                name={RESTAURANT_DESIGN.primaryText} 
                                onChange={(color, name, theme) => handleChange(color, name, theme)} 
                                color={design[RESTAURANT_DESIGN.light].primaryText} />
                        </div>
                            <div className={classes.colorContainer}>
                            <h4> {RESTAURANT_DESIGN.SECONDARY_TEXT_COLOR} </h4>
                            <ColorPicker 
                                theme={RESTAURANT_DESIGN.light} 
                                name={RESTAURANT_DESIGN.secondaryText} 
                                onChange={(color, name, theme) => handleChange(color, name, theme)} 
                                color={design[RESTAURANT_DESIGN.light].secondaryText} />
                        </div>
                    </div>
                    <div className={classes.padding}>
                        <h4 className={classes.titles}>{RESTAURANT_DESIGN.DARK_THEME}</h4>
                        <div className={classes.colorContainer}>
                            <h4> {RESTAURANT_DESIGN.PRIMARY_COLOR} </h4>
                            <ColorPicker 
                                theme={RESTAURANT_DESIGN.dark} 
                                name={RESTAURANT_DESIGN.primary} 
                                onChange={(color, name, theme) => handleChange(color, name, theme)} 
                                color={design[RESTAURANT_DESIGN.dark].primary} />
                        </div>
                        <div className={classes.colorContainer}>
                            <h4> {RESTAURANT_DESIGN.SECONDARY_COLOR} </h4>
                            <ColorPicker 
                                theme={RESTAURANT_DESIGN.dark} 
                                name={RESTAURANT_DESIGN.secondary} 
                                onChange={(color, name, theme) => handleChange(color, name, theme)} 
                                color={design[RESTAURANT_DESIGN.dark].secondary} />
                        </div>
                        <div className={classes.colorContainer}>
                            <h4> {RESTAURANT_DESIGN.PRIMARY_TEXT_COLOR} </h4>
                            <ColorPicker 
                                theme={RESTAURANT_DESIGN.dark} 
                                name={RESTAURANT_DESIGN.primaryText} 
                                onChange={(color, name, theme) => handleChange(color, name, theme)} 
                                color={design[RESTAURANT_DESIGN.dark].primaryText} />
                        </div>
                        <div className={classes.colorContainer}>
                            <h4> {RESTAURANT_DESIGN.SECONDARY_TEXT_COLOR} </h4>
                            <ColorPicker 
                                theme={RESTAURANT_DESIGN.dark} 
                                name={RESTAURANT_DESIGN.secondaryText} 
                                onChange={(color, name, theme) => handleChange(color, name, theme)} 
                                color={design[RESTAURANT_DESIGN.dark].secondaryText} />
                        </div>
                    </div>
                    <div className={classes.padding}>
                        <div className={classes.hide}>
                            <h4> Hide restaurant name </h4>
                            <FormControlLabel
                                className={classes.listItems}
                                control={
                                    <Checkbox
                                        onChange={changeCheckbox}
                                        checked={design['additionalSettings']['hideRestaurantName']}
                                    />
                                }
                            />
                        </div>
                    </div>
                </div>
                <Button 
                    className={classes.submitButton} 
                    color='primary' 
                    variant='contained' 
                    onClick={() => changeDesign()}>
                    Save
                </Button>
                <Button 
                    className={classes.submitButton} 
                    color='primary' 
                    variant='contained'
                    onClick={() => resetDesign()}>
                    Reset
                </Button>
            </Paper>
      )
    } else return <FullPageLoading fullHeight={false} />
  
}

let styles = (theme) => ({
    container: {
        width: '100%',
        overflow: 'auto',
        marginBottom: '40px',
    },
    colorContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& h4': {
        paddingLeft: '1em',
        paddingRight: '2em',
        width: '7em',
        },
    },
    padding: {
        padding: '0px 20px 20px 20px',
        background: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
    },
    titles: {
        paddingLeft: 8,
    },
    submitButton: {
        width: 150,
        marginTop: 'auto',
        marginLeft: '21px',
        marginBottom:'4em'
    },
    themeContainer: {
        display: 'flex',
        width: '50%',
        justifyContent: 'space-between'
    },
    hide: {
        display:'flex', 
        width:'20em', 
        justifyContent:'space-around'
    }
})

export default withRouter(withStyles(styles)(RestaurantDesign))
