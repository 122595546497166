import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core'

import Modal from '../../../common/Modal'
import InputGenerator from '../../../common/InputGenerator'

import * as NOTIFICATIONS from '../../../utils/notification'
import * as CATEGORIES_REQUESTS from '../../../utils/requests/categories'
import i18nLang from '../../../utils/i18n.json'

let InputWrapper = ({ onChange, input, keyProps, shrink }) => <div>
    <InputGenerator
        key={keyProps}
        fullWidth={true}
        InputLabelProps={shrink ? { shrink: true } : {}}
        margin='dense'
        {...input}
        value={input.value}
        onChange={onChange}
    />
</div>

let initialFields = [
    { value: '', type: 'text', name: 'name', label: 'Subcategory Name' },
]

let CreateSubcategory = props => {

    let { classes } = props
    const [subcategoryFields, setSubcategoryFields] = useState([])
    const lang = localStorage.getItem('currentLanguage')
    React.useEffect(() => {
        if (props.languages) {
            let baseField = [...initialFields].map(obj => ({ ...obj }))[0]
            let newFields = props.languages.map(language => {
                let short = Object.keys(language)[0]
                let name = Object.values(language)[0]
                return {
                    ...baseField, value: props.subcategoryToEdit.name ? props.subcategoryToEdit.name[short] : '', name: `${baseField.name}-${short}`, label: `${baseField.label} - ${name.name}`
                }
            })
            setSubcategoryFields(newFields)
           
        }
    }, [props.open])

    let onInputChange = event => {
        let fieldIndex = subcategoryFields.findIndex(f => f.name === event.target.name)

        if (!(fieldIndex > -1)) return

        let newFields = [...subcategoryFields]

        newFields[fieldIndex].value = event.target.value
        setSubcategoryFields(newFields)
    }

    const renderFields = () => {
        return subcategoryFields.map((field, index) => {
            return (
                <div key={index} className={classes.flexRow}>
                    <div className={`${classes.flexColumn} ${classes.equalFlex}`}>
                        <div className={`${classes.equalFlex} ${classes.paddingTopButton}`}>
                            <InputWrapper onChange={event => onInputChange(event)} shrink={true} input={field} key={field.name} />
                        </div>
                    </div>
                </div>
            )
        })
    }

    const onCreate = () => {
        let languages = {}

        let allGood = true
        let subCategoryFieldsCopy = [...subcategoryFields].map(obj => ({ ...obj }))
        subCategoryFieldsCopy.forEach(field => {
            if (!field.value) {
                field.error = true
                allGood = false
            }
        })

        if (!allGood) {
            setSubcategoryFields(subCategoryFieldsCopy)
            return NOTIFICATIONS.error('Please complete all forms')
        }


        subcategoryFields.map(field => {
            let short = field.name.split('-')[1]
            languages[short] = field.value
        })
        let subcategoryJson = {
            name: languages
        }

        if(props.editing)
        {
            CATEGORIES_REQUESTS.rename(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID, props.selectedCategory.id, props.subcategoryToEdit.id, { name: subcategoryJson.name })
            .then(() => {
                props.getSubcategories(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID, props.selectedCategory.id)
                props.onClose()
                setSubcategoryFields(subcategoryFields.map(field => ({ ...field, error: false, value: '' }))) 
                NOTIFICATIONS.success(i18nLang.subcategoryOptions.notification_succes_rename[lang])
            })
            .catch(err => {
                NOTIFICATIONS.error(i18nLang.subcategoryOptions.notification_error_rename[lang])
            })
        }
        else
        {
            CATEGORIES_REQUESTS.createSubcategory(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID, props.selectedCategory.id, { name: subcategoryJson.name })
            .then(() => {
                props.getSubcategories(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID, props.selectedCategory.id)
                props.onClose()
                NOTIFICATIONS.success('Successfully added new subcategory')
            })
            .catch(err => {
                NOTIFICATIONS.error('Fail to create')
            })
        }
    }

    const onClose = () => {
        props.onClose()
    }
    
    return (
        <Modal
            onCancel={onClose}
            onClose={onClose}
            acceptButtonText={props.editing ? i18nLang.subcategoryOptions.rename[lang] : i18nLang.subcategoryOptions.add[lang]}
            title={props.editing ? i18nLang.subcategoryOptions.rename[lang] : i18nLang.subcategoryOptions.create[lang]}
            cancelButtonText={i18nLang.subcategoryOptions.cancel[lang]}
            maxWidth={'sm'}
            onAccept={() => onCreate()}
            open={props.open}>
            {renderFields()}
        </Modal>
    )
}

let styles = theme => ({
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    equalFlex: {
        flex: 1
    }
})

export default withRouter(withStyles(styles)(CreateSubcategory))