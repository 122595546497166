import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Language, ArrowBack } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import TooltipMenu from './TooltipMenu'
import { getLanguage, i18n } from '../utils/helpers'

let useStyles = makeStyles({
    imageHeaderContainer: {
        minHeight: 250,
        width: '100%',
        backgroundSize: 'cover',
        position: 'relative',
        margin: '0 auto'
    },
    imageText: {
        position: 'absolute',
        bottom: 0,
        background: 'rgba(0,0,0,0.5)',
        color: '#f1f1f1',
        width: '100%',
        display: 'flex'
    },
    languageButton: {
        color: '#ffffff',
        marginRight: '10px'
    }
})

let RestaurantHeader = props => {
    let { restaurant, menu } = props
    let classes = useStyles()

    const getCurrentLanguage = () => {
        if (restaurant.languages && restaurant.languages.length) {
            for (let lang of restaurant.languages) {
                if (lang[getLanguage()]) {
                    return lang[getLanguage()].name
                }
            }

            localStorage.setItem('currentLanguage', restaurant.baseCurrency || 'ro')
            window.location.reload()
        }
    }

    useEffect(() => {
        localStorage.setItem('defaultLanguage', restaurant.baseCurrency || 'ro')
    }, [])

    return <div className={classes.imageHeaderContainer} style={{ backgroundImage: `url(${restaurant.photoUrl})` }}>
        <div className={classes.imageText}>
            {props.backButton ? <Link to={`/restaurants/${restaurant.id}`} style={{ color: '#ffffff', textDecoration: 'none', flex: 1 }}><h1 style={{ paddingLeft: 20 }}><ArrowBack style={{ paddingRight: 10, bottom: -2, position: 'relative' }} />{restaurant.name}: {i18n(menu.name)}</h1></Link> :
                <h1 style={{ paddingLeft: 20, flex: 1 }}>{restaurant.name}</h1>}
            <TooltipMenu
                style={{ flex: 1 }}
                buttonClass={classes.languageButton}
                frontIcon={<Language style={{ marginRight: '5px', top: '-1px' }} />}
                buttonText={getCurrentLanguage()}
                options={(restaurant.languages || []).filter(language => ['ro', 'en'].includes(Object.keys(language)[0])).map((language) => {
                    return { label: Object.values(language)[0].name, value: Object.keys(language)[0] }
                })}
                onClick={lang => {
                    if (lang !== getLanguage() && lang.length > 1) {
                        localStorage.setItem('currentLanguage', lang)
                        window.location.reload()
                    }
                }}
            />
        </div>
    </div>
}


export default RestaurantHeader