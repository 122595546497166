import React, { useEffect, useRef, useState } from 'react'
import AppleIcon from '@material-ui/icons/Apple';
import { withRouter, Link } from 'react-router-dom'
import { withStyles, Collapse, Divider, Accordion, AccordionSummary, AccordionDetails, Button, TextField, Tabs, Tab, Card, CardContent, Typography, CardMedia, CardActionArea, Popper, Grow, Paper, ClickAwayListener, MenuList, List, MenuItem, FormControlLabel, Checkbox, makeStyles } from '@material-ui/core'
import { ArrowBackIos, RestaurantMenu, KeyboardArrowDown, ExpandMore, Search, Language, ArrowRightAlt, RadioButtonUnchecked, RadioButtonChecked } from '@material-ui/icons'
import AcUnitIcon from '@material-ui/icons/AcUnit';
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'
import { getPrice, analytics } from '../../../utils/helpers'
import { EVENTS, COLORTHEME, MENU_CONTENT_MOBILE, SWIPER_BREAKPOINTS } from '../../../utils/constants'

import * as MOBILE_REQUESTS from '../../../utils/requests/mobile'
import i18n from '../../../utils/i18n.json'

let allSubcategories = []

let MenuContent = props => {

    let [tab, setTab] = useState(0)
    let [categories, setCategories] = useState(null)
    let [subcategories, setSubcategories] = useState([])
    let [restaurant, setRestaurant] = useState(null)
    const [openSearch, setOpenSearch] = useState(false)
    let actualTheme = localStorage.getItem(COLORTHEME)
    const [colorTheme, setColorTheme] = useState(actualTheme)
    const [circleState, setCircleState] = useState({
        colorTheme: actualTheme === 'lightMode' ? false : true
    })
    //const [expanded, setExpanded] = useState(`panel${props.history.location.state && props.history.location.state.selectedSubcategoryId}`)
    //const [expandAll, setExpandAll] = useState(false)
    const [displayLogo, setDisplayLogo] = useState(true)
    const [searchText, setSearchText] = useState('')

    const [open, setOpen] = useState(false)
    const refSubcategory = useRef(null)
    const anchorRef = useRef(null)
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    // const handleChangeAccordion = (panel) => (event, newExpanded) => {
    //     setExpanded(newExpanded ? panel : false)
    // }
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setOpen(false)
    }

    const handleChange = (event) => {
        if (event.target.checked) {
            setColorTheme('darkMode')
            localStorage.setItem(COLORTHEME, 'darkMode')
        }
        else {
            setColorTheme('lightMode')
            localStorage.setItem(COLORTHEME, 'lightMode')
        }
        setCircleState({ ...circleState, [event.target.name]: event.target.checked })
    }

    let onSearchProducts = event => {
        //setExpandAll(true)
        setSearchText(event.target.value)
        let value = event.target.value

        if (value.trim() === '') {
            //setExpandAll(false) 
            return setSubcategories(allSubcategories)
        }
        setSubcategories([...allSubcategories].map(subcategory => JSON.parse(JSON.stringify(subcategory))).filter(subcategory => {
            if (!subcategory.products.length) return false
            subcategory.products = subcategory.products.filter(product => {
                return product.name[localStorage.getItem('languageMobile')].toLowerCase().search(value.toLowerCase()) > -1
            })

            return subcategory.products.length > 0
        }))
    }

    const handleSearchProducts = () => {
        setOpenSearch(!openSearch)
        setDisplayLogo(!displayLogo)
    }

    let mergeSubcategoriesWithProducts = (subcategories, products) => {
        if (subcategories.length) {
            subcategories.forEach(subcategory => {
                subcategory.products = products.filter(product => product.subcategory === subcategory.id)
            })
        }

        return subcategories
    }

    let lang = localStorage.getItem('languageMobile')
    useEffect(() => {
        MOBILE_REQUESTS.getMenuCategories(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID)
            .then(categories => {

                let categoryIndex = categories.findIndex(category => category.id === props.match.params.CATEGORY_ID)
                categoryIndex = categoryIndex > -1 ? categoryIndex : 0
                if (categoryIndex > -1) setTab(categoryIndex)
                if (categories.length) {

                    Promise.all([
                        MOBILE_REQUESTS.getMenuProducts(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID, categories[categoryIndex].id)
                            .then(products => mergeSubcategoriesWithProducts(categories[categoryIndex].subcategories, products)),
                        MOBILE_REQUESTS.getRestaurant(props.match.params.RESTAURANT_ID)
                    ]).then(response => {
                        setCategories(categories)
                        setSubcategories(response[0])
                        allSubcategories = response[0]
                        setRestaurant(response[1])
                        if (props.history.location.state && response[0][0] && response[0][0].id !== props.history.location.state.selectedSubcategoryId)
                            refSubcategory.current.scrollIntoView({
                                block: "center",
                                behavior: 'smooth'
                            })
                    })
                }
            })
    }, [props.history.location.state, props.match.params.CATEGORY_ID, props.match.params.MENU_ID, props.match.params.RESTAURANT_ID])

    const useStyles = makeStyles({
        list: {
            display: 'flex',
            height: '4em',
            '& hr': {
                height: '4em'
            }
        },
        flexList: {
            flex: '0.2',
            display: 'flex',
            justifyContent: 'flex-end'
        },
        containerLightMode: {
            width: '100%',
            height: 'fit-content',
            minHeight: '100%',
            display: 'block',
            backgroundColor: props.design.light.primary,
            paddingTop: '30px',
            '& .MuiTabs-root': {
                color: 'black'
            },
            '& .MuiSvgIcon-root': {
                color: colorTheme === 'darkMode' ? props.design.dark.primaryText : props.design.light.primaryText
            },
            '& .swiper-button-prev, .swiper-button-next': {
                position: 'absolute',
                top: '50%',
                width: '40px',
                height: '40px',
                marginTop: 'calc(0px - (40px / 2))',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'black',
                backgroundColor: props.design.light.primary,
                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.2) 0px 0px 0px 1px',
            },
            '& .swiper-button-next::before': {
                content: '" "',
                width: '120px',
                height: '350px',
                backgroundImage: `linear-gradient(to left, ${props.design.light.primary} 30px, rgba(255, 255, 255, 0))`,
                position: 'absolute',
                '@media (max-width: 500px)': {
                    display: 'none'
                },
            },
            '& .swiper-button-prev::before': {
                content: '" "',
                width: '120px',
                height: '350px',
                backgroundImage: `linear-gradient(to right, ${props.design.light.primary} 40px, rgba(255, 255, 255, 0))`,
                position: 'absolute',
                '@media (max-width: 500px)': {
                    display: 'none'
                },
            },
        },
        containerDarkMode: {
            width: '100%',
            minHeight: '100%',
            height: 'fit-content',
            display: 'block',
            backgroundColor: props.design.dark.primary,
            paddingTop: '30px',
            '& h1': {
                color: 'white',
            },
            '& .MuiCardContent-root': {
                backgroundColor: props.design.dark.secondary
            },
            '& .MuiTabs-root': {
                color: 'white'
            },
            '& .MuiPaper-root': {
                backgroundColor: props.design.dark.primary
            },
            '& .MuiSvgIcon-root': {
                color: 'white'
            },
            '& .MuiDivider-root': {
                backgroundColor: '#212121'
            },
            '& .MuiInputBase-root': {
                color: 'white',
                borderBottom: '1px solid white',
            },
            '& .MuiInput-underline:before': {
                borderBottom: '1px solid white',
            },
            '& .swiper-button-prev, .swiper-button-next': {
                position: 'absolute',
                top: '50%',
                width: '40px',
                height: '40px',
                marginTop: 'calc(0px - (40px / 2))',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                backgroundColor: props.design.dark.primary,
                boxShadow: 'rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(255, 255, 255, 1) 0px 0px 0px 1px',
            },
            '& .swiper-button-next::before': {
                content: '" "',
                width: '120px',
                height: '350px',
                backgroundImage: `linear-gradient(to left, ${props.design.dark.primary} 30px, rgba(255, 255, 255, 0))`,
                position: 'absolute',
                '@media (max-width: 500px)': {
                    display: 'none'
                },
            },
            '& .swiper-button-prev::before': {
                content: '" "',
                width: '120px',
                height: '350px',
                backgroundImage: `linear-gradient(to right, ${props.design.dark.primary} 40px, rgba(255, 255, 255, 0))`,
                position: 'absolute',
                '@media (max-width: 500px)': {
                    display: 'none'
                },
            }
        },
        linkStyle: {
            textDecoration: 'none',
            height: '4em',
            width: '4em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            '@media (max-width: 500px)': {
                width: '2em',
                minWidth: '2em'
            },
        },
        header: {
            display: 'flex',
            width: '100%',
            padding: '0px 0px 10px 0px',
            position: 'fixed',
            top: '0px',
            boxSizing: 'border-box',
            flexDirection: 'column',
            zIndex: 100,
            backgroundColor: colorTheme === 'darkMode' ? props.design.dark.secondary : props.design.light.secondary,
            color: 'white',
            borderBottom: colorTheme === 'darkMode' ? '1px solid #212121' : '1px solid #e0e0e0',
        },
        backButton: {
            textDecoration: 'none !important',
            height: '100%',
            width: '100%',
            opacity: 0.9,
            background: 'none',
            outline: 'none',
            borderRadius: 'none',
            '&:hover': {
                background: 'none',
                boxShadow: 'none'
            },
            '@media (max-width: 500px)': {
                minWidth: 'unset',
                width: '3em'
            },
        },
        backButtonContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            flex: '0.2'
        },
        searchInput: {
            height: '30px',
            marginLeft: 'auto',
            marginRight: '10px'
        },
        tabsHolder: {
            flex: 1,
            marginTop: '10px',
        },
        tabs: {
            height: 40,
            minHeight: 40,
            marginBottom: -11,
            maxWidth: '1200px',
            margin: 'auto'
        },
        tab: {
            minHeight: '40px !important',
            maxWidth: '350px',
            color: colorTheme === 'darkMode' ? props.design.dark.primaryText : props.design.light.primaryText
        },
        productsContainer: {
            margin: 'auto',
            marginTop: '110px',
            maxWidth: '1200px'
        },
        subcategoryHolder: {
            width: '100%',
            padding: '0px 10px 0px 10px',
            boxSizing: 'border-box'
        },
        subcategory: {
            marginBottom: '0px',
            paddingLeft: '0.8em'
        },
        subcategoryDarkMode: {
            backgroundColor: props.design.dark.primary,
            color: 'white',
            width: '100%',
            textAlign: 'center'
        },
        products: {
            marginBottom: '10px',
            paddingBottom: '1em',
            borderBottom: colorTheme === 'darkMode' ? `1px solid ${props.design.dark.secondary}` : `1px solid ${props.design.light.secondary}`,
            '& .swiper-button-next:after': {
                content: 'next',
                fontSize: '1.5em',
                fontWeight: '900',
                zIndex: '1',
            },
            '& .swiper-button-prev:after': {
                content: 'prev',
                fontSize: '1.5em',
                fontWeight: '900',
                zIndex: '1',
            },
            '& .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled': {
                opacity: 0,
                pointerEvents: 'none'
            },
            '& .swiper-button-prev, .swiper-container-rtl .swiper-button-next': {
                left: '10px',
                right: 'auto'
            }
        },
        product: {
            marginLeft: '20px',
            display: 'inline-block',
            marginTop: '20px',
            borderRadius: '0.5em',
            width: '10em',
            backgroundColor: colorTheme === 'darkMode' ? `${props.design.dark.secondary} !important` : `${props.design.light.secondary} !important`,
            '&:nth-of-type(2n+1)': {
                marginLeft: '0px'
            },
            '@media (min-width: 767px)': {
                '&:nth-of-type(2n+1)': {
                    marginLeft: '20px'
                }
            },
            '@media (max-width: 500px)': {
                width: '7.5em'
            },
        },
        cardMediaContainer: {
            '@media (max-width: 500px)': {
                height: '120px',
            },
        },
        productName: {
            lineHeight: 1.1,
            textAlign: 'auto',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
        },
        price: {
            position: 'absolute',
            top: '20px',
            right: '0px',
            padding: 4,
            width: '45%',
            background: '#35B558',
            color: '#fff',
            textAlign: 'center',
            borderTopLeftRadius: '5px',
            borderBottomLeftRadius: '5px'
        },
        weightPrice: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px 10px 0px 10px',
            height: '3em',
            borderBottom: 'none',
            '@media (max-width: 500px)': {
                padding: '0px 5px 0px 5px',
                '& h1': {
                    fontSize: '12px'
                },
                '& span': {
                    fontSize: '12px'
                }
            },
        },
        backButtonArrow: {
            padding: '1em'
        },
        topBackButton: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '4em',
            width: '4em',
            borderRight: '1px solid lightgray'
        },
        topSearchButton: {
            height: '2em',
            weight: '2em'
        },
        topCurrencyButton: {
            padding: '0.7em',
        },
        weightGray: {
            color: 'lightgray',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: 'fit-content',
            display: 'inline-block',
        },
        fadeArrow: {
            position: 'absolute',
            top: '0',
            height: '310px',
            marginTop: '22px',
            width: '70px',
            zIndex: '9',
            background: 'linear-gradient(90deg,rgba(245,245,245,255) 0%, rgba(0,0,0,0) 80px)'
        },
        fadeArrowNext: {
            position: 'absolute',
            top: '0',
            right: '0',
            height: '320px',
            marginTop: '20px',
            width: '70px',
            zIndex: '9',
            background: 'linear-gradient(-90deg,rgba(245,245,245,255) 0%, rgba(0,0,0,0) 80px)',
        },
        headerContainer: {
            height: '4em',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        subcategoryLightMode: {
            backgroundColor: props.design.light.primary,
            color: 'black',
            width: '100%',
            textAlign: 'center'
        },
        darkMode: {
            backgroundColor: props.design.dark.secondary,
            color: 'white'
        },
        lightMode: {
            backgroundColor: 'white',
            color: 'black'
        },
        searchContainer: {
            display: 'flex',
            height: '4em',
            padding: displayLogo ? '0 1.2em 0 1.2em' : '0 .2em 0 .2em',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        },
        priceGoWell: {
            fontWeight: 'bold',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: 'fit-content',
            display: 'inline-block',
        },
        searchText: {
            '& > div > input': {
                width: 'fit-content'
            },
            ['@media (max-width:500px)']: {
                maxWidth: '10em'
            }
        },
        cardProductName: {
            borderBottom: 'none',
            padding: '10px 10px 4px 10px',
            height: '2em',
            '@media (max-width: 500px)': {
                padding: '5px 5px 4px 5px',
            },
        },
        cardProductDetails: {
            padding: '0px 10px 4px 10px',
            borderBottom: 'none',
            textAlign: 'left',
            '@media (max-width: 500px)': {
                padding: '0px 5px 4px 5px',
            },
        },
        cardProductNameText: {
            fontWeight: '600',
            minHeight: '2em',
            wordBreak: 'break-word',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            '@media (max-width: 500px)': {
                fontWeight: '500',
            },
        },
        textDetails: {
            fontWeight: '400',
            minHeight: '2em',
            textAlign: 'left',
            color: colorTheme === 'darkMode' ? `${props.design.dark.secondaryText} !important` : `${props.design.light.secondaryText} !important`,
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'left'
        },
        arrowColor: {
            color: colorTheme === 'darkMode' ? `${props.design.dark.secondaryText} !important` : `${props.design.light.secondaryText} !important`
        },
        verticalDivider: {
            height: '4em'
        },
        checkBoxColorTheme: {
            paddingLeft: '1.5em'
        },
        paper: {
            backgroundColor: 'white !important'
        },
        accordionSummary: {
            borderBottom: '1px solid lightgray',
            boxShadow: 'none',
            backgroundColor: colorTheme === 'darkMode' ? props.design.dark.primary : props.design.light.primary
        },
        headerContainerImage: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            position: 'relative',
            flex: '0.6',
            '& img': {
                maxWidth: '100%',
                maxHeight: '3.7em',
                width: '100%',
                objectFit: 'contain',
            },
            '@media (max-width: 500px)': {
                height: '45%',
                display: !displayLogo && 'none'
            },
        },
        swiperContainer: {
            width: '100%'
        },
        productSpiceVeganContainer: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        productSpicyContainer: {
            background: colorTheme === 'darkMode' ? props.design.dark.primary : props.design.light.primary,
            borderRadius: '50%',
            width: '2em',
            height: '2em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 0.1em 0 0.1em'
        },
        vegetarianImage: {
            height: '1em',
            width: '1em'
        },
        spicyImage: {
            height: '2em',
            width: '2em'
        }
    })
    const classes = useStyles();
    let setLanguage = language => {
        localStorage.setItem('languageMobile', language)
        window.location.reload()
    }

    let renderLanguages = () => {
        return (
            restaurant.languages.map((language, index) => {
                const languageName = Object.keys(language)[0]
                return (
                    <MenuItem
                        key={index}
                        onClick={() => { setLanguage(languageName); analytics.logEvent(EVENTS.LANG, { restaurant_id: restaurant.id, language: languageName }) }}
                        style={{
                            backgroundColor: localStorage.getItem('languageMobile') === languageName ? 'rgba(0,0,0,0.5)' : '',
                            color: localStorage.getItem('languageMobile') === languageName ? '#ffffff' : ''
                        }}>
                        <span>{languageName}</span>
                    </MenuItem>
                )
            })
        )
    }

    let changeCategory = index => {
        //setExpanded(`panel${categories[index].subcategories[0].id}`)
        //onSearchProducts({target: {value: ''}})
        setTab(index)
        //setExpanded(`panel${categories[index].subcategories[0].id}`)
        analytics.logEvent(EVENTS.CATEGORY, { restaurant_id: props.match.params.RESTAURANT_ID, category_name: categories[index].name[localStorage.getItem('defaultLanguage')] })
        MOBILE_REQUESTS.getMenuProducts(props.match.params.RESTAURANT_ID, props.match.params.MENU_ID, categories[index].id)
            .then(products => {
                props.history.push(`/mobile/${props.match.params.RESTAURANT_ID}/menu/${props.match.params.MENU_ID}/category/${categories[index].id}`)
                setSubcategories(mergeSubcategoriesWithProducts(categories[index].subcategories, products))
                allSubcategories = mergeSubcategoriesWithProducts(categories[index].subcategories, products)
            })
    }

    let productClick = (productId, productName) => {
        analytics.logEvent(EVENTS.PRODUCT, { restaurant_id: props.match.params.RESTAURANT_ID, product_name: productName })
        props.history.push(`/mobile/${props.match.params.RESTAURANT_ID}/menu/${props.match.params.MENU_ID}/product/${productId}`)
    }


    let Product = ({ product, restaurant }) => <Card onClick={() => productClick(product.id, product.name[localStorage.getItem('defaultLanguage')])} className={classes.product}>
        <CardActionArea>
            <CardMedia
                component='img'
                height='140'
                image={product.photoThumbUrl}
                className={classes.cardMediaContainer}
            />
            <CardContent className={classes.cardProductName}>
                <Typography component='h1' variant='body2' className={`${classes.productName} ${classes.cardProductNameText}`}>
                    {Number(product.name[localStorage.getItem('languageMobile') || 'ro'].length) > MENU_CONTENT_MOBILE.PRODUCT_NAME_LENGTH ? product.name[localStorage.getItem('languageMobile')].slice(0, MENU_CONTENT_MOBILE.PRODUCT_NAME_LENGTH) + '...' : product.name[localStorage.getItem('languageMobile')]}
                </Typography>
            </CardContent>
            <CardContent className={classes.cardProductDetails}>
                <Typography className={classes.textDetails} component='h1' >
                    Detalii &nbsp; <ArrowRightAlt className={classes.arrowColor} fontSize='small' />
                </Typography>
            </CardContent>
            <CardContent className={classes.weightPrice}>
                {/* {product.weight ? <Typography component='span' className={`${classes.productName} ${classes.weightGray}`}> {product.weight} {product.weightUnit}</Typography> : null} */}
                {product.price ? <Typography component='h1' className={classes.priceGoWell}>{getPrice(product.price, restaurant.currencies, restaurant.languages)}</Typography> : null}
                <div className={classes.productSpiceVeganContainer}>
                    {product.veganTag && product.veganTag !== "none" ? <div className={classes.productSpicyContainer}> <img className={classes.vegetarianImage} src="/assets/vegetarian.png" alt="vegetarian" /> </div> : null}
                    {product.isSpicy ? <div className={classes.productSpicyContainer}> <img className={classes.spicyImage} src="/assets/spicy.png" alt="spicy" /> </div> : null}
                </div>
            </CardContent>
        </CardActionArea>
    </Card>

    return (
        <div className={colorTheme === 'darkMode' ? classes.containerDarkMode : classes.containerLightMode}>
            <div className={classes.header} >
                <div className={classes.headerContainer}>
                    <div className={classes.backButtonContainer}>
                        <Link className={classes.linkStyle} to={{ pathname: `/mobile/${props.match.params.RESTAURANT_ID}`, state: { renderMenus: true, showBackButton: false, categoryId: subcategories.map(sub => sub.id) } }}>
                            <Button className={classes.backButton}>
                                <ArrowBackIos />
                            </Button>
                        </Link>
                        <Divider className={classes.verticalDivider} orientation='vertical' flexItem />
                    </div>
                    {restaurant &&
                        <div className={classes.headerContainerImage}>
                            <img alt='' src={colorTheme === 'darkMode' ? restaurant.logoUrlDark : restaurant.logoUrlLight} />
                        </div>}
                    <div className={classes.flexList}>
                        <List component='nav' className={classes.list}>
                            <Divider orientation='vertical' flexItem />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={circleState.colorTheme}
                                        icon={<RadioButtonUnchecked />}
                                        checkedIcon={<RadioButtonChecked />}
                                        onChange={handleChange}
                                        name={COLORTHEME}
                                    />
                                }
                                className={classes.checkBoxColorTheme}
                            />
                            <Divider orientation='vertical' flexItem />
                            <div className={classes.searchContainer}>
                                <Search onClick={() => handleSearchProducts()} />
                                {openSearch &&
                                    <Collapse in={openSearch}>
                                        <TextField autoFocus={true} className={classes.searchText} value={searchText} name={MENU_CONTENT_MOBILE.SEARCH} placeholder={MENU_CONTENT_MOBILE.SEARCH_PLACEHOLDER} type='search' onChange={event => onSearchProducts(event)} />
                                    </Collapse>
                                }
                            </div>
                            <Divider orientation='vertical' flexItem />
                            <Button
                                ref={anchorRef}
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup='true'
                                onClick={handleToggle}
                            >
                                <Language /> <KeyboardArrowDown />
                            </Button>
                            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                    >
                                        <Paper className={classes.paper}>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList autoFocusItem={open} id='menu-list-grow'>
                                                    {renderLanguages()}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                            <Divider orientation='vertical' flexItem />
                        </List>
                    </div>
                </div>
                <Divider orientation='horizontal' />
                <div className={classes.tabsHolder}>
                    {categories ? <Tabs
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: colorTheme === 'darkMode' ? props.design.dark.secondaryText : props.design.light.secondaryText
                            }
                        }}
                        indicatorColor='secondary'
                        value={tab}
                        onChange={(_, newValue) => changeCategory(newValue)}
                        variant='scrollable'
                        scrollButtons='on'
                        className={classes.tabs}
                    >
                        {categories.map(category => <Tab key={category.id} className={classes.tab} label={category.name[localStorage.getItem('languageMobile')]} />)}
                    </Tabs> : null}
                </div>
            </div>
            {/* {expandAll ? (
                <div className={classes.productsContainer}>
                    {restaurant && subcategories.length ? subcategories
                        .filter(subcategory => subcategory.products && subcategory.products.length && !subcategory.isHidden)
                        .map((subcategory,index) => {
                            return (
                                <Accordion 
                                    key={`${subcategory.id}_${expandAll}`} 
                                    className={classes.accordionSummary} 
                                    defaultExpanded={true}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls={`panel${subcategory.id}bh-content`}
                                        id={`panel${subcategory.id}bh-header`}
                                    >
                                            <h1 className={classes.subcategory}>{subcategory.name[localStorage.getItem('languageMobile')]}</h1>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.products}>
                                        <Swiper 
                                            spaceBetween={0} 
                                            navigation
                                            slidesPerView={5.6}
                                            className={classes.swiperContainer}
                                            breakpoints={SWIPER_BREAKPOINTS}
                                        >
                                            {subcategory.products.map(product => <SwiperSlide key={product.id}><Product restaurant={restaurant} product={product} /></SwiperSlide>)}
                                        </Swiper>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        }) : <div className={`${classes.subcategory} ${colorTheme === 'darkMode' ? `${classes.subcategoryDarkMode}` : `${classes.subcategoryLightMode}`}`} >{restaurant ? i18n.noProducts[lang] : 'Loading..'} </div>
                    }
                </div>
            ) : (   
                    <div className={classes.productsContainer}>
                        {restaurant && subcategories.length ? subcategories
                            .filter(subcategory => subcategory.products && subcategory.products.length && !subcategory.isHidden)
                            .map((subcategory,index) => {
                                return (
                                    <Accordion 
                                        key={`${subcategory.id}_${expandAll}`}
                                        className={`${classes.accordionSummary} ${colorTheme === 'darkMode' ? classes.accordionSummarDarkMode : classes.accordionSummarLightMode }`} 
                                        expanded={expanded === `panel${subcategory.id}`} onChange={handleChangeAccordion(`panel${subcategory.id}`)}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                            aria-controls={`panel${subcategory.id}bh-content`}
                                            id={`panel${subcategory.id}bh-header`}
                                        >
                                            <h1 className={classes.subcategory}>{subcategory.name[localStorage.getItem('languageMobile')]}</h1>
                                        </AccordionSummary>
                                        <AccordionDetails className={classes.products}>
                                            <Swiper 
                                                spaceBetween={0} 
                                                navigation
                                                slidesPerView={5.6}
                                                className={classes.swiperContainer}
                                                breakpoints={{
                                                    320: {
                                                        slidesPerView: 1.5
                                                    },
                                                    360: {
                                                        slidesPerView: 1.9
                                                    },
                                                    500: {
                                                        slidesPerView: 2.9,
                                                    },
                                                    700: {
                                                        slidesPerView: 3.75,
                                                    },
                                                    900: {
                                                        slidesPerView: 4.6
                                                    },
                                                    1150: {
                                                        slidesPerView: 5.6
                                                    }
                                                }}
                                            >
                                                {subcategory.products.map(product => <SwiperSlide key={product.id}> <Product restaurant={restaurant} product={product} /> </SwiperSlide>)}
                                            </Swiper>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            }) : <div className={`${classes.subcategory} ${colorTheme === 'darkMode' ? `${classes.subcategoryDarkMode}` : `${classes.subcategoryLightMode}`}`} >{restaurant ? i18n.noProducts[lang] : 'Loading..'} </div>}
                    </div>
                )
            } */}

            <div className={classes.productsContainer}>
                {restaurant && subcategories.length ? subcategories
                    .filter(subcategory => subcategory.products && subcategory.products.length && !subcategory.isHidden)
                    .map((subcategory, index) => {
                        return (
                            <div ref={props.history.location.state && subcategory.id === props.history.location.state.selectedSubcategoryId ? refSubcategory : null} key={index} className={classes.subcategoryHolder}>
                                <div className={classes.subcategory}>
                                    <h1>{subcategory.name[localStorage.getItem('languageMobile')]}</h1>
                                </div>
                                <div className={classes.products}>
                                    <Swiper
                                        spaceBetween={0}
                                        navigation
                                        slidesPerView={5.6}
                                        className={classes.swiperContainer}
                                        breakpoints={SWIPER_BREAKPOINTS}
                                    >
                                        {subcategory.products.map(product => <SwiperSlide key={product.id}><Product restaurant={restaurant} product={product} /></SwiperSlide>)}
                                    </Swiper>
                                </div>
                            </div>
                        )
                    }) : <div className={`${classes.subcategory} ${colorTheme === 'darkMode' ? `${classes.subcategoryDarkMode}` : `${classes.subcategoryLightMode}`}`} >{restaurant ? i18n.noProducts[lang] : 'Loading..'} </div>
                }
            </div>
        </div>

    )
}

export default withRouter(MenuContent)